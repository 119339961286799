// import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { useHistory, useParams } from "react-router-dom";
// import PlaidLink from "./plaid/plaid.link.btn";
// import PlaidBankCard from "./plaid/plaid.bank.card";
// import { getBankAccounts } from "../api.call";
// import PlaidBankSkeleton from "../../../styled/skeletons/PlaidBankCardSkeleton";
// import emptyIcon from "../../../../Assets/emptyData.svg";
// import { Grid } from "@mui/material";
// import IndianLinkBtn from "./plaid/indian.bank.btn";
// import IndianBankCard from "./plaid/indian.bank.card";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "15px 15px 30px",
//     height: "100%",
//     overflowX: "hidden",
//     overflowY: "auto",
//     "&::-webkit-scrollbar": {
//       display: "none",
//     },
//     scrollbarWidth: "none",
//   },
//   row: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "wrap",
//   },
//   titleText: {
//     fontSize: "1.5rem",
//     fontWeight: "600",
//     color: "#424242",
//     margin: "1rem",
//   },
//   allCardCont: {
//     display: "flex",
//     alignItems: "center",
//     flexWrap: "wrap",
//     [theme.breakpoints.only("xs")]: {
//       justifyContent: "space-around",
//     },
//   },
//   title: {
//     fontSize: "25px",
//     fontWeight: "600",
//     color: "#00345d",
//     margin: "50px 0px 15px",
//   },
//   emptyCont: {
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "column",
//     padding: "30px 10px",
//     "& img": {
//       width: "250px",
//       height: "auto",
//     },
//     "& p": {
//       fontSize: "15px",
//       fontWeight: "400",
//       marginTop: "10px",
//     },
//   },
// }));

// export default function BankAccounts(props) {
//   const classes = useStyles();
//   const history = useHistory();
//   const { walletId } = useParams();

//   const [plaidBankAccounts, setPlaidBankAccounts] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const getBankAccountsApiHit = async () => {
//     setLoading(true);
//     await getBankAccounts({ walletId })
//       .then((data) => {
//         setPlaidBankAccounts(data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//     setLoading(false);
//   };

//   useEffect(() => {
//     getBankAccountsApiHit();
//   }, []);

//   return (
//     <div className={classes.root}>
//       <Grid container spacing={1}>
//         <Grid item xs={12} sm={6} lg={4}>
//           <IndianLinkBtn walletId={walletId} />
//         </Grid>
//         {/* <Grid item xs={12} sm={6} lg={4}>
//           <PlaidLink walletId={walletId} />
//         </Grid> */}
//       </Grid>

//       <h3 className={classes.title}>Bank Accounts</h3>
//       <div className={classes.allCardCont}>
//         {loading ? (
//           <>
//             <PlaidBankSkeleton />
//             <PlaidBankSkeleton />
//             <PlaidBankSkeleton />
//           </>
//         ) : (
//           <>
//             {plaidBankAccounts?.length > 0 ? (
//               plaidBankAccounts.map((bankAcct, index) => {
//                 return bankAcct?.accountType === "PlaidBankAccount" ? (
//                   // <PlaidBankCard
//                   //   bankAccount={bankAcct.account}
//                   //   givenNickName={bankAcct?.nickName}
//                   //   accountId={bankAcct?._id}
//                   //   index={index}
//                   //   key={index}
//                   //   plaidBankAccounts={plaidBankAccounts}
//                   //   setPlaidBankAccounts={setPlaidBankAccounts}
//                   // />
//                   <></>
//                 ) : bankAcct?.accountType === "IndianBank" ? (
//                   <IndianBankCard bankAccount={bankAcct} />
//                 ) : null;
//               })
//             ) : (
//               <div className={classes.emptyCont}>
//                 <img src={emptyIcon} />
//                 <p>
//                   No bank account exist. Click on the above card to add bank
//                   account
//                 </p>
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { useHistory, useParams } from "react-router-dom";
// import PlaidLink from "./plaid/plaid.link.btn";
// import PlaidBankCard from "./plaid/plaid.bank.card";
// import { getBankAccounts } from "../api.call";
// import PlaidBankSkeleton from "../../../styled/skeletons/PlaidBankCardSkeleton";
// import emptyIcon from "../../../../Assets/emptyData.svg";
// import { Grid, CircularProgress } from "@mui/material";
// import IndianLinkBtn from "./plaid/indian.bank.btn";
// import IndianBankCard from "./plaid/indian.bank.card";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: "15px 15px 30px",
//     height: "100%",
//     overflowX: "hidden",
//     overflowY: "auto",
//     "&::-webkit-scrollbar": {
//       display: "none",
//     },
//     scrollbarWidth: "none",
//   },
//   row: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "wrap",
//   },
//   titleText: {
//     fontSize: "1.5rem",
//     fontWeight: "600",
//     color: "#424242",
//     margin: "1rem",
//   },
//   allCardCont: {
//     display: "flex",
//     alignItems: "center",
//     flexWrap: "wrap",
//     [theme.breakpoints.only("xs")]: {
//       justifyContent: "space-around",
//     },
//   },
//   title: {
//     fontSize: "25px",
//     fontWeight: "600",
//     color: "#00345d",
//     margin: "50px 0px 15px",
//   },
//   emptyCont: {
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flexDirection: "column",
//     padding: "30px 10px",
//     "& img": {
//       width: "250px",
//       height: "auto",
//     },
//     "& p": {
//       fontSize: "15px",
//       fontWeight: "400",
//       marginTop: "10px",
//     },
//   },
//   loadingIndicator: {
//     display: "flex",
//     justifyContent: "center",
//     marginTop: "20px",
//   },
// }));

// export default function BankAccounts(props) {
//   const classes = useStyles();
//   const history = useHistory();
//   const { walletId } = useParams();
//   const [plaidBankAccounts, setPlaidBankAccounts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [isAddingAccount, setIsAddingAccount] = useState(false);

//   const getBankAccountsApiHit = async () => {
//     setLoading(true);
//     try {
//       const data = await getBankAccounts({ walletId });
//       setPlaidBankAccounts(data);
//     } catch (err) {
//       console.log(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getBankAccountsApiHit();
//   }, [walletId]);

//   const refreshBankAccounts = () => {
//     getBankAccountsApiHit();
//   };

//   return (
//     <div className={classes.root}>
//       <Grid container spacing={1}>
//         <Grid item xs={12} sm={6} lg={4}>
//           <IndianLinkBtn 
//             walletId={walletId} 
//             onAccountAdded={refreshBankAccounts}
//             setIsAddingAccount={setIsAddingAccount}
//           />
//         </Grid>
//         {/* Uncomment this if you want to use PlaidLink
//         <Grid item xs={12} sm={6} lg={4}>
//           <PlaidLink 
//             walletId={walletId} 
//             onAccountAdded={refreshBankAccounts}
//             setIsAddingAccount={setIsAddingAccount}
//           />
//         </Grid>
//         */}
//       </Grid>

//       <h3 className={classes.title}>Bank Accounts</h3>

//       {isAddingAccount && (
//         <div className={classes.loadingIndicator}>
//           <CircularProgress />
//         </div>
//       )}

//       <div className={classes.allCardCont}>
//         {loading ? (
//           <>
//             <PlaidBankSkeleton />
//             <PlaidBankSkeleton />
//             <PlaidBankSkeleton />
//           </>
//         ) : (
//           <>
//             {plaidBankAccounts?.length > 0 ? (
//               plaidBankAccounts.map((bankAcct, index) => {
//                 return bankAcct?.accountType === "PlaidBankAccount" ? (
//                   <PlaidBankCard
//                     bankAccount={bankAcct.account}
//                     givenNickName={bankAcct?.nickName}
//                     accountId={bankAcct?._id}
//                     index={index}
//                     key={index}
//                     plaidBankAccounts={plaidBankAccounts}
//                     setPlaidBankAccounts={setPlaidBankAccounts}
//                   />
//                 ) : bankAcct?.accountType === "IndianBank" ? (
//                   <IndianBankCard bankAccount={bankAcct} key={index} />
//                 ) : null;
//               })
//             ) : (
//               <div className={classes.emptyCont}>
//                 <img src={emptyIcon} alt="No bank accounts" />
//                 <p>
//                   No bank account exist. Click on the above card to add bank account
//                 </p>
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import PlaidLink from "./plaid/plaid.link.btn";
import PlaidBankCard from "./plaid/plaid.bank.card";
import { getBankAccounts } from "../api.call";
import PlaidBankSkeleton from "../../../styled/skeletons/PlaidBankCardSkeleton";
import emptyIcon from "../../../../Assets/emptyData.svg";
import { Grid, CircularProgress } from "@mui/material";
import IndianLinkBtn from "./plaid/indian.bank.btn";
import IndianBankCard from "./plaid/indian.bank.card";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 15px 30px",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  titleText: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#424242",
    margin: "1rem",
  },
  allCardCont: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "space-around",
    },
  },
  title: {
    fontSize: "25px",
    fontWeight: "600",
    color: "#00345d",
    margin: "50px 0px 15px",
  },
  emptyCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "30px 10px",
    "& img": {
      width: "250px",
      height: "auto",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "400",
      marginTop: "10px",
    },
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));

export default function BankAccounts(props) {
  const classes = useStyles();
  const history = useHistory();
  const { walletId } = useParams();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddingAccount, setIsAddingAccount] = useState(false);

  const getBankAccountsApiHit = async () => {
    setLoading(true);
    try {
      const data = await getBankAccounts({ walletId });
      setBankAccounts(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBankAccountsApiHit();
  }, [walletId]);

  const refreshBankAccounts = () => {
    getBankAccountsApiHit();
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={4}>
          <IndianLinkBtn 
            walletId={walletId} 
            onAccountAdded={refreshBankAccounts}
            setIsAddingAccount={setIsAddingAccount}
          />
        </Grid>
        {/* Uncomment this if you want to use PlaidLink
        <Grid item xs={12} sm={6} lg={4}>
          <PlaidLink 
            walletId={walletId} 
            onAccountAdded={refreshBankAccounts}
            setIsAddingAccount={setIsAddingAccount}
          />
        </Grid>
        */}
      </Grid>

      <h3 className={classes.title}>Bank Accounts</h3>

      {isAddingAccount && (
        <div className={classes.loadingIndicator}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.allCardCont}>
        {loading ? (
          <>
            <PlaidBankSkeleton />
            <PlaidBankSkeleton />
            <PlaidBankSkeleton />
          </>
        ) : (
          <>
            {/* {plaidBankAccounts?.length > 0 ? (
              plaidBankAccounts.map((bankAcct, index) => {
                return bankAcct?.accountType === "PlaidBankAccount" ? (
                  <PlaidBankCard
                    // bankAccount={bankAcct.account}
                    // // givenNickName={bankAcct?.nickName}
                    // accountId={bankAcct?._id}
                    // index={index}
                    // key={index}
                    // plaidBankAccounts={plaidBankAccounts}
                    // setPlaidBankAccounts={setPlaidBankAccounts}
                    
                  />
                ) : bankAcct?.accountType === "IndianBank" ? (
                  <IndianBankCard 
                    bankAccount={bankAcct} 
                    key={index} 
                    onUpdate={refreshBankAccounts}
                  />
                ) : null;
              })
            ) 
             */}
            {bankAccounts?.length > 0 ? (
              bankAccounts.map((bankAcct, index) => {
                return bankAcct?.accountType === "IndianBank" ? (
                  <IndianBankCard 
                    key={index}
                    bankAccount={bankAcct} 
                    onUpdate={getBankAccountsApiHit}
                  />
                ) : null; // Handle other account types if needed
              })
            ) : (
              <div className={classes.emptyCont}>
                <img src={emptyIcon} alt="No bank accounts" />
                <p>
                  No bank account exist. Click on the above card to add bank account
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

