import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import Popover from '@material-ui/core/Popover';
import ButtonBase from '@material-ui/core/ButtonBase';
import NumberFormat from 'react-number-format';
import Paper from '@material-ui/core/Paper';
import { Button, IconButton } from '@material-ui/core';
import { NewInputBase } from '../../../commonComponent/styles';
import AutocompleteWithSeveralCreates from '../../../../styled/CommonComponents/AutocompleteWithSeveralCreates';
import CreateDiscountOrTax from './Create.DiscountOrTax';

const useStyles = makeStyles((theme) => ({
  smBtn: {
    minWidth: '3rem',
    height: '2rem',
    marginTop: '0.5rem',
  },
  smBtnActive: {
    minWidth: '3rem',
    height: '2rem',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    backgroundColor: '#bbdefb',
    marginTop: '0.5rem',
  },
  popoverTextInputDiv: {
    margin: "15px 0px"
  },
  typeBtn: {
    minWidth: '3rem',
    height: '2rem',
    textAlign: 'center',
  },
  autoCompleteCont: {
    width: "120px",
    [theme.breakpoints.down('xs')]: {
      width: "95px",
    }
  },
  btnSty: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "550"
  },
  addText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  autocompleteBtnCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "3px"
  },
  popoverCont: {
    width: "250px",
    padding: "5px 10px 10px"
  },
  popoverTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  applyBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  popoverBtnsCont: {
    display: "flex",
    alignItems: "center",
  }
}));

const MoneyPopOver = (props) => {
  const classes = useStyles();
  const {
    placeholder, valObjGiven, grandTotal, setValObj,
    taxesOrDiscounts, taxOrDiscountValue, onSelect, tx,
    callDefaultAdd, onClickDelete
  } = props;

  const oldType = valObjGiven?.type ? valObjGiven.type : '$';
  const oldAmount = valObjGiven?.amount ? valObjGiven.amount : 0;
  const oldPercent = valObjGiven?.percent ? valObjGiven.percent : 0;

  const [valObj, setValObjUseState] = useState(valObjGiven);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(oldType);
  const [amount, setAmount] = useState(oldAmount);
  const [percent, setPercent] = useState(oldPercent);
  const [taxOrDiscountText, setTaxOrDiscountText] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setValObjUseState(valObjGiven)
    setType(valObjGiven?.type)
    setAmount(valObjGiven?.amount)
    setPercent(valObjGiven?.percent)
  }, [valObjGiven])

  const onApply = () => {
    const obj = {
      enabled: true,
      type,
      amount,
      percent,
    };

    if (setValObj) {
      setValObj(obj);
      setAnchorEl(null);

      if (!tx?.billList?.discountRelationModel && placeholder === "Discount") {
        callDefaultAdd();
      }
      if (!tx?.billList?.taxRelationModel && placeholder === "Tax") {
        callDefaultAdd();
      }
    }
  };

  const handleClick = (event) => {
    if (taxOrDiscountValue) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <>
      {valObj?.enabled ?
        <div className={classes.autocompleteBtnCont} >
          <IconButton onClick={onClickDelete} style={{ padding: "0px" }} >
            <DeleteIcon />
          </IconButton>
          <ButtonBase
            onClick={handleClick}
            className={classes.btnSty}
          >
            {type == '$' ?
              <>₹{amount}</>
              :
              <>{percent}%</>
            }
          </ButtonBase>
          <div className={classes.autoCompleteCont}>
            <AutocompleteWithSeveralCreates
              value={taxOrDiscountValue}
              offerings={taxesOrDiscounts}
              onSelect={onSelect}
              placeholder={""}
              text={taxOrDiscountText}
              setText={setTaxOrDiscountText}
              open={createOpen}
              setOpen={setCreateOpen}
              loading={createLoading}
              setLoading={setCreateLoading}
              getOptionLabel={(option) => { return option?.name || " " }}
              lable={placeholder}
              CreateDialogBox={
                <CreateDiscountOrTax
                  text={taxOrDiscountText}
                  setOpen={setCreateOpen}
                  setLoading={setCreateLoading}
                  onSelect={onSelect}
                  createType={placeholder}
                  tx={tx}
                />
              }
            />
          </div>
        </div>
        :
        <div className={classes.addText}>
          <ButtonBase
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.btnSty}
          >
            Add {placeholder}
          </ButtonBase>
        </div>
      }


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverCont}>
          <div className={classes.popoverTop}>
            <h3>
              {placeholder}
            </h3>

            <div className={classes.popoverBtnsCont}>
              <Paper
                className={type == '%' ? classes.smBtnActive : classes.smBtn}
                style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
              >
                <ButtonBase
                  className={classes.typeBtn}
                  onClick={() => {
                    setType('%');
                  }}
                >
                  %
                </ButtonBase>
              </Paper>
              <Paper
                className={type == '$' ? classes.smBtnActive : classes.smBtn}
                style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }}
              >
                <ButtonBase
                  className={classes.typeBtn}

                  onClick={() => {
                    setType('$');
                  }}
                >
                  ₹
                </ButtonBase>
              </Paper>
            </div>
          </div>
          <div className={classes.popoverTextInputDiv}>
            {type == '$' ? <NumberFormat
              allowNegative={false}
              isAllowed={(values) => {
                const { value } = values;
                return grandTotal ? value >= 0 && value < grandTotal : value >= 0;
              }}
              value={amount} thousandSeparator={true} prefix={'₹'}
              customInput={NewInputBase}
              onValueChange={(values) => {
                const { value } = values;
                setAmount(value);
              }}
            /> : <NumberFormat
              allowNegative={false}
              isAllowed={(values) => {
                const { value } = values;
                return value >= 0 && value < 100;
              }}
              value={percent} thousandSeparator={false} suffix={'%'}
              customInput={NewInputBase}
              onValueChange={(values) => {
                const { value } = values;
                setPercent(value);
              }}
            />}
          </div>
          <div className={classes.applyBtn}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                onApply();
              }}
            >Apply</Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
export default MoneyPopOver;