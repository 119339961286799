import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import Api from "../../../../../helpers/Api";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";

const validationSchema = yup.object().shape({
  beneficiaryName: yup.string().required("Beneficiary Name is required"),
  bankName: yup.string().required("Bank Name is required"),
  accountNumber: yup.string().required("Account Number is required"),
  ifsc: yup.string().required("IFSC is required"),
  branchName: yup.string().required("Branch Name is required"),
});

export default function EditIndianBankForm({ bankDetails, onClose, onUpdate }) {
  const dispatch = useDispatch();
  const { walletId } = useParams();
  const [formData, setFormData] = useState({
    beneficiaryName: "",
    bankName: "",
    accountNumber: "",
    ifsc: "",
    branchName: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (bankDetails) {
      setFormData(bankDetails);
    }
  }, [bankDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateBank = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      console.log("wallet_id:", walletId);
      console.log("bankId:" , bankDetails._id);
      const { data } = await Api.post("/wallet/indian/bank/update", {
        ...formData,
        wallet: walletId,
        bankId: bankDetails._id, // Include the bank ID in the request body
      });
      console.log("Update response:", data);
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Bank details updated successfully",
          },
        });
        onUpdate();
        onClose();
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.log(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An error occurred while updating bank details",
          },
        });
      }
    }
  };

  return (
    <>
      <FormBox label="Beneficiary Name">
        <TextField
          name="beneficiaryName"
          value={formData.beneficiaryName}
          onChange={handleChange}
          placeholder="Enter Beneficiary Name"
          error={!!errors.beneficiaryName}
          helperText={errors.beneficiaryName}
          fullWidth={true}
        />
      </FormBox>
      <FormBox label="Bank Name">
        <TextField
          name="bankName"
          value={formData.bankName}
          onChange={handleChange}
          placeholder="Enter Bank Name"
          error={!!errors.bankName}
          helperText={errors.bankName}
          fullWidth={true}
        />
      </FormBox>
      <FormBox label="Account Number">
        <TextField
          name="accountNumber"
          value={formData.accountNumber}
          onChange={handleChange}
          placeholder="Enter Account Number"
          error={!!errors.accountNumber}
          helperText={errors.accountNumber}
          fullWidth={true}
        />
      </FormBox>
      <FormBox label="IFSC">
        <TextField
          name="ifsc"
          value={formData.ifsc}
          onChange={handleChange}
          placeholder="Enter IFSC"
          error={!!errors.ifsc}
          helperText={errors.ifsc}
          fullWidth={true}
        />
      </FormBox>
      <FormBox label="Branch Name">
        <TextField
          name="branchName"
          value={formData.branchName}
          onChange={handleChange}
          placeholder="Enter Branch Name"
          error={!!errors.branchName}
          helperText={errors.branchName}
          fullWidth={true}
        />
      </FormBox>
      <DuoButtonGroup
        primaryButtonText="Update"
        primaryButtonListener={updateBank}
        secondaryButtonText="Cancel"
        secondaryButtonListener={onClose}
      />
    </>
  );
}