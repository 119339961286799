import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTxTemplateCountByWallet, getTxTemplateByWallet } from '../../commonComponent/transaction/api';
import SlidingSideBar from '../../commonComponent/generator/SlidingSideBar';
import TransactionsTable from '../../commonComponent/Transactions.Table';

export default function InvoiceGenerators(props) {
  const { walletId } = useParams()

  const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false)
  const [selectedGenerator, setSelectedGenerator] = useState(null)

  return (<>
    <TransactionsTable
      walletId={walletId}
      getDataApiHitFun={getTxTemplateByWallet}
      getDataObj={{
        walletId: walletId,
        type: "Invoice"
      }}
      setSelectedTx={setSelectedGenerator}
      setOpenDetails={setOpenTemplateDrawer}
      getNumberOfDataRowApiHit={getTxTemplateCountByWallet}
      getDataNumberObj={{
        walletId: walletId,
        type: "Invoice"
      }}
      emptyText={"No invoice generator available"}
      isGenerator={true}
    />

    {selectedGenerator && (
      <SlidingSideBar
        openTemplateDrawer={openTemplateDrawer}
        setOpenTemplateDrawer={setOpenTemplateDrawer}
        selectedGenerator={selectedGenerator}
        editClickUrl={`/finance/${walletId}/invoice/edit/`}
        viewClickUrl={`/finance/${walletId}/invoice/view/`}
        walletId={walletId}
      />
    )}
  </>);
}