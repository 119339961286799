const { default: Api } = require('../../../helpers/Api');

export const getCertificateCreateBasicData = async (obj) => {
    try {
        const resData = await Api.post("procurement/certificate/get-create-data", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createNewAdjustments = async (obj) => {
    try {
        const resData = await Api.post("create/adjustment/certificate/multi-select", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateAdjustments = async (obj) => {
    try {
        const resData = await Api.post("update/adjustment/certificate/multi-select", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateAdjustmentById = async (obj) => {
    try {
        const resData = await Api.post("update/adjustment/by-id", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const deleteAdjustmentById = async (obj) => {
    try {
        const resData = await Api.post("delete/adjustment/by-id", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const submitCertificate = async (obj) => {
    try {
        const resData = await Api.post("submit/certificate", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateCertificateApiCall = async (obj) => {
    try {
        const resData = await Api.post("update/certificate/by-id", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getCertificatePagination = async (obj) => {
    try {
        const resData = await Api.post("get/certificate/pagination", obj);
        const resultData = resData?.data || null;
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};