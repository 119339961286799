import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { useParams } from 'react-router-dom';
import TransactionsTable from "../commonComponent/Transactions.Table";
import TxDetailsDialog from "../commonComponent/TxDetails.Dialog";
import { getTxByWallet, getTxCountByWallet } from "../commonComponent/transaction/api";

const useStyles = makeStyles((theme) => ({
  
}));

const CreditNotes = (props) => {
    const { walletId } = useParams()
    const [openDetails, setOpenDetails] = useState(false)
    const [selectedTx, setSelectedTx] = useState(null)
  
    return (<>
      <TransactionsTable
        walletId={walletId}
        getDataApiHitFun={getTxByWallet}
        getDataObj={{
          walletId: walletId,
          type: "CreditNote"
        }}
        getNumberOfDataRowApiHit={getTxCountByWallet}
        getDataNumberObj={{
          walletId: walletId,
          type: "CreditNote"
        }}
        setSelectedTx={setSelectedTx}
        setOpenDetails={setOpenDetails}
        emptyText={"No credit note available"}
      />
  
      {selectedTx && (
        <TxDetailsDialog
          walletId={walletId}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
          selectedTx={selectedTx}
          setSelectedTx={setSelectedTx}
          editClickUrl={`/finance/${walletId}/credit-note/edit/`}
          viewClickUrl={`/finance/${walletId}/credit-note/view/`}
        />
      )}
    </>);
};

export default CreditNotes;