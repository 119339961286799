import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TxSecondParty from "../../commonComponent/transaction/secondParty";
import TxBillListEdit from "../../commonComponent/transaction/tx.bill.list.edit";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import BillPrintDialog from "./BillPrintDialog";
import EditIcon from "@material-ui/icons/Edit";
import DialogBillNo from "./DialogBillNo";
import axios from "axios";
import configObject from "../../../../config";
import ExportPDF from "../../../styled/generic/ExportPDF";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  billEditBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  nextIcon: {
    transform: "rotate(180deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
}));

export default function BillOtherSettings({
  tx, setTx, chartAccounts, setChartAccounts, offerings, setOfferings,
  txNosArr, setTxNosArr, maxNo, setMaxNo, updateApi, walletId, setOpenPayment,
  setLoadingBool, incomeChartAccounts, setIncomeChartAccounts, expenseChartAccounts,
  setExpenseChartAccounts, needToMakeDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const BudgetUpdateRef = useRef(null);

  const [open, setOpen] = useState(false);
  const oldlateFeeApplicable = tx?.lateFeeApplicable || false;
  const [showGL, setShowGL] = useState(oldlateFeeApplicable);

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <Typography className={classes.headerTitle}>
            {walletId === tx?.firstPartyWallet._id ? (
              <>Expense</>
            ) : (
              <>Receipt</>
            )}
            <span style={{ marginLeft: "10px" }}>#{tx?.txNo}</span>
          </Typography>
          {!needToMakeDisable && (<>
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              <EditIcon className={classes.editIconSty} />
            </IconButton>
            <DialogBillNo
              open={open}
              handleClose={() => {
                setOpen(false);
              }}
              tx={tx}
              setTx={setTx}
              type={"Bill"}
              maxNo={maxNo}
              setMaxNo={setMaxNo}
              walletId={walletId}
              setLoadingBool={setLoadingBool}
              txNos={txNosArr}
            />
          </>)}
        </div>
        <div className={classes.barRight}>
          {!needToMakeDisable && (
            <ExportPDF
              apiUrl="/transaction/get/pdf"
              fileName="bill"
              apiBody={{
                transactionId: tx?._id,
              }}
              enableSignatures={true}
              enableTermsAndConditions={true}
              libraryId={tx?.firstPartyWallet?.defaultLibrary}
              profileId={tx?.firstPartyWallet?.profile}
            />
          )}
          {tx?.secondParty ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenPayment(true);
              }}
              size={isExSmall ? "small" : "medium"}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size={isExSmall ? "small" : "medium"}
              disabled
            >
              Next
            </Button>
          )}
        </div>
      </div>
      <div className={classes.billEditBody}>
        <TxSecondParty
          tx={tx}
          setTx={setTx}
          updateApi={updateApi}
          relationType={"Customer"}
          walletId={walletId}
          needToMakeDisable={needToMakeDisable}
          setLoadingBool={setLoadingBool}
        />
        <TxBillListEdit
          tx={tx}
          setTx={setTx}
          updateApi={updateApi}
          showGL={showGL}
          incomeChartAccounts={incomeChartAccounts}
          setIncomeChartAccounts={setIncomeChartAccounts}
          expenseChartAccounts={expenseChartAccounts}
          setExpenseChartAccounts={setExpenseChartAccounts}
          setLoadingBool={setLoadingBool}
          chartAccounts={chartAccounts}
          setChartAccounts={setChartAccounts}
          offerings={offerings}
          needToMakeDisable={needToMakeDisable}
          setOfferings={setOfferings}
        />
      </div>
    </div>
  );
}
