// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
// import PlaidLogo from "../../../../../Assets/plaidLogo.svg";
// import PlaidBankLinkHelper from "./plaid.bank.link.helper";
// import { Button, ButtonBase } from "@mui/material";
// import Dialog from "../../../../styled/generic/Dialog";
// import FormBox from "../../../../styled/generic/FormBox";
// import TextField from "../../../../styled/generic/TextField";
// import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
// import Api from "../../../../../helpers/Api";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom/cjs/react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   paperStyle: {
//     width: "430px",
//     padding: "15px 20px",
//     boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
//     borderRadius: "15px",
//     [theme.breakpoints.only("xs")]: {
//       width: "90%",
//       padding: "10px 15px",
//       borderRadius: "10px",
//     },
//   },
//   cardHeader: {
//     display: "flex",
//     alignItems: "center",
//     marginBottom: "15px",
//     "& img": {
//       height: "30px",
//     },
//     "& h3": {
//       fontSize: "25px",
//       marginLeft: "10px",
//     },
//     [theme.breakpoints.only("xs")]: {
//       "& img": {
//         height: "25px",
//       },
//       "& h3": {
//         fontSize: "20px",
//         marginLeft: "10px",
//       },
//     },
//   },
//   bankText: {
//     color: "#407bff",
//     fontSize: "16px",
//     fontWeight: "400",
//     fontFamily: `'Poppins', sans-serif`,
//     textAlign: "left",
//     [theme.breakpoints.only("xs")]: {
//       fontSize: "14px",
//     },
//   },
//   paraText: {
//     fontSize: "16px",
//     color: "#263238",
//     fontWeight: "400",
//     fontFamily: `'Poppins', sans-serif`,
//     textAlign: "left",
//     [theme.breakpoints.only("xs")]: {
//       fontSize: "14px",
//     },
//   },
// }));

// export default function IndianLinkBtn(props) {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   const [showBankDialog, setShowBankDialog] = useState(false);
//   const { walletId } = useParams();
//   const [beneficiaryName, setBeneficiaryName] = useState("");
//   const [bankName, setBankName] = useState("");
//   const [accountNumber, setAccountNumber] = useState("");
//   const [ifsc, setIfsc] = useState("");
//   const [branchName, setBranchName] = useState("");

//   const createBank = async () => {
//     try {
//       const { data } = await Api.post("/wallet/indian/bank/create", {
//         beneficiaryName,
//         bankName,
//         accountNumber,
//         ifsc,
//         branchName,
//         wallet: walletId,
//       });
//       if (data) {
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: true,
//             message: "Bank added successfully",
//           },
//         });
//         setShowBankDialog(false);
//       } else {
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: false,
//             message: "An unknown error occurred",
//           },
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       dispatch({
//         type: "AddApiAlert",
//         payload: {
//           success: false,
//           message: "An unknown error occurred" + error,
//         },
//       });
//     }
//   };

//   return (
//     <>
//       <ButtonBase onClick={() => setShowBankDialog(true)}>
//         <Paper className={classes.paperStyle}>
//           <div className={classes.cardHeader}>
//             {/* <img src={PlaidLogo} /> */}
//             <h3>Indian Bank</h3>
//           </div>

//           <p className={classes.bankText}>
//             Click here to add an Indian bank account
//           </p>
//           <p className={classes.paraText}>
//             Add an Indian bank for use in transactions, certificates, demand
//             deeds etc
//           </p>
//         </Paper>
//       </ButtonBase>
//       <Dialog
//         open={showBankDialog}
//         setOpen={setShowBankDialog}
//         title="Indian Bank"
//       >
//         <FormBox label="Beneficiary Name">
//           <TextField
//             value={beneficiaryName}
//             onChange={(e) => setBeneficiaryName(e.target.value)}
//             placeholder="Enter Beneficiary Name"
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Bank Name">
//           <TextField
//             value={bankName}
//             onChange={(e) => setBankName(e.target.value)}
//             placeholder="Enter Bank Name"
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Account Number">
//           <TextField
//             value={accountNumber}
//             onChange={(e) => setAccountNumber(e.target.value)}
//             placeholder="Enter Account Number"
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="IFSC">
//           <TextField
//             value={ifsc}
//             onChange={(e) => setIfsc(e.target.value)}
//             placeholder="Enter IFSC"
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Branch Name">
//           <TextField
//             value={branchName}
//             onChange={(e) => setBranchName(e.target.value)}
//             placeholder="Enter Branch Name"
//             fullWidth={true}
//           />
//         </FormBox>
//         <DuoButtonGroup
//           primaryButtonText="Save"
//           primaryButtonListener={() => createBank()}
//           secondaryButtonText="Cancel"
//           secondaryButtonListener={() => setShowBankDialog(false)}
//         />
//       </Dialog>
//     </>
//   );
// }

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PlaidLogo from "../../../../../Assets/plaidLogo.svg";
import PlaidBankLinkHelper from "./plaid.bank.link.helper";
import { Button, ButtonBase } from "@mui/material";
import Dialog from "../../../../styled/generic/Dialog";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import Api from "../../../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import * as yup from "yup";
const useStyles = makeStyles((theme) => ({

    paperStyle: {
    width: "430px",
    padding: "15px 20px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      padding: "10px 15px",
      borderRadius: "10px",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    "& img": {
      height: "30px",
    },
    "& h3": {
      fontSize: "25px",
      marginLeft: "10px",
    },
    [theme.breakpoints.only("xs")]: {
      "& img": {
        height: "25px",
      },
      "& h3": {
        fontSize: "20px",
        marginLeft: "10px",
      },
    },
  },
  bankText: {
    color: "#407bff",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Poppins', sans-serif`,
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    },
  },
  paraText: {
    fontSize: "16px",
    color: "#263238",
    fontWeight: "400",
    fontFamily: `'Poppins', sans-serif`,
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    },
  },

}));

const validationSchema = yup.object().shape({
  beneficiaryName: yup.string().required("Beneficiary Name is required"),
  bankName: yup.string().required("Bank Name is required"),
  accountNumber: yup.string().required("Account Number is required"),
  ifsc: yup.string().required("IFSC is required"),
  branchName: yup.string().required("Branch Name is required"),
});

export default function IndianLinkBtn({ onAccountAdded, setIsAddingAccount }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showBankDialog, setShowBankDialog] = useState(false);
  const { walletId } = useParams();
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [errors, setErrors] = useState({});

  const createBank = async () => {
        
   
    try {
      await validationSchema.validate(
        { beneficiaryName, bankName, accountNumber, ifsc, branchName },
        { abortEarly: false }
      );
      setIsAddingAccount(true);
      const { data } = await Api.post("/wallet/indian/bank/create", {
        beneficiaryName,
        bankName,
        accountNumber,
        ifsc,
        branchName,
        wallet: walletId,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Bank added successfully",
          },
        });
        setShowBankDialog(false);
        onAccountAdded(); // Call the function to refresh the bank accounts list
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (error) {
            if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.log(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred" + error,
          },
        });
      }
    } finally {
      setIsAddingAccount(false);
    }
  };

  return (
    <>
      <ButtonBase onClick={() => setShowBankDialog(true)}>
        <Paper className={classes.paperStyle}>
          <div className={classes.cardHeader}>
            {/* <img src={PlaidLogo} /> */}
            <h3>Indian Bank</h3>
          </div>

          <p className={classes.bankText}>
            Click here to add an Indian bank account
          </p>
          <p className={classes.paraText}>
            Add an Indian bank for use in transactions, certificates, demand
            deeds etc
          </p>
        </Paper>
      </ButtonBase>
      <Dialog
        open={showBankDialog}
        setOpen={setShowBankDialog}
        title="Indian Bank"
      >
        <FormBox label="Beneficiary Name">
          <TextField
            value={beneficiaryName}
            onChange={(e) => setBeneficiaryName(e.target.value)}
            placeholder="Enter Beneficiary Name"
            error={!!errors.beneficiaryName}
            helperText={errors.beneficiaryName}
            fullWidth={true}
            
          />
        </FormBox>
        <FormBox label="Bank Name">
          <TextField
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            placeholder="Enter Bank Name"
            error={!!errors.bankName}
            helperText={errors.bankName}
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="Account Number">
          <TextField
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder="Enter Account Number"
            error={!!errors.accountNumber}
            helperText={errors.accountNumber}
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="IFSC">
          <TextField
            value={ifsc}
            onChange={(e) => setIfsc(e.target.value)}
            placeholder="Enter IFSC"
            error={!!errors.ifsc}
            helperText={errors.ifsc}
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="Branch Name">
          <TextField
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            placeholder="Enter Branch Name"
            error={!!errors.branchName}
            helperText={errors.branchName}
            fullWidth={true}
          />
        </FormBox>
        <DuoButtonGroup
          primaryButtonText="Save"
          primaryButtonListener={() => createBank()}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setShowBankDialog(false)}
        />
      </Dialog>
    </>
  );
}

// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
// import { ButtonBase } from "@mui/material";
// import Dialog from "../../../../styled/generic/Dialog";
// import FormBox from "../../../../styled/generic/FormBox";
// import TextField from "../../../../styled/generic/TextField";
// import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
// import Api from "../../../../../helpers/Api";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom/cjs/react-router-dom";
// import * as yup from "yup";
// import EditIcon from '@material-ui/icons/Edit';

// const useStyles = makeStyles((theme) => ({
//   paperStyle: {
//     width: "430px",
//     padding: "15px 20px",
//     boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
//     borderRadius: "15px",
//     [theme.breakpoints.only("xs")]: {
//       width: "90%",
//       padding: "10px 15px",
//       borderRadius: "10px",
//     },
//   },
//   cardHeader: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     marginBottom: "15px",
//     "& img": {
//       height: "30px",
//     },
//     "& h3": {
//       fontSize: "25px",
//       marginLeft: "10px",
//     },
//     [theme.breakpoints.only("xs")]: {
//       "& img": {
//         height: "25px",
//       },
//       "& h3": {
//         fontSize: "20px",
//         marginLeft: "10px",
//       },
//     },
//   },
//   bankText: {
//     color: "#407bff",
//     fontSize: "16px",
//     fontWeight: "400",
//     fontFamily: `'Poppins', sans-serif`,
//     textAlign: "left",
//     [theme.breakpoints.only("xs")]: {
//       fontSize: "14px",
//     },
//   },
//   paraText: {
//     fontSize: "16px",
//     color: "#263238",
//     fontWeight: "400",
//     fontFamily: `'Poppins', sans-serif`,
//     textAlign: "left",
//     [theme.breakpoints.only("xs")]: {
//       fontSize: "14px",
//     },
//   },
// }));

// const validationSchema = yup.object().shape({
//   beneficiaryName: yup.string().required("Beneficiary Name is required"),
//   bankName: yup.string().required("Bank Name is required"),
//   accountNumber: yup.string().required("Account Number is required"),
//   ifsc: yup.string().required("IFSC is required"),
//   branchName: yup.string().required("Branch Name is required"),
// });

// export default function IndianLinkBtn({ bankAccount, onAccountUpdated, onAccountAdded, setIsAddingAccount }) {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.auth);
//   const [showBankDialog, setShowBankDialog] = useState(false);
//   const { walletId } = useParams();
//   const [beneficiaryName, setBeneficiaryName] = useState(bankAccount?.beneficiaryName || "");
//   const [bankName, setBankName] = useState(bankAccount?.bankName || "");
//   const [accountNumber, setAccountNumber] = useState(bankAccount?.accountNumber || "");
//   const [ifsc, setIfsc] = useState(bankAccount?.ifsc || "");
//   const [branchName, setBranchName] = useState(bankAccount?.branchName || "");
//   const [errors, setErrors] = useState({});

//   const createOrUpdateBank = async () => {
//     try {
//       await validationSchema.validate(
//         { beneficiaryName, bankName, accountNumber, ifsc, branchName },
//         { abortEarly: false }
//       );
//       setIsAddingAccount(true);
      
//       const payload = {
//         beneficiaryName,
//         bankName,
//         accountNumber,
//         ifsc,
//         branchName,
//         wallet: walletId,
//       };

//       if (bankAccount) {
//         const { data } = await Api.put(`/wallet/indian/bank/update/${bankAccount.id}`, payload);
//         if (data) {
//           dispatch({
//             type: "AddApiAlert",
//             payload: {
//               success: true,
//               message: "Bank updated successfully",
//             },
//           });
//          ;
//           setShowBankDialog(false);
//           onAccountUpdated(); // Call the function to refresh the bank accounts list
//         } else {
//           dispatch({
//             type: "AddApiAlert",
//             payload: {
//               success: false,
//               message: "An unknown error occurred",
//             },
//           });
//         }
//       } else {
//         const { data } = await Api.post("/wallet/indian/bank/create", payload);
//         if (data) {
//           dispatch({
//             type: "AddApiAlert",
//             payload: {
//               success: true,
//               message: "Bank added successfully",
//             },
//           });
//           setShowBankDialog(false);
//           onAccountAdded(); // Call the function to refresh the bank accounts list
//         } else {
//           dispatch({
//             type: "AddApiAlert",
//             payload: {
//               success: false,
//               message: "An unknown error occurred",
//             },
//           });
//         }
//       }
//     } catch (error) {
//       if (error.name === "ValidationError") {
//         const validationErrors = {};
//         error.inner.forEach((err) => {
//           validationErrors[err.path] = err.message;
//         });
//         setErrors(validationErrors);
//       } else {
//         console.log(error);
//         dispatch({
//           type: "AddApiAlert",
//           payload: {
//             success: false,
//             message: "An unknown error occurred" + error,
//           },
//         });
//       }
//     } finally {
//       setIsAddingAccount(false);
//     }
//   };

//   return (
//     <>
//       <ButtonBase onClick={() => setShowBankDialog(true)}>
//         <Paper className={classes.paperStyle}>
//           <div className={classes.cardHeader}>
//             <h3>Indian Bank</h3>
            
//           </div>

//           <p className={classes.bankText}>
//             Click here to add an Indian bank account
//           </p>
//           <p className={classes.paraText}>
//             Add an Indian bank for use in transactions, certificates, demand deeds etc
//           </p>
//         </Paper>
//       </ButtonBase>
//       <Dialog
//         open={showBankDialog}
//         setOpen={setShowBankDialog}
//         title={bankAccount ? "Edit Indian Bank" : "Indian Bank"}
//       >
//         <FormBox label="Beneficiary Name">
//           <TextField
//             value={beneficiaryName}
//             onChange={(e) => setBeneficiaryName(e.target.value)}
//             placeholder="Enter Beneficiary Name"
//             error={!!errors.beneficiaryName}
//             helperText={errors.beneficiaryName}
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Bank Name">
//           <TextField
//             value={bankName}
//             onChange={(e) => setBankName(e.target.value)}
//             placeholder="Enter Bank Name"
//             error={!!errors.bankName}
//             helperText={errors.bankName}
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Account Number">
//           <TextField
//             value={accountNumber}
//             onChange={(e) => setAccountNumber(e.target.value)}
//             placeholder="Enter Account Number"
//             error={!!errors.accountNumber}
//             helperText={errors.accountNumber}
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="IFSC">
//           <TextField
//             value={ifsc}
//             onChange={(e) => setIfsc(e.target.value)}
//             placeholder="Enter IFSC"
//             error={!!errors.ifsc}
//             helperText={errors.ifsc}
//             fullWidth={true}
//           />
//         </FormBox>
//         <FormBox label="Branch Name">
//           <TextField
//             value={branchName}
//             onChange={(e) => setBranchName(e.target.value)}
//             placeholder="Enter Branch Name"
//             error={!!errors.branchName}
//             helperText={errors.branchName}
//             fullWidth={true}
//           />
//         </FormBox>
//         <DuoButtonGroup
//           primaryButtonText={bankAccount ? "Update" : "Save"}
//           primaryButtonListener={() => createOrUpdateBank()}
//           secondaryButtonText="Cancel"
//           secondaryButtonListener={() => setShowBankDialog(false)}
//         />
//       </Dialog>
//     </>
//   );
// }




