import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NormalDialog from "../../../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles((theme) => ({
    inputsCont: {
        width: "290px"
    },
    styleInputRadio: {
        width: "15px",
        height: "15px",
        marginRight: "7px"
    },
    radioTextCont: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& p": {
            fontSize: "14px",
            fontWeight: "510"
        }
    },
    styleDialogText: {
        width: "45px",
        height: "35px",
        marginLeft: "30px",
        paddingLeft: "5px"
    },
    inputTextCont: {
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            marginLeft: "5px"
        }
    },
    textSty: {
        marginLeft: "30px",
        fontSize: "14px",
        fontWeight: "510",
        marginTop: "7px",
        marginBottom: "3px"
    },
    btnCont: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "15px"
    }
}))


function TermCreateDialog(props) {
    const classes = useStyles();
    const {
        open, handleClose, setOnlyDays, setOnlyDayMonth, onlyDayMonth, onlyDays,
        termName, setTermName, termDays, setTermDays, termDMDay, setTermDMDay,
        termDMMonth, setTermDMMonth, saveNewTerm
    } = props;


    return (
        <NormalDialog
            openDialog={open}
            handleCloseShare={handleClose}
            pageTitle={"Add New Term"}
            content={<form onSubmit={saveNewTerm} >
                <div className={classes.inputsCont} >
                    <TextField
                        variant="outlined"
                        id="name"
                        label="Name"
                        value={termName}
                        onChange={(event) => { setTermName(event.target.value) }}
                        type="text"
                        size="small"
                        fullWidth
                        style={{ margin: "10px 0px 20px" }}
                    />
                    <div className={classes.radioTextCont} >
                        <input
                            className={classes.styleInputRadio}
                            type="radio"
                            name="termType"
                            value={onlyDays}
                            onClick={() => {
                                setOnlyDays(true);
                                setOnlyDayMonth(false);
                            }}
                        />
                        <p>Due in fixed number of days</p>
                    </div>
                    <div className={classes.inputTextCont} style={{ marginBottom: "15px" }} >
                        <input
                            className={classes.styleDialogText}
                            type="number"
                            value={termDays}
                            onChange={(event) => { setTermDays(event.target.value) }}
                            disabled={!onlyDays}
                        />
                        <p>days</p>
                    </div>
                    <div className={classes.radioTextCont} >
                        <input
                            className={classes.styleInputRadio}
                            type="radio"
                            name="termType"
                            value={onlyDayMonth}
                            onClick={() => {
                                setOnlyDays(false);
                                setOnlyDayMonth(true);
                            }}
                        />
                        <p>Due by certain day of the month</p>
                    </div>
                    <div className={classes.inputTextCont} >
                        <input
                            className={classes.styleDialogText}
                            type="number"
                            value={termDMDay}
                            onChange={(event) => { setTermDMDay(event.target.value) }}
                            disabled={!onlyDayMonth}
                        />
                        <p>{`days of month ( <= 31 )`}</p>
                    </div>
                    <p className={classes.textSty} >
                        Due the next month if issued within
                    </p>
                    <div className={classes.inputTextCont} >
                        <input
                            className={classes.styleDialogText}
                            type="number"
                            value={termDMMonth}
                            onChange={(event) => { setTermDMMonth(event.target.value) }}
                            disabled={!onlyDayMonth}
                        />
                        <p>days of due date</p>
                    </div>
                </div>

                <div className={classes.btnCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        disabled={termName && termName.length > 3 && ((onlyDays && termDays) || (onlyDayMonth && termDMDay && termDMDay <= 31 && termDMMonth)) ? false : true}
                    >
                        Save
                    </Button>
                </div>
            </form>}
        />
    )
}

export default TermCreateDialog;