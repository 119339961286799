import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import arrayToReducer from '../../../../../helpers/arrayToReducer';
import AddIcon from '@material-ui/icons/Add';
import { addBillIteminBillList } from '../../../commonComponent/transaction/api';

const useStyles = makeStyles((theme) => ({

}));

export default function AddItemBtn({
  billList, setBillList, billItemDictionary, setBillItemDictionary,
  type, defaultOfferingRelation, itemChartAccount, setLoadingBool,
  size = null
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const addItem = async () => {
    if (billList?._id) {
      setLoadingBool(true);
      await addBillIteminBillList({
        billListId: billList._id,
        type,
        defaultOfferingRelation,
        itemChartAccount
      })
        .then((data) => {
          const billItem = data?.billItem;
          const { newDict } = arrayToReducer([billItem]);
          setBillList({
            ...billList,
            items: [...billList.items, billItem?._id],
          });
          setBillItemDictionary({
            ...billItemDictionary,
            ...newDict,
          });

          setLoadingBool(false);
        })
        .catch((err) => {
          setLoadingBool(false);
          console.log(err)
        })
    }
  };

  return (
    <Button
      onClick={addItem}
      variant="contained"
      color="primary"
      size={size ? size : isExSmall ? "small" : "medium"}
      startIcon={<AddIcon />}
    >
      Add Bill Item
    </Button>
  );
}