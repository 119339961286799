import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ConnectStripeBtn from './stripe/connect.stripe.btn';
import StripeCustomer from './stripe/stripe.customer'
import ConnectDwollaBtn from './dwolla/connect.dwolla.btn';
import DwollaCustomer from './dwolla/dwolla.customer';
import PaymentMethodSkeleton from '../../../styled/skeletons/PaymentMethodSkeleton';
import { getPopulatedWalletData } from '../api.call'
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 30px 30px",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down('md')]: {
      padding: "15px 15px 30px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "10px 10px 30px",
    },
  }
}));

export default function PaymentMethods(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { walletId } = useParams();

  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    getPopulatedWalletData({ walletId })
      .then((data) => {
        setWallet(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [walletId]);


  return (
    <div className={classes.root} >
      {wallet?._id ? (
        <>
          {wallet?.dwollaCustomer ? (
            <DwollaCustomer
              wallet={wallet}
              setWallet={setWallet}
            />
          ) : (
            <ConnectDwollaBtn
              wallet={wallet}
            />
          )}

          {wallet?.stripeConnectAccountId ? (
            <StripeCustomer
              wallet={wallet}
              setWallet={setWallet}
            />
          ) : (
            <ConnectStripeBtn
              wallet={wallet}
              setWallet={setWallet}
            />
          )}
        </>
      ) : <>
        <PaymentMethodSkeleton />
        <PaymentMethodSkeleton />
      </>}
    </div>
  );
}