import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import ChartAccountTable from './Chart.Account.Table';
import { getChartAccounts } from './api';
import { useHistory, useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },

    row: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },

    col: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },

    title: {
        flex: 1,
        display: 'flex',
        fontSize: '1.5rem',
        fontWeight: '700',
        margin: '1.5rem',
    },


}));

export default function ChartAccounts(props) {
    const { walletId } = useParams();
    const classes = useStyles();

    const {
        root, row, col, title,
    } = classes;

    const [show, setShow] = useState("asset")
    const [group, setGroup] = useState()
    const [accts, setAccts] = useState([])

    useEffect(() => {
        const myGroup = _.groupBy(accts, 'topLevel');
        setGroup(myGroup);
    }, [accts])


    useEffect(() => {
        getChartAccounts(walletId)
            .then(data => {
                if (data.length > 0) {
                    setAccts(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [walletId])





    return (
        <div className={root}>

            <ChartAccountTable
                dataRow={accts}
            />

        </div>
    );
}
