import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import RelatedTxs from './RelatedTxs';

const useStyles = makeStyles((theme) => ({
    relatedTxCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    }
}));

export default function ShowFullTxs(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { walletId, secondPartyWallet, option } = useParams();

    return (
        <div className={classes.relatedTxCont} >
            <RelatedTxs
                walletId={walletId}
                secondPartyWallet={secondPartyWallet}
            />
        </div>
    );
}