import {
  AlternateEmail,
  BusinessOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  Email,
  LanguageOutlined,
  LocalAtmOutlined,
  LocationOnOutlined,
  PaymentOutlined,
  Person,
  Phone,
  PhoneAndroid,
} from "@material-ui/icons";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getGeocodeFromZip from "../../helpers/geoCode";
import Api from "../../helpers/Api";

const StyledTextField = ({ label, type, icon, size, value, onChange }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <OutlinedInput
        type={type ? type : "text"}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        placeholder={label}
        size={size}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </FormControl>
  );
};

const PalForm = ({ text, onSelect, setLoading }) => {
  const { user, userProfile } = useSelector((state) => state.auth);
  const [type, setType] = useState("User");
  const [fullName, setFullName] = useState(text || "");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  const [taxId, setTaxId] = useState("");
  const relations = [
    "Contractor",
    "Customer",
    "Employee",
    "Investor",
    "Lender",
    "Vendor",
    "Tenant",
  ];

  const [userRelation, setUserRelation] = useState(false);
  const [projectRelation, setProjectRelation] = useState(false);
  const [orgRelation, setOrgRelation] = useState(false);
  const [userRelationVal, setUserRelationVal] = useState(relations[0]);
  const [projectRelationVal, setProjectRelationVal] = useState(relations[0]);
  const [orgRelationVal, setOrgRelationVal] = useState(relations[0]);
  const [projectRelationData, setProjectRelationData] = useState([]);
  const [orgRelationData, setOrgRelationData] = useState([]);

  const [projectOptions, setProjectOptions] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);

  const getBasicData = async () => {
    const res = await Api.post("shared/getBasicData", {
      userProfileId: user.profile,
    });

    if (res?.data) {
      const data = res?.data;
      const orgTeamRes = data.orgTeams;
      const projectTeamRes = data.projectTeams;
      let projects = projectTeamRes.map((item) => item.parent);
      let orgs = orgTeamRes.map((item) => item.parent);
      setProjectOptions(projects);
      setOrgOptions(orgs);
    }
  };

  useEffect(() => {
    getBasicData();
  }, []);

  const createPal = async () => {
    let zipCodeData = {};
    setLoading(true);
    if (zip.length > 0) {
      zipCodeData = await getGeocodeFromZip(zip);
    }

    await Api.post("pal/create", {
      type: type,
      address: {
        ...zipCodeData,
        postal_code: zip,
        line1: address,
      },
      displayName: fullName,
      email: email.toLowerCase(),
      phone: phone,
      mobile: mobile,
      companyName: companyName,
      companyPhone: companyPhone,
      zip: zip,
      website: website,
      pan: PAN,
      taxId: taxId,
      parent: user._id,
      parentModelName: user.model,
      user: user.model === "User" ? user._id : userProfile._id,
      userProfile: user.profile,
      userRelation,
      projectRelation,
      orgRelation,
      userRelationVal,
      projectRelationVal,
      orgRelationVal,
      projectRelationData,
      orgRelationData,
    }).then((res) => {
      const data = res?.data;

      if (onSelect && data) {
        onSelect(data);
      }
    });

    setLoading(false);
  };

  return (
    <Box style={{ width: "60vw" }}>
      <FormControl>
        <RadioGroup row value={type} onChange={(evt, val) => setType(val)}>
          <FormControlLabel value="User" control={<Radio />} label="User" />
          <FormControlLabel
            value="Organization"
            control={<Radio />}
            label="Organization"
          />
          <FormControlLabel value="Group" control={<Radio />} label="Group" />
        </RadioGroup>
      </FormControl>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={6}>
          <StyledTextField
            label="Full Name"
            size="small"
            icon={<Person />}
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </Grid>
        {type !== "Group" && (
          <>
            <Grid item xs={6}>
              <StyledTextField
                label="Email"
                size="small"
                icon={<AlternateEmail />}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                label="Mobile"
                size="small"
                icon={<PhoneAndroid />}
                type="email"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                label="Telephone"
                size="small"
                type="number"
                icon={<Phone />}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            {type === "User" && (
              <>
                <Grid item xs={6}>
                  <StyledTextField
                    label="Company Name"
                    size="small"
                    icon={<BusinessOutlined />}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField
                    fullWidth
                    label="Company Phone"
                    size="small"
                    icon={<Phone />}
                    value={companyPhone}
                    onChange={(e) => setCompanyPhone(e.target.value)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                label="Address"
                size="small"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                label="Zip"
                size="small"
                type="number"
                icon={<LocationOnOutlined />}
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                label="Website"
                size="small"
                icon={<LanguageOutlined />}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                label="PAN"
                size="small"
                icon={<LocalAtmOutlined />}
                value={PAN}
                onChange={(e) => setPAN(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth
                variant="outlined"
                label="TAX ID"
                size="small"
                icon={<PaymentOutlined />}
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Add In Directory
            </Typography>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box style={{ width: "190px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userRelation}
                        onChange={(evt, checked) => setUserRelation(checked)}
                      />
                    }
                    label="With Myself"
                  />
                </Box>

                <Select
                  size="small"
                  value={userRelationVal}
                  onChange={(e) => setUserRelationVal(e.target.value)}
                  style={{ minWidth: "120px" }}
                >
                  {relations.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box style={{ width: "190px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={projectRelation}
                        onChange={(evt, checked) => setProjectRelation(checked)}
                      />
                    }
                    label="With Project"
                  />
                </Box>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={projectOptions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.displayName}
                  onChange={(evt, val) => setProjectRelationData(val)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.displayName}
                    </li>
                  )}
                  style={{ width: "200px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Projects"
                      placeholder="Select Projects"
                      size="small"
                    />
                  )}
                />

                <Select
                  size="small"
                  value={projectRelationVal}
                  onChange={(e) => setProjectRelationVal(e.target.value)}
                  style={{ minWidth: "120px" }}
                >
                  {relations.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box style={{ width: "190px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={orgRelation}
                        onChange={(evt, checked) => setOrgRelation(checked)}
                      />
                    }
                    label="With Org"
                  />
                </Box>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={orgOptions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.displayName}
                  onChange={(evt, val) => setOrgRelationData(val)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.displayName}
                    </li>
                  )}
                  style={{ width: "200px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organizations"
                      placeholder="Select Organizations"
                      size="small"
                    />
                  )}
                />

                <Select
                  size="small"
                  value={orgRelationVal}
                  onChange={(e) => setOrgRelationVal(e.target.value)}
                  style={{ minWidth: "120px" }}
                >
                  {relations.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        onClick={() => createPal()}
        style={{ marginTop: "10px" }}
      >
        Create
      </Button>
    </Box>
  );
};

export default PalForm;

// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { withFormik } from "formik";
// import * as Yup from "yup";
// import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, withStyles } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { connect } from "react-redux";
// import Api from "../../helpers/Api";
// import getGeocodeFromZip from "../../helpers/geoCode";
// import PersonIcon from "@material-ui/icons/Person";
// import BusinessIcon from "@material-ui/icons/Business";

// const styles = () => ({
// 	card: {
// 		flex: 1,
// 		display: "flex",
// 		maxWidth: 420,
// 		marginTop: 50,
// 	},
// 	row: {
// 		flex: 1,
// 		display: "flex",
// 		flexDirection: "row",
// 		marginBottom: 0,
// 		alignItems: "center",
// 	},
// 	rowReverse: {
// 		flex: 1,
// 		display: "flex",
// 		flexDirection: "row-reverse",
// 		marginBottom: 0,
// 	},
// 	zipCode: {
// 		width: "9rem",
// 		marginLeft: "1rem",
// 	},
// 	container: {
// 		display: "Flex",
// 		justifyContent: "center",
// 		padding: "10px 20px",
// 	}
// });

// class UserForm extends React.Component {
// 	render() {
// 		const { classes, pal, setPal, values, touched, errors,
// 			isSubmitting, handleChange, handleBlur, handleSubmit,
// 		} = this.props;

// 		return (
// 			<div className={classes.container}>
// 				<form onSubmit={handleSubmit}>
// 					<TextField
// 						id="fullName"
// 						label="Name"
// 						placeholder="Enter Name"
// 						value={values.fullName}
// 						onChange={handleChange}
// 						onBlur={handleBlur}
// 						helperText={touched.fullName ? errors.fullName : ""}
// 						error={touched.fullName && Boolean(errors.fullName)}
// 						margin="dense"
// 						variant="outlined"
// 						fullWidth
// 					/>
// 					<TextField
// 						id="email"
// 						label="Email"
// 						type="email"
// 						value={values.email}
// 						onChange={handleChange}
// 						onBlur={handleBlur}
// 						helperText={touched.email ? errors.email : ""}
// 						error={touched.email && Boolean(errors.email)}
// 						margin="dense"
// 						variant="outlined"
// 						fullWidth
// 					/>
// 					<div className={classes.row} style={{ marginTop: "5px" }}>
// 						<PhoneInput
// 							id="phone"
// 							placeholder="Phone Number"
// 							country={"us"}
// 							value={pal.phone}
// 							onChange={(phone) => {
// 								setPal({
// 									...pal,
// 									phone,
// 								});
// 							}}
// 						/>
// 					</div>
// 					<FormControl
// 						component="fieldset"
// 						style={{ marginTop: "10px" }}
// 					>
// 						<FormLabel component="legend">Type</FormLabel>
// 						<RadioGroup
// 							style={{ flexDirection: "row" }}
// 							name="gender1"
// 							value={pal.isPerson ? 1 : 0}
// 							onChange={(event) => {
// 								setPal({
// 									...pal,
// 									isPerson:
// 										event.target.value == 1 ? true : false,
// 								});
// 							}}
// 						>
// 							<FormControlLabel
// 								value={1}
// 								control={<Radio color="primary" />}
// 								label={
// 									<div
// 										style={{
// 											display: "flex",
// 											alignItems: "center",
// 											gap: "4px",
// 										}}
// 									>
// 										<PersonIcon /> <span>Person</span>
// 									</div>
// 								}
// 								labelPlacement="End"
// 							/>
// 							<FormControlLabel
// 								value={0}
// 								control={<Radio color="primary" />}
// 								label={
// 									<div
// 										style={{
// 											display: "flex",
// 											alignItems: "center",
// 											gap: "4px",
// 										}}
// 									>
// 										<BusinessIcon />{" "}
// 										<span>Organization</span>
// 									</div>
// 								}
// 								labelPlacement="End"
// 							/>
// 						</RadioGroup>
// 					</FormControl>
// 					<TextField
// 						id="address"
// 						multiline
// 						rows={2}
// 						value={values.address}
// 						placeholder={"Address"}
// 						onChange={handleChange}
// 						onBlur={handleBlur}
// 						label="Address"
// 						helperText={touched.address ? errors.address : ""}
// 						error={touched.address && Boolean(errors.address)}
// 						variant="outlined"
// 						fullWidth
// 					/>

// 					<div className={classes.row}>
// 						<TextField
// 							id="zip"
// 							label="Zipcode"
// 							value={values.zip}
// 							onChange={handleChange}
// 							onBlur={handleBlur}
// 							helperText={touched.zip ? errors.zip : ""}
// 							error={touched.zip && Boolean(errors.zip)}
// 							margin="dense"
// 							variant="outlined"
// 						/>
// 					</div>
// 					<div className={classes.rowReverse}>
// 						<Button
// 							style={{ width: "100%", marginTop: "10px" }}
// 							type="submit"
// 							variant="contained"
// 							color="primary"
// 							disabled={isSubmitting}
// 						>
// 							Add
// 						</Button>
// 					</div>
// 				</form>
// 			</div>
// 		);
// 	}
// }

// const StyledUserForm = withStyles(styles)(UserForm);

// const Form = withFormik({
// 	mapPropsToValues: ({ fullName, email, address, zip, pal }) => {
// 		return {
// 			fullName: pal?.displayName || "",
// 			zip: zip || "",
// 			address: address || "",
// 			email: email || "",
// 		};
// 	},

// 	validationSchema: Yup.object().shape({
// 		fullName: Yup.string()
// 			.min(3, "Fullname must contain at least 3 characters")
// 			.required("Required"),
// 		email: Yup.string().email("Enter a valid email"),
// 	}),

// 	handleSubmit: async (values, { props, setSubmitting, setValues }) => {
// 		const { pal, callback, setLoading } = props;
// 		let zipCodeData = {};
// 		setLoading(true)
// 		if (values.zip.length > 0) {
// 			zipCodeData = await getGeocodeFromZip(values.zip);
// 		}

// 		await Api.post("pal/create", {
// 			...pal,
// 			type: pal.isPerson ? "User" : "Organization",
// 			address: {
// 				...zipCodeData,
// 				postal_code: values.zip,
// 				line1: values.address,
// 			},
// 			displayName: values.fullName,
// 			email: values.email.toLowerCase(),
// 		}).then((res) => {
// 			const data = res?.data;

// 			if (callback && data) {
// 				callback(data);
// 			}
// 		});

// 		setLoading(false)
// 	},
// })(StyledUserForm);

// const mapStateToProps = ({ auth }) => {
// 	const { user, userProfile } = auth;
// 	return { user, userProfile };
// };

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		dispatch,
// 	};
// };

// const ConnectForm = connect(mapStateToProps, mapDispatchToProps)(Form);

// export default function AddMember(props) {
// 	const { text, onSelect, setLoading } = props;
// 	const { user, userProfile } = useSelector((state) => state.auth);

// 	const initPal = {
// 		isPerson: false,
// 		displayName: text,
// 		email: "",
// 		phone: "",
// 		address: "",
// 		zip: "",
// 		parent: user._id,
// 		parentModelName: user.model,
// 		user: user.model === "User" ? user._id : userProfile._id,
// 	};
// 	const [pal, setPal] = useState(initPal);

// 	return (
// 		<ConnectForm
// 			pal={pal}
// 			initPal={initPal}
// 			setPal={setPal}
// 			setLoading={setLoading}
// 			callback={(profile) => {
// 				if (onSelect) {
// 					onSelect(profile);
// 				}
// 			}}
// 		/>
// 	);
// }
