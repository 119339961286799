import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { useParams } from 'react-router-dom';
import TransactionsTable from "../commonComponent/Transactions.Table";
import TxDetailsDialog from "../commonComponent/TxDetails.Dialog";
import { getTxByWallet, getTxCountByWallet } from "../commonComponent/transaction/api";

const useStyles = makeStyles((theme) => ({

}));

const DebitNotes = (props) => {
    const { walletId } = useParams()
    const [openDetails, setOpenDetails] = useState(false)
    const [selectedTx, setSelectedTx] = useState(null)

    return (<>
        <TransactionsTable
            walletId={walletId}
            getDataApiHitFun={getTxByWallet}
            getDataObj={{
                walletId: walletId,
                type: "DebitNote"
            }}
            getNumberOfDataRowApiHit={getTxCountByWallet}
            getDataNumberObj={{
                walletId: walletId,
                type: "DebitNote"
            }}
            setSelectedTx={setSelectedTx}
            setOpenDetails={setOpenDetails}
            emptyText={"No debit note available"}
        />

        {selectedTx && (
            <TxDetailsDialog
                walletId={walletId}
                openDetails={openDetails}
                setOpenDetails={setOpenDetails}
                selectedTx={selectedTx}
                setSelectedTx={setSelectedTx}
                editClickUrl={`/finance/${walletId}/debit-note/edit/`}
                viewClickUrl={`/finance/${walletId}/debit-note/view/`}
            />
        )}
    </>);
};

export default DebitNotes;