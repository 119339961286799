import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TransactionsTable from '../commonComponent/Transactions.Table';
import { getTxCountInMutual, getTxsInMututal } from '../commonComponent/transaction/api';

const useStyles = makeStyles((theme) => ({
    tabsPanel: {
        width: "100%",
    },
    tableCont: {
        position: "relative",
        width: "100%",
        height: "calc(100% - 50px)",
        overflow: "hidden",
    },
    innerSlideCont: {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%"
    }
}));

const CustomTab = withStyles({
    root: {
        textTransform: "none",
        fontSize: "1rem",
        minWidth: 50,
    },
})(Tab);

export default function RelatedTxs({ walletId, secondPartyWallet }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [value, setValue] = useState(0)
    const [view, setView] = useState("allTransactions")
    const [openDetailsInvoice, setOpenDetailsInvoice] = useState(false)
    const [selectedTxInvoice, setSelectedTxInvoice] = useState(null)

    return (<>
        <div className={classes.tabsPanel}>
            <Tabs
                value={value}
                onChange={(event, newValue) => { setValue(newValue) }}
                TabIndicatorProps={{ style: { backgroundColor: "#17804f" } }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <CustomTab
                    label={<div>All Transactions</div>}
                    onClick={() => {
                        setView("allTransactions");
                        history.push({ search: `?tab=allTransactions` });
                    }}
                />
                <CustomTab
                    label={<div>Invoice</div>}
                    onClick={() => {
                        setView("invoice");
                        history.push({ search: `?tab=invoice` });
                    }}
                />
                <CustomTab
                    label={<div>Bill</div>}
                    onClick={() => {
                        setView("bill");
                        history.push({ search: `?tab=bill` });
                    }}
                />
            </Tabs>
        </div>

        <div className={classes.tableCont} >
            <Slide direction="left" in={view === "allTransactions" ? true : false} mountOnEnter unmountOnExit>
                <div className={classes.innerSlideCont} >
                    <TransactionsTable
                        walletId={walletId}
                        getDataApiHitFun={getTxsInMututal}
                        getDataObj={{
                            walletId: walletId,
                            secondPartyWallet: secondPartyWallet
                        }}
                        getNumberOfDataRowApiHit={getTxCountInMutual}
                        getDataNumberObj={{
                            walletId: walletId,
                            secondPartyWallet: secondPartyWallet,
                        }}
                        setSelectedTx={setSelectedTxInvoice}
                        setOpenDetails={setOpenDetailsInvoice}
                        emptyText={"No transaction is available"}
                    />


                </div>
            </Slide>

            <Slide direction="left" in={view === "invoice" ? true : false} mountOnEnter unmountOnExit>
                <div className={classes.innerSlideCont} >
                    <TransactionsTable
                        walletId={walletId}
                        getDataApiHitFun={getTxsInMututal}
                        getDataObj={{
                            walletId: walletId,
                            secondPartyWallet: secondPartyWallet,
                            type: "Invoice"
                        }}
                        getNumberOfDataRowApiHit={getTxCountInMutual}
                        getDataNumberObj={{
                            walletId: walletId,
                            secondPartyWallet: secondPartyWallet,
                            type: "Invoice"
                        }}
                        setSelectedTx={setSelectedTxInvoice}
                        setOpenDetails={setOpenDetailsInvoice}
                        emptyText={"No invoice or receipt is available"}
                    />


                </div>
            </Slide>

            <Slide direction="left" in={view === "bill" ? true : false} mountOnEnter unmountOnExit>
                <div className={classes.innerSlideCont} >
                    <TransactionsTable
                        walletId={walletId}
                        getDataApiHitFun={getTxsInMututal}
                        getDataObj={{
                            walletId: walletId,
                            secondPartyWallet: secondPartyWallet,
                            type: "Bill"
                        }}
                        getNumberOfDataRowApiHit={getTxCountInMutual}
                        getDataNumberObj={{
                            walletId: walletId,
                            secondPartyWallet: secondPartyWallet,
                            type: "Bill"
                        }}
                        setSelectedTx={setSelectedTxInvoice}
                        setOpenDetails={setOpenDetailsInvoice}
                        emptyText={"No expense or bill is available"}
                    />


                </div>
            </Slide>
        </div>
    </>);
}