import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useMediaQuery } from '@material-ui/core';
import { updateTx, updateTxTemplate } from '../../commonComponent/transaction/api';
import { updateJournalEntry } from "../../financialStatements/journalEntry/api";
import NormalDialog from "../../../styled/CommonComponents/NormalDialog";
import { useDebounce } from "react-use";

function DialogBillNo({
    open, handleClose, txNos, tx, setTx, type,
    maxNo, setMaxNo, walletId, setLoadingBool
}) {
    const theme = useTheme();

    const [newBillNo, setNewBillNo] = useState();
    const [defaultCounter, setDefaultCounter] = useState(maxNo);
    const [check, setCheck] = useState(false);
    const [checkCounter, setCheckCounter] = useState(false);

    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        setNewBillNo(parseInt(tx?.txNo))
    }, [tx?.txNo])

    useEffect(() => {
        setDefaultCounter(parseInt(maxNo))
    }, [maxNo])

    useEffect(() => {
        setCheck(false)
        setCheckCounter(false)
    }, [newBillNo, defaultCounter])

    useDebounce(() => {
        setCheck(false);
        txNos.map((txBill) => {
            if (txBill == newBillNo) {
                setCheck(true);
                return;
            }
        })
        if (newBillNo > maxNo) {
            setMaxNo(newBillNo)
            setDefaultCounter(parseInt(maxNo))
        }
    }, 1000, [defaultCounter, newBillNo]);

    useDebounce(() => {
        if (defaultCounter < maxNo) {
            setCheckCounter(true);
        }
    }, 1000, [newBillNo, defaultCounter])

    const onSubmit = async () => {
        handleClose();
        setLoadingBool(true)

        if (type === "Bill") {
            tx.txNo = newBillNo;
            await updateTx({
                txNo: newBillNo,
                _id: tx._id,
                type: type,
                defaultCounter: { billCounter: defaultCounter },
                editCounter: true,
                walletId: walletId
            })
        } else if (type === "Invoice") {
            tx.txNo = newBillNo;
            await updateTx({
                txNo: newBillNo,
                _id: tx._id,
                type: type,
                defaultCounter: { invoiceCounter: defaultCounter },
                editCounter: true,
                walletId: walletId
            })
        } else if (type === "DebitNote") {
            tx.txNo = newBillNo;
            await updateTx({
                txNo: newBillNo,
                _id: tx._id,
                type: type,
                defaultCounter: { debitNoteCounter: defaultCounter },
                editCounter: true,
                walletId: walletId
            })
        } else if (type === "CreditNote") {
            tx.txNo = newBillNo;
            await updateTx({
                txNo: newBillNo,
                _id: tx._id,
                type: type,
                defaultCounter: { creditNoteCounter: defaultCounter },
                editCounter: true,
                walletId: walletId
            })
        }
        else if (type === "InvoiceTemplate") {
            tx.txNo = newBillNo;
            await updateTxTemplate({
                txNo: newBillNo,
                _id: tx._id,
                type: "Invoice",
                defaultCounter: { templateInvCounter: defaultCounter },
                editCounter: true,
                walletId: walletId
            })
        } else if (type === "BillTemplate") {
            tx.txNo = newBillNo;
            await updateTxTemplate({
                txNo: newBillNo,
                _id: tx._id,
                type: "Bill",
                defaultCounter: { templateBillCounter: defaultCounter },
                editCounter: true,
                walletId: walletId
            })
        } else if (type === "Jornal Entry") {
            tx.journalEntryNo = newBillNo;
            await updateJournalEntry({
                _id: tx._id,
                journalEntryNo: newBillNo,
                defaultCounter: defaultCounter,
                editCounter: true,
                walletId: walletId
            })
        }

        setTx(tx);
        setLoadingBool(false)
    }

    return (
        <NormalDialog
            openDialog={open}
            handleCloseShare={handleClose}
            pageTitle={`Edit ${type} No`}
            content={<>
                <div style={isExSmall ? { padding: "7px" } : {}} >
                    <TextField
                        autoFocus
                        label={`${type} No`}
                        type="number"
                        value={newBillNo}
                        onChange={(event) => { setNewBillNo(event.target.value) }}
                        style={{ width: "250px" }}
                    />
                    <div style={{ marginBottom: "20px" }} >
                        {check ? (<p style={{ color: 'red' }} >{type} No Already Exist</p>) : null}
                    </div>
                    <TextField
                        autoFocus
                        label={`Default ${type} Counter`}
                        type="number"
                        value={defaultCounter}
                        onChange={(event) => { setDefaultCounter(event.target.value) }}
                        style={{ width: "250px" }}
                    />
                    {checkCounter ? (<p style={{ color: 'red' }} >The number should be bigger then {maxNo - 1}</p>) : null}
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "15px" }} >
                    {!check && !checkCounter && defaultCounter && newBillNo ?
                        (
                            <Button
                                onClick={onSubmit}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                Save
                            </Button>
                        )
                        :
                        (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled
                                size="small"
                            >
                                Save
                            </Button>
                        )
                    }
                </div>
            </>
            }
        />
    )
}

export default DialogBillNo;