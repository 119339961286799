import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, TextField, Tabs, Tab } from '@material-ui/core';
import { submitMultipleTransaction } from '../commonComponent/transaction/api';
import Button from '@material-ui/core/Button';
import { getIncomeChartAccounts } from '../productsAndServices/utils';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NormalDialog from '../../styled/CommonComponents/NormalDialog';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { GrMoney } from "react-icons/gr";
import ChartAccountCreateForBank from '../salesAndInvoices/ChartAccountCreateForBank';
import "../salesAndInvoices/index.css"

const paymentTypes = ["Cash", "Cheque"]

const useStyles = makeStyles((theme) => ({
    invoiceEditBody: {
        width: "100%",
        marginTop: "30px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    sectionTop: {
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        marginTop: "50px",
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56"
        },
        [theme.breakpoints.down('sm')]: {
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
            },
        }
    },
    autocompleteSty: {
        width: "400px",
        margin: "10px 0px 15px",
        [theme.breakpoints.down('xs')]: {
            width: "80%",
        }
    },
    tabsPanel: {
        marginTop: "30px",
        borderBottom: "1px solid #d0cfcf",
        width: "100%"
    },
    bodySectionCont: {
        width: "100%",
        height: "300px",
        overflowX: "hidden",
        position: "relative",
    },
    offlinePaymentCont: {
        width: "100%",
        position: "absolute",
        top: "0px",
        left: "0px"
    },
    textSty: {
        marginTop: "20px",
        "& p": {
            fontSize: "13px",
            color: "gray"
        }
    },
    paidBtnCont: {
        marginTop: "20px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    typeTitleSty: {
        fontSize: "16px",
        fontWeight: "510",
        margin: "20px 0px 0px"
    },
    noBorder: {
        border: "none",
    },
    input: {
        backgroundColor: "#ebebeb",
        padding: "10px"
    },
    memoStyle: {
        width: "100%"
    },
    displayFlex: {
        display: "flex",
        alignItems: "center",
    }
}));

const CustomTab = withStyles({
    root: {
        textTransform: "none",
        fontSize: "1rem",
        minWidth: 50,
    },
})(Tab);

export default function TransactionSubmitComp({
    tx, setTx, updateApi, walletId, setLoadingBool, bankChartAccounts,
    setBankChartAccounts, submitionFailMsg, setSubmitionFailMsg, openErr,
    setOpenErr, needToMakeDisable
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

    const [tabValue, setTabValue] = useState(0);
    const [view, setView] = useState("onlinePayment");
    const [selectedBankChartAccount, setSelectedBankChartAccount] = useState(tx?.BankChartAccount || null);
    const [openChartAccCreate, setOpenChartAccCreate] = useState(false)
    const [paymentTypeValue, setPaymentTypeValue] = useState(tx?.paymentBy || null)

    // to update transaction
    const update = async (obj, isBankChartUpdate = false) => {
        if (!needToMakeDisable) {
            setLoadingBool(true)
            if (isBankChartUpdate) {
                await updateApi({
                    _id: obj?._id,
                    BankChartAccount: obj?.BankChartAccount?._id
                })
                setTx({
                    ...tx,
                    BankChartAccount: obj?.BankChartAccount
                })
            } else {
                await updateApi(obj)
                setTx({
                    ...tx,
                    ...obj
                })
            }
            setLoadingBool(false)
        }
    }

    //to select payment type in offline payment (Cash or Cheque)
    const paymentTypeSelect = async (value) => {
        if (!needToMakeDisable) {
            setPaymentTypeValue(value)
            await update({
                _id: tx?._id,
                paymentBy: value
            }, false)
        }
    }

    // for just select bank chart account
    const onSelectChartAccount = async (value) => {
        if (!needToMakeDisable) {
            setSelectedBankChartAccount(value)
            await update({
                _id: tx?._id,
                BankChartAccount: value
            },
                true
            )
        }
    }

    // for update chart accounts array as well as to select the newly created one
    const chartAccountCreateDialog = async (value) => {
        if (!needToMakeDisable) {
            setOpenChartAccCreate(value)
            const type = { classification: 'Bank', wallet: walletId }
            await getIncomeChartAccounts({ type })
                .then(async (accounts) => {
                    const addObject = {
                        _id: 'New',
                        name: "+ Add New",
                        numDays: "0"
                    };
                    const newFiltered = [addObject, ...accounts]
                    if (newFiltered.length > bankChartAccounts.length) {
                        const len = accounts?.length
                        setBankChartAccounts(newFiltered)
                        setSelectedBankChartAccount(accounts[len - 1])
                        await update({
                            _id: tx?._id,
                            BankChartAccount: accounts[len - 1]
                        },
                            true
                        )
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    return (
        <>
            <div className={classes.invoiceEditBody} >
                <div className={classes.sectionTop} style={{ marginTop: "10px" }} >
                    <h3>Bank Chart Account</h3>
                </div>
                <Autocomplete
                    value={selectedBankChartAccount}
                    options={bankChartAccounts}
                    getOptionLabel={(option) => { return option?.name || "" }}
                    getOptionSelected={(option) => {
                        return option?._id === selectedBankChartAccount?._id
                    }}
                    disabled={needToMakeDisable}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            label="Bank Chart Account"
                            placeholder={"Select bank chart account"}
                        />
                    )}
                    onChange={(event, value) => {
                        if (value?._id === "New") {
                            setOpenChartAccCreate(true);
                        } else {
                            onSelectChartAccount(value)
                        }
                    }}
                    className={classes.autocompleteSty}
                    size="small"
                />
                {!needToMakeDisable && openChartAccCreate && (
                    <ChartAccountCreateForBank
                        walletId={walletId}
                        openDialog={openChartAccCreate}
                        setOpenChart={chartAccountCreateDialog}
                    />
                )}
                <p style={{ fontSize: "12px", color: "gray" }} ><span style={{ color: "red" }} >*</span>Choose the chart bank account where the amount will added after the payment done</p>
                <h3 className={classes.typeTitleSty} >Select Payment Accept Type</h3>
                <Autocomplete
                    value={paymentTypeValue}
                    options={paymentTypes}
                    getOptionLabel={(option) => { return option || "" }}
                    disabled={needToMakeDisable}
                    getOptionSelected={(option) => {
                        return option === paymentTypeValue
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            placeholder={"Select payment accept type"}
                        />
                    )}
                    onChange={(event, value) => {
                        paymentTypeSelect(value)
                    }}
                    className={classes.autocompleteSty}
                    size="small"
                />
            </div>
            <NormalDialog
                openDialog={openErr}
                handleCloseShare={() => { setOpenErr(false) }}
                pageTitle={"Something is wrong"}
                content={<>
                    <p style={{ color: "red", textAlign: "center" }} >{submitionFailMsg}</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }} >
                        <Button
                            onClick={() => { setOpenErr(false) }}
                            color="primary"
                        >
                            ok
                        </Button>
                    </div>
                </>}
            />
        </>
    );
}