import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getRequestStageById } from "./api.call";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import UpdateIcon from "@mui/icons-material/Update";
import { AvatarGroup } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  actionFlowDetailContainer: {
    width: "100%",
    height: "5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F2F3",
    margin: "0.7rem 0",
  },
  actionFlowDetailSection: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
  },
  stageText: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  boxUp: {
    width: "100%",
    display: "flex",

    justifyContent: "space-between",
    alignItems: "center",
  },
  boxDown: {
    display: "flex",
    justifyContent: "space-between",
  },
  normalBoldText: {
    fontWeight: "bold",
  },
  actionDetails: {
    "& h3": {
      fontSize: "14px",
      fontWeight: "600",
    },
    "& p": {
      fontSize: "10px",
    },
  },
  section: {
    display: "flex",
    gap: "0.8rem",
    alignItems: "center",
  },
}));

export default function ApprovalStageDetails({ stageId }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);

  const [stageData, setStageData] = useState(null);
  const [totalMandatoryNeeded, setTotalMandatoryNeeded] = useState(0);
  const [approved, setApproved] = useState([]);
  const [dontTakeAnyAction, setDontTakeAnyAction] = useState([]);
  const [rejected, setRejected] = useState([]);

  useEffect(() => {
    getRequestStageById({ stageId })
      .then((data) => {
        console.log(data);
        setStageData(data);
        let approvers = data?.approvers || [];
        let neededMandatory = 0;
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];

        approvers.map((s) => {
          if (s?.mandatory) {
            neededMandatory = neededMandatory + 1;
          }
          if (!s?.isApproved && !s?.isRejected) {
            arr1.push(s);
          } else if (s?.isApproved) {
            arr2.push(s);
          } else if (s?.isRejected) {
            arr3.push(s);
          }
        });
        setApproved(arr2);
        setDontTakeAnyAction(arr1);
        setRejected(arr3);
        setTotalMandatoryNeeded(neededMandatory);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setStageData({});
    };
  }, [stageId]);

  const getType = (type) => {
    switch (type) {
      case "specific_same_power":
        return "Specific(Same Power)";
      case "specific_different_power":
        return "Specific(Different Power)";
      case "anybody":
        return "Anybody";
    }
  };

  console.log(stageData);

  return (
    <>
      {stageData?._id && (
        <div className={classes.actionFlowDetailContainer}>
          <div className={classes.actionFlowDetailSection}>
            <div className={classes.boxUp}>
              <div style={{ width: "10rem" }}>
                {stageData?.approvalType === "specific_different_power" ? (
                  <p>
                    Needed Approvals(Mandatory/Optional) :{" "}
                    {totalMandatoryNeeded}/
                    {stageData?.approvalsNeededCustom || 1}
                  </p>
                ) : (
                  <>
                    {stageData?.approvalsNeededCustom - approved.length > 0 && (
                      <h5 style={{ fontWeight: "bold" }}>
                        Needed Approvals :{" "}
                        {stageData?.approvalsNeededCustom - approved.length}
                      </h5>
                    )}
                  </>
                )}
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
              >
                <span>
                  {" "}
                  {stageData?.approvalsNeededCustom - approved.length > 0 ? (
                    <span>
                      <UpdateIcon
                        style={{ fontSize: "1.8rem", color: "gray" }}
                      />
                    </span>
                  ) : (
                    <span>
                      <TaskAltIcon
                        style={{ fontSize: "1.8rem", color: "green" }}
                      />
                    </span>
                  )}
                </span>
                <span>
                  {" "}
                  <h3 className={classes.stageText}>
                    Stage - {stageData?.status}
                  </h3>
                </span>
              </div>
              <div style={{ width: "10rem" }}></div>
            </div>
            <div className={classes.boxDown}>
              {dontTakeAnyAction?.length > 0 && (
                <div className={classes.section}>
                  <h5 className={classes.normalBoldText}>Approvers: </h5>
                  {dontTakeAnyAction?.length > 0 && (
                    <div>
                      <AvatarGroup max={5}>
                        {dontTakeAnyAction?.map((s) => (
                          <Tooltip
                            title={
                              <div>
                                <p>{s?.profile?.parent?.displayName}</p>
                              </div>
                            }
                            placement="bottom-end"
                          >
                            {s?.profile?.parent?.displayPicture?.thumbUrl ||
                            s?.profile?.parent?.displayPicture?.url ? (
                              <Avatar
                                style={{ width: "20px", height: "20px" }}
                                src={
                                  s?.profile?.parent?.displayPicture?.thumbUrl
                                    ? s?.profile?.parent?.displayPicture
                                        ?.thumbUrl
                                    : s?.profile?.parent?.displayPicture?.url
                                }
                              />
                            ) : (
                              <Avatar style={{ width: "20px", height: "20px" }}>
                                {s?.profile?.parent?.displayName}
                              </Avatar>
                            )}
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </div>
                  )}
                </div>
              )}
              {rejected?.length > 0 && (
                <div className={classes.section}>
                  <h5 className={classes.normalBoldText}>Rejected: </h5>
                  <div>
                    <AvatarGroup max={5}>
                      {rejected?.map((s) => (
                        <Tooltip
                          title={
                            <div className={classes.actionDetails}>
                              <h3 style={{ color: "#ef9aa4" }}>Rejected</h3>
                              <p>By {s?.profile?.parent?.displayName}</p>
                              <p>
                                At{" "}
                                {moment(s?.actionDate).format("Do MMM, HH:mm")}
                              </p>
                            </div>
                          }
                          placement="bottom-end"
                        >
                          {s?.profile?.parent?.displayPicture?.thumbUrl ||
                          s?.profile?.parent?.displayPicture?.url ? (
                            <Avatar
                              style={{ width: "20px", height: "20px" }}
                              src={
                                s?.profile?.parent?.displayPicture?.thumbUrl
                                  ? s?.profile?.parent?.displayPicture?.thumbUrl
                                  : s?.profile?.parent?.displayPicture?.url
                              }
                            />
                          ) : (
                            <Avatar style={{ width: "20px", height: "20px" }}>
                              {s?.profile?.parent?.displayName}
                            </Avatar>
                          )}
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  </div>
                </div>
              )}
              {approved?.length > 0 && (
                <div className={classes.section}>
                  <h5 className={classes.normalBoldText}>Approved: </h5>
                  <div>
                    <AvatarGroup max={5}>
                      {approved?.map((s) => (
                        <Tooltip
                          title={
                            <div className={classes.actionDetails}>
                              <h3
                                style={{ color: "#9aef9a", fontWeight: "bold" }}
                              >
                                Approved
                              </h3>
                              <p>By {s?.profile?.parent?.displayName}</p>
                              <p>
                                At{" "}
                                {moment(s?.actionDate).format("Do MMM, HH:mm")}
                              </p>
                            </div>
                          }
                          placement="bottom-end"
                        >
                          {s?.profile?.parent?.displayPicture?.thumbUrl ||
                          s?.profile?.parent?.displayPicture?.url ? (
                            <Avatar
                              style={{ width: "20px", height: "20px" }}
                              src={
                                s?.profile?.parent?.displayPicture?.thumbUrl
                                  ? s?.profile?.parent?.displayPicture?.thumbUrl
                                  : s?.profile?.parent?.displayPicture?.url
                              }
                            />
                          ) : (
                            <Avatar style={{ width: "20px", height: "20px" }}>
                              {s?.profile?.parent?.displayName}
                            </Avatar>
                          )}
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
