import { approvalTypesDefaultFieldsMap, approvalTypesModuleMap, getLinerStatuses } from "../../helpers/approval"
import { createDefaultTemplate } from "./api.call"

export const createDefaultTemplateHook = async ({
    profileId, entity, entityModelName, entityParent, userId,
    entityParentModelName, ownerProfileId, types, libraryId
}) => {
    let defaultTemplateArr = []
    let shareArr = []

    types.map((type) => {
        const statusArr = getLinerStatuses(type)
        let stagesArr = [];
        let modules = [approvalTypesModuleMap[type]];
        statusArr && statusArr.map((status) => {
            stagesArr.push({
                status: status,
                participantsType: "anybody"
            })
        })
        let newObj = {
            type: type,
            modules: modules,
            stages: stagesArr,
            defaultField: approvalTypesDefaultFieldsMap[type]
        }
        defaultTemplateArr.push(newObj)
    })
    if (profileId) {
        shareArr.push(profileId)
    }
    if (ownerProfileId) {
        shareArr.push(ownerProfileId)
    }
    try {
        const createRes = await createDefaultTemplate({
            defaultTemplateArr,
            libraryId: libraryId,
            shared: shareArr,
            userId,
            entity,
            entityModelName,
            entityParent,
            entityParentModelName
        })
        return createRes
    } catch (err) {
        console.log(err)
        return err;
    }
}