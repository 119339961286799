import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Api from "../../../helpers/Api";
import {
  createTx,
  getPopulatedCertificateById,
  getPopulatedWalletByIdForCertificate,
  getProfileByWallet,
} from "../commonComponent/transaction/api";
import BillListAddEdit from "../commonComponent/BillAddEdit/Bill.List.AddEdit";
import {
  createNewAdjustments,
  deleteAdjustmentById,
  getCertificateCreateBasicData,
  submitCertificate,
  updateAdjustmentById,
  updateAdjustments,
  updateCertificateApiCall,
} from "./api.call";
import LockIcon from '@material-ui/icons/Lock';
import { MdOutlineForkRight } from "react-icons/md";
import { getProcurementByType } from "../commonComponent/transaction/api";
import TextFieldNumberFormated from "../../styled/CommonComponents/TextFieldNumberFormated";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import emptyBill from "../../../Assets/emptyBillItem.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDebounce } from "react-use";
import DeleteIcon from "@material-ui/icons/Delete";
import NotifyAlert from "../../styled/CommonComponents/NotifyAlert";
import BudgetUpdate from "../../budgetCostAnalysis/BudgetUpdate";
import TaxDiscountCreateDrawer from "../expenseAndBills/bills/BillList/TaxDiscountCreateDrawer";
import AttachmentsHandler from "../../styled/CommonComponents/Attachments.Handler";
import ExportPDF from "../../styled/generic/ExportPDF";
import ApprovalAndActions from "../../approval/ApprovalAndActions";
import ApprovalActionDrawer from "../../approval/ApprovalActionDrawer";
import { allStatusOptions } from "../../../helpers/allStatuses";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import { ArrowDropDown } from "@material-ui/icons";
import BlocksAndTasksHandler from "../salesAndInvoices/BlocksAndTasksHandler";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  invoiceEditBody: {
    width: "100%",
    height: "calc(100% - 55px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 25px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  nextIcon: {
    transform: "rotate(180deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    marginTop: "50px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  noBorder: {
    border: "none",
  },
  input: {
    backgroundColor: "#ebebeb",
    padding: "10px",
  },
  memoStyle: {
    width: "100%",
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "250px",
      width: "auto",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
    },
  },
  inputLable: {
    width: "100%",
    padding: "10px",
    boxSizing: "border-box",
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      marginBottom: "1px",
    },
  },
  validationSty: {
    fontSize: "12px",
    color: "red",
    fontWeight: "400",
    marginTop: "5px",
  },
  displayFlex: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  percentageSty: {
    width: "calc(100% - 330px)",
  },
  amountDeleteSty: {
    width: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  amountSty: {
    width: "250px",
  },
  subtotalAmountView: {
    padding: "10px",
    paddingTop: "0px",
    "& div": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "5px",
      marginBottom: "15px",
      borderTop: "1px solid #ececec",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "500",
      color: "gray",
    },
    "& h3": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
  totalAmountView: {
    padding: "10px",
    paddingTop: "0px",
    "& div": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "5px",
      borderTop: "1px solid #ececec",
      marginTop: "10px",
      marginTop: "10px",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      color: "gray",
    },
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  sectionTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "10px",
    marginTop: "30px",
    padding: "5px",
    "& h3": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  emptyWorkorder: {
    width: "100%",
    height: "calc(100% - 110px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "180px",
      width: "auto",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
    },
  },
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
}));

export default function CertificateEdit({ }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { walletId, certificateId} = useParams();

  const { user } = useSelector((state) => state.auth);
  const { createdFileIds } = useSelector((state) => state.file);

  const [entityData, setEntityData] = useState(null);
  const [certificateData, setCertificateData] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [certificateObjData, setCertificateObjData] = useState({
    debitNotes: [],
    creditNotes: [],
    taxs: [],
    workOrder: null,
    retentionDeductionPercentage: 0,
    retentionDeductionAmount: 0,
    advanceDeductionPercentage: 0,
    advanceDeductionAmount: 0,
    completionAmount: 0,
    creditNoteAmount: 0,
    netAmount: 0,
  });
  const [stateChange, setStateChange] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [debitNotes, setDebitNotes] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);
  const [validationObj, setValidationObj] = useState([]);
  const [allWorkOrders, setAllWorkOrders] = useState([]);
  const [itemsTotal, setItemsTotal] = useState({});
  const [openTaxDiscountCreateDrawer, setOpenTaxDiscountCreateDrawer] = useState(false);
  const [submitCheckValidation, setSubmitCheckValidation] = useState();
  const [selectedBudgetId, setSelectedBudgetId] = useState([]);
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [needToMakeDisable, setNeedToMakeDisable] = useState(false);
  const [actionStatus, setActionStatus] = useState(null)
  const [activeStage, setActiveStage] = useState(null)
  const [blocks, setBlocks] = useState([]);
  const [units, setUnits] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskattached , setTaskAttached] = useState([]);
  const [tasktransaction , setTaskTransaction] = useState([]);

  const setValuesFromCertificate = async (certificateDetailsData) => {
    let adjustmentsArr = certificateDetailsData?.adjustments || [];
    let locTaxs = [];
    let locDebits = [];
    let locCredits = [];
    console.log("Certificate details data:" , certificateDetailsData);
    adjustmentsArr.map((aj) => {
      if (aj?.type === "tax") {
        locTaxs.push({
          _id: aj?._id,
          billItem: aj?.billItem,
          tax: aj?.taxId,
          type: aj?.type,
          amount: parseFloat(aj?.amount || 0),
        });
      } else if (aj?.type === "credit") {
        locCredits.push({
          _id: aj?._id,
          billItem: aj?.billItem,
          creditNote: aj?.creditNote,
          type: aj?.type,
          amount: parseFloat(aj?.amount || 0),
        });
      } else if (aj?.type === "debit") {
        locDebits.push({
          _id: aj?._id,
          billItem: aj?.billItem,
          debitNote: aj?.debitNote,
          type: aj?.type,
          amount: parseFloat(aj?.amount || 0),
        });
      }
    });

    const locItems = certificateDetailsData?.billList?.items || [];
    let locCompletionAmount = 0;
    locItems.map((item) => {
      locCompletionAmount =
        locCompletionAmount +
        parseFloat(item?.rate || 0) * parseFloat(item?.qTy || 0);
    });

    let projectTotalAmount = parseFloat(
      certificateDetailsData?.workOrder?.totalAmountVal || 0
    );
    let advanceAmount = parseFloat(
      certificateDetailsData?.workOrder?.advanceAmount || 0
    );
    let completionAmount = parseFloat(locCompletionAmount || 0);
    let retentionPercentage = parseFloat(
      certificateDetailsData?.workOrder?.retentionPercentage || 0
    );
    let retentionAmount = (
      (completionAmount / 100) *
      retentionPercentage
    ).toFixed(2);
    let advanceDeductionAmount = (
      (completionAmount / projectTotalAmount) *
      advanceAmount
    ).toFixed(2);
    let advanceDeductionPercentage = (
      (advanceDeductionAmount / completionAmount) *
      100
    ).toFixed(2);

    let locTaxAmount = 0;
    locTaxs.map((tax) => {
      locTaxAmount = locTaxAmount + parseFloat(tax?.amount || 0);
    });
    let locDebitNotesAmount = 0;
    locDebits.map((debitNote) => {
      locDebitNotesAmount =
        locDebitNotesAmount + parseFloat(debitNote?.amount || 0);
    });
    let locCreditNotesAmount = 0;
    locCredits.map((creditNote) => {
      locCreditNotesAmount =
        locCreditNotesAmount + parseFloat(creditNote?.amount || 0);
    });
    setSelectedBudgetId(certificateDetailsData?.selectedBudgetIds || []);
    setCertificateObjData({
      workOrder: certificateDetailsData?.workOrder,
      completionAmount: completionAmount,
      taxs: locTaxs,
      debitNotes: locDebits,
      creditNotes: locCredits,
      retentionDeductionAmount: retentionAmount,
      retentionDeductionPercentage: retentionPercentage,
      advanceDeductionAmount: advanceDeductionAmount,
      advanceDeductionPercentage: advanceDeductionPercentage,
      totalTaxAmount: locTaxAmount,
      debitNoteAmount: locDebitNotesAmount,
      creditNoteAmount: locCreditNotesAmount,
      netAmount: (
        completionAmount -
        retentionAmount -
        advanceDeductionAmount +
        locTaxAmount +
        locDebitNotesAmount -
        locCreditNotesAmount
      ).toFixed(2),
    });
    setCertificateData(certificateDetailsData);
  };
 
  const getPopulatedCertificateData = async () => {
    setLoader(true);
    await getPopulatedCertificateById({ certificateId })
      .then((data) => {
        console.log("The populated data are:",data);
        if (data?._id) {
          setValuesFromCertificate(data);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }

  useEffect(() => {
    if (certificateId) {
      getPopulatedCertificateData()
    }
  }, [certificateId]);

  useEffect(() => {
    let locActiveStage = certificateData?.approvalRequest?.activeStage || null
    setActiveStage(locActiveStage)
    let canEdit = false
    if (locActiveStage?.approvalType === "anybody") {
      canEdit = true
    } else {
      let approvers = locActiveStage?.approvers || [];
      for (let i = 0; i < approvers.length; i++) {
        if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
          canEdit = true;
          break;
        }
      }
    }
    setNeedToMakeDisable(!canEdit)
  }, [certificateData?.approvalRequest?.activeStage])


console.log("CertificateId is:" , certificateId);
console.log("OpsorderId is:", certificateData?.workOrder?._id);
  useEffect(() => {
    const initializeCertificateData = async () => {
      try {
        const response = await Api.post('/transaction/initialize/block-unit-task', {
          certificateId: certificateId,
          opsOrderId: certificateData?.workOrder?._id 
        });
        console.log("The response is:", response.data);
        if (response.status === 200) {
          setCertificateData(response.data.data);
          setBlocks(response.data.projectBlocks);
          setUnits(response.data.units);
          setTasks(response.data.tasks);
          setTaskAttached(response.data.taskAttachedFiles);
          setTaskTransaction(response.data.taskTransactions);
        }
      } catch (error) {
        console.error('Error initializing certificate data:', error);
      }
    };
    initializeCertificateData();
  }, [certificateId]);


  useEffect(() => {
    if (walletId) {
      setLoader(true);
      getPopulatedWalletByIdForCertificate({ walletId })
        .then((data) => {
          setWalletData(data);
          setLoader(false);
          if (data?.parentModelName == "User") {
            setEntityData(data?.parent);
            console.log("Entity Data for user:" , data?.parent);
          } else {
            setEntityData(data?.parent?.parent);
            console.log("Entity Data for other:", data?.parent?.parent);
          }

        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [walletId]);

  useEffect(() => {
    if (walletId) {
      getCertificateCreateBasicData({ walletId })
        .then((data) => {
          console.log(data)
          let taxesArr = data?.taxs || [];
          let debitNoteArr = data?.debitNote || [];
          let creditNoteArr = data?.creditNote || [];
          const addewObj = {
            _id: "addNew",
            name: "+ Add New",
          };
          setTaxes([addewObj, ...taxesArr]);
          setDebitNotes([addewObj, ...debitNoteArr]);
          setCreditNotes([addewObj, ...creditNoteArr]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [walletId]);
  console.log("EntityID:" , entityData?._id);

  useEffect(() => {
    if (entityData?._id) {
      getProcurementByType({
        identifier: entityData?._id,
        procurementType: ["Work Order", "Purchase Order"],
      })
        .then((data) => {
          console.log("Allworkorder data is:", data);
          setAllWorkOrders(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [entityData?._id]);

  useDebounce(async () => {
    if (certificateObjData?.workOrder?._id && certificateObjData?.completionAmount != null && certificateObjData?.completionAmount != undefined) {
      let bulkUpdateObjArr = [];
      let locUpdatedTaxs = [];
      let projectTotalAmount = parseFloat(
        certificateObjData?.workOrder?.totalAmountVal || 0
      );
      let advanceAmount = parseFloat(
        certificateObjData?.workOrder?.advanceAmount || 0
      );
      let completionAmount = parseFloat(
        certificateObjData?.completionAmount || 0
      );
      let retentionPercentage = parseFloat(
        certificateObjData?.workOrder?.retentionPercentage || 0
      );
      let retentionAmount = (
        (completionAmount / 100) *
        retentionPercentage
      ).toFixed(2);
      let advanceDeductionAmount = (
        (completionAmount / projectTotalAmount) *
        advanceAmount
      ).toFixed(2);
      let advanceDeductionPercentage = (
        (advanceDeductionAmount / completionAmount) *
        100
      ).toFixed(2);
      bulkUpdateObjArr.push({
        updateOne: {
          filter: {
            type: "advanceDeduction",
            certificate: certificateData?._id,
          },
          update: {
            amount: advanceDeductionAmount,
            advanceRecoveryPercentage: advanceDeductionPercentage,
          },
        },
      });
      bulkUpdateObjArr.push({
        updateOne: {
          filter: {
            type: "retentionDeduction",
            certificate: certificateData?._id,
          },
          update: {
            amount: retentionAmount,
            retentionPercentage: retentionPercentage,
          },
        },
      });

      let locTaxAmount = 0;
      certificateObjData?.taxs &&
        certificateObjData.taxs.map((tax) => {
          let amount = 0;
          if (completionAmount != null && completionAmount != undefined) {
            if (tax?.tax?.discountOrTax?.type === "%") {
              amount = (
                (parseFloat(completionAmount || 0) / 100) *
                parseFloat(tax?.tax?.discountOrTax?.percent || 0)
              ).toFixed(2);
            } else {
              amount = parseFloat(tax?.tax?.discountOrTax?.amount || 0);
            }
          }
          locUpdatedTaxs.push({
            _id: tax?._id,
            tax: tax?.tax,
            type: "tax",
            billItem: tax?.billItem,
            amount: amount,
          });
          bulkUpdateObjArr.push({
            updateOne: {
              filter: {
                _id: tax?._id,
              },
              update: {
                amount: amount,
              },
            },
          });
          locTaxAmount = locTaxAmount + parseFloat(amount || 0);
        });

      let locDebitNotesAmount = 0;
      certificateObjData?.debitNotes &&
        certificateObjData.debitNotes.map((debitNote) => {
          locDebitNotesAmount =
            locDebitNotesAmount + parseFloat(debitNote?.amount || 0);
        });

      let locCreditNotesAmount = 0;
      certificateObjData?.creditNotes &&
        certificateObjData.creditNotes.map((creditNote) => {
          locCreditNotesAmount =
            locCreditNotesAmount + parseFloat(creditNote?.amount || 0);
        });

      setCertificateObjData({
        ...certificateObjData,
        taxs: locUpdatedTaxs,
        retentionDeductionAmount: retentionAmount,
        retentionDeductionPercentage: retentionPercentage,
        advanceDeductionAmount: advanceDeductionAmount,
        advanceDeductionPercentage: advanceDeductionPercentage,
        totalTaxAmount: locTaxAmount,
        debitNoteAmount: locDebitNotesAmount,
        creditNoteAmount: locCreditNotesAmount,
        netAmount: (
          completionAmount -
          retentionAmount -
          advanceDeductionAmount +
          locTaxAmount +
          locDebitNotesAmount -
          locCreditNotesAmount
        ).toFixed(2),
      });
      const additionalUpdateObj = {
        _id: certificateData?._id,
        netPayment: (
          completionAmount -
          retentionAmount -
          advanceDeductionAmount +
          locTaxAmount +
          locDebitNotesAmount -
          locCreditNotesAmount
        ).toFixed(2),
      };
      if (!needToMakeDisable) {
        await updateAdjustments({ bulkUpdateObjArr, additionalUpdateObj })
          .then((data) => {
            console.log(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, 2000, [stateChange]);

  useEffect(() => {
    if (!needToMakeDisable) {
      setStateChange(!stateChange);
      setCertificateObjData({
        ...certificateObjData,
        completionAmount: parseFloat(itemsTotal?.simpleTotal || 0),
      });
      setStateChange(!stateChange);
    }
  }, [itemsTotal]);

  const onChangeWorkorder = async (workorder) => {
    if (!needToMakeDisable) {
      setLoader(true);
      setCertificateObjData({
        ...certificateObjData,
        workOrder: workorder,
      });
      let projectTotalAmount = parseFloat(workorder?.totalAmountVal || 0);
      let advanceAmount = parseFloat(workorder?.advanceAmount || 0);
      let completionAmount = parseFloat(itemsTotal?.simpleTotal || 0);
      let retentionPercentage = parseFloat(workorder?.retentionPercentage || 0);
      let retentionAmount = (
        (completionAmount / 100) *
        retentionPercentage
      ).toFixed(2);
      let advanceDeductionAmount = (
        (completionAmount / projectTotalAmount) *
        advanceAmount
      ).toFixed(2);
      let advanceDeductionPercentage = (
        (advanceDeductionAmount / completionAmount) *
        100
      ).toFixed(2);

      if (certificateData?.workOrder?._id) {
        let bulkUpdateObjArr = [];
        bulkUpdateObjArr.push({
          updateOne: {
            filter: {
              type: "advanceDeduction",
              certificate: certificateData?._id,
            },
            update: {
              amount: advanceDeductionAmount,
              order: workorder?._id,
              advanceRecoveryPercentage: advanceDeductionPercentage,
              project: workorder?.project?._id,
              projectParent: workorder?.projectParent?._id,
              projectParentModelName: workorder?.projectParentModel,
              projectBlock: workorder?.projectBlock?._id
                ? workorder?.projectBlock?._id
                : workorder?.projectBlock,
              curProfile: workorder?.profile,
            },
          },
        });
        bulkUpdateObjArr.push({
          updateOne: {
            filter: {
              type: "retentionDeduction",
              certificate: certificateData?._id,
            },
            update: {
              amount: retentionAmount,
              order: workorder?._id,
              retentionPercentage: retentionPercentage,
              project: workorder?.project?._id,
              projectParent: workorder?.projectParent?._id,
              projectParentModelName: workorder?.projectParentModel,
              projectBlock: workorder?.projectBlock?._id
                ? workorder?.projectBlock?._id
                : workorder?.projectBlock,
              curProfile: workorder?.profile,
            },
          },
        });

        let localTaxTotalNet = 0;
        let localDebitTotalNet = 0;
        let localCreditTotalNet = 0;
        certificateData?.adjustments &&
          certificateData.adjustments.map((aj) => {
            if (aj?.type === "tax") {
              let amount = 0;
              if (completionAmount != null && completionAmount != undefined) {
                if (aj?.taxId?.discountOrTax?.type === "%") {
                  amount = (
                    (parseFloat(completionAmount || 0) / 100) *
                    parseFloat(aj?.taxId?.discountOrTax?.percent || 0)
                  ).toFixed(2);
                } else {
                  amount = parseFloat(aj?.taxId?.discountOrTax?.amount);
                }
              }
              bulkUpdateObjArr.push({
                updateOne: {
                  filter: {
                    _id: aj?._id,
                  },
                  update: {
                    amount: amount,
                    order: workorder?._id,
                    project: workorder?.project?._id,
                    projectParent: workorder?.projectParent?._id,
                    projectParentModelName: workorder?.projectParentModel,
                    projectBlock: workorder?.projectBlock?._id
                      ? workorder?.projectBlock?._id
                      : workorder?.projectBlock,
                    curProfile: workorder?.profile,
                  },
                },
              });
              localTaxTotalNet = localTaxTotalNet + parseFloat(amount || 0);
            } else if (aj?.type === "debit" || aj?.type === "credit") {
              bulkUpdateObjArr.push({
                updateOne: {
                  filter: {
                    _id: aj?._id,
                  },
                  update: {
                    order: workorder?._id,
                    project: workorder?.project?._id,
                    projectParent: workorder?.projectParent?._id,
                    projectParentModelName: workorder?.projectParentModel,
                    projectBlock: workorder?.projectBlock?._id
                      ? workorder?.projectBlock?._id
                      : workorder?.projectBlock,
                    curProfile: workorder?.profile,
                  },
                },
              });
              if (aj?.type === "debit") {
                localDebitTotalNet =
                  localDebitTotalNet + parseFloat(aj?.amount || 0);
              } else {
                localCreditTotalNet =
                  localCreditTotalNet + parseFloat(aj?.amount || 0);
              }
            }
          });
        const additionalUpdateObj = {
          _id: certificateData?._id,
          workOrder: workorder?._id,
          selectedBudgetIds: workorder?.selectedBudgetIds,
          netPayment: (
            completionAmount -
            retentionAmount -
            advanceDeductionAmount +
            localTaxTotalNet +
            localDebitTotalNet -
            localCreditTotalNet
          ).toFixed(2),
        };

        await updateAdjustments({ bulkUpdateObjArr, additionalUpdateObj })
          .then((data) => {
            if (data?._id) {
              setValuesFromCertificate(data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const advanceDeductionAJObj = {
          type: "advanceDeduction",
          amount: advanceDeductionAmount,
          order: workorder?._id,
          advanceRecoveryPercentage: advanceDeductionPercentage,
          certificate: certificateData?._id,
          project: workorder?.project?._id,
          projectParent: workorder?.projectParent?._id,
          projectParentModelName: workorder?.projectParentModel,
          projectBlock: workorder?.projectBlock?._id
            ? workorder?.projectBlock?._id
            : workorder?.projectBlock,
          curProfile: workorder?.profile,
          user: user?._id,
        };
        const retentionDeductionAJObj = {
          type: "retentionDeduction",
          amount: retentionAmount,
          order: workorder?._id,
          retentionPercentage: retentionPercentage,
          certificate: certificateData?._id,
          project: workorder?.project?._id,
          projectParent: workorder?.projectParent?._id,
          projectParentModelName: workorder?.projectParentModel,
          projectBlock: workorder?.projectBlock?._id
            ? workorder?.projectBlock?._id
            : workorder?.projectBlock,
          curProfile: workorder?.profile,
          user: user?._id,
        };
        let adjustmentsArr = [advanceDeductionAJObj, retentionDeductionAJObj];
        const additionalUpdateObj = {
          workOrder: workorder?._id,
          selectedBudgetIds: workorder?.selectedBudgetIds,
        };
        await createNewAdjustments({
          adjustmentsArr,
          additionalUpdateObj,
          certificateId: certificateData?._id,
        })
          .then((data) => {
            if (data?._id) {
              setValuesFromCertificate(data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setLoader(false);
    }
  };

  const addItem = async (type) => {
    setLoader(true);
    switch (type) {
      case "debit":
        let debitNotes = certificateObjData?.debitNotes || [];
        const newDebitObj = {
          debitNote: null,
          amount: 0,
        };
        setCertificateObjData({
          ...certificateObjData,
          debitNotes: [newDebitObj, ...debitNotes],
        });
        break;
      case "credit":
        let creditNotes = certificateObjData?.creditNotes || [];
        const newCreditObj = {
          creditNote: null,
          amount: 0,
        };
        setCertificateObjData({
          ...certificateObjData,
          creditNotes: [newCreditObj, ...creditNotes],
        });
        break;
      case "tax":
        let locTaxs = certificateObjData?.taxs || [];
        const newTaxObj = {
          tax: null,
          amount: 0,
        };
        setCertificateObjData({
          ...certificateObjData,
          taxs: [newTaxObj, ...locTaxs],
        });
        break;
    }

    const dataObj = {
      type,
      order: certificateData?.workOrder?._id,
      certificate: certificateData?._id,
      project: certificateData?.workOrder?.project,
      projectParent: certificateData?.workOrder?.projectParent,
      projectParentModelName: certificateData?.workOrder?.projectParentModel,
      projectBlock: certificateData?.workOrder?.projectBlock,
      curProfile: certificateData?.workOrder?.profile,
      user: user?._id,
    };
    const adjustmentsArr = [dataObj];
    const additionalUpdateObj = {};
    await createNewAdjustments({
      adjustmentsArr,
      additionalUpdateObj,
      certificateId: certificateData?._id,
    })
      .then((data) => {
        if (data?._id) {
          setValuesFromCertificate(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const setTaxAndTx = async (updateObj) => {
    await updateAdjustmentById({ updateObj })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addCreditDebitNote = async (type) => {
    setLoader(true);
    dispatch({
      type: "AddWallet",
      payload: { financeLoading: true },
    });
    await getProfileByWallet({ walletId })
      .then(async (profileId) => {
        const data = await createTx({
          type,
          status: "Draft",
          firstPartyWallet: walletId,
          firstParty: profileId,
          creatorUser: user?._id,
          user: user?._id,
          creatorProfile: user?.profile,
          isAccountingNeed: false,
        });
        dispatch({
          type: "AddWallet",
          payload: { financeLoading: false },
        });

        if (data?._id) {
          let path = "";
          if (type === "DebitNote") {
            path = "/finance/" + walletId + "/debit-note/edit/" + data?._id;
          } else if (type === "CreditNote") {
            path = "/finance/" + walletId + "/credit-note/edit/" + data?._id;
          }
          history.push(path);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "AddWallet",
          payload: { financeLoading: false },
        });
        setLoader(false);
      });
  };

  const deleteAdjustment = async (ajId) => {
    if (!needToMakeDisable) {
      setLoader(true);
      await deleteAdjustmentById({
        certificateId: certificateData?._id,
        adjustmentId: ajId,
      })
        .then((data) => {
          if (data?._id) {
            setValuesFromCertificate(data);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const addNewSelectedItems = async (items) => {
    if (!needToMakeDisable) {
      const updateObj = {
        _id: certificateData?._id,
        selectedBudgetIds: items,
      };
      await updateCertificateApiCall({ updateObj })
        .then((data) => {
          setValuesFromCertificate(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateStatus = (nextStatus) => {
    if (!needToMakeDisable) {
      if (!certificateData?.workOrder?._id) {
        setSubmitCheckValidation({
          show: true,
          message: "Certificate is not ready to submit! Have to select workorder or purchaseorder first",
          status: "error",
        });
        return;
      }
      const debitNotesForCheck = certificateObjData?.debitNotes || [];
      const creditNotesForCheck = certificateObjData?.creditNotes || [];
      const taxsForCheck = certificateObjData?.taxs || [];
      let isAnythingWrong = false;

      console.log("debitNotesForCheck")
      console.log(certificateObjData)
      console.log(debitNotesForCheck)
      console.log("debitNotesForCheck")

      debitNotesForCheck.map((debitNote) => {
        if (!debitNote?._id || !debitNote?.debitNote?._id) {
          isAnythingWrong = true;
        }
      });
      if (isAnythingWrong) {
        setSubmitCheckValidation({
          show: true,
          message: "Something is missing under debit note part",
          status: "error",
        });
        return;
      }
      isAnythingWrong = false;
      creditNotesForCheck.map((creditNote) => {
        if (!creditNote?._id || !creditNote?.creditNote?._id) {
          isAnythingWrong = true;
        }
      });
      if (isAnythingWrong) {
        setSubmitCheckValidation({
          show: true,
          message: "Something is missing under credit note part",
          status: "error",
        });
        return;
      }
      isAnythingWrong = false;
      taxsForCheck.map((tax) => {
        if (!tax?._id || !tax?.tax?._id) {
          isAnythingWrong = true;
        }
      });
      if (isAnythingWrong) {
        setSubmitCheckValidation({
          show: true,
          message: "Something is missing under tax part",
          status: "error",
        });
        return;
      }
      let cfAllocations = certificateData?.workOrder?.allocations || [];
      let oldOneExisit = false;
      let curTotalAllocated = 0;
      cfAllocations.map((alCF) => {
        if (alCF?.identifier == certificateData?._id) {
          oldOneExisit = true;
          curTotalAllocated =
            curTotalAllocated + parseFloat(certificateObjData?.netAmount || 0);
        } else {
          curTotalAllocated = curTotalAllocated + parseFloat(alCF?.amount || 0);
        }
      });
      if (!oldOneExisit) {
        curTotalAllocated =
          curTotalAllocated + parseFloat(certificateObjData?.netAmount || 0);
      }
      let totalWorkPurchaseOrder = parseFloat(
        certificateData?.workOrder?.totalAmountVal || 0
      );
      if (curTotalAllocated > totalWorkPurchaseOrder) {
        setSubmitCheckValidation({
          show: true,
          message:
            "Sorry! you don't have enough approved amount to submit the current total",
          status: "error",
        });
        return;
      }
      setActionStatus(nextStatus)
      setOpenAction(true)
    }
  }

  const submitCertificateSubmit = async () => {
    if (!needToMakeDisable) {
      const debitNotesForCheck = certificateObjData?.debitNotes || [];
      const creditNotesForCheck = certificateObjData?.creditNotes || [];
      const taxsForCheck = certificateObjData?.taxs || [];
      let locItems = [
        ...debitNotesForCheck,
        ...creditNotesForCheck,
        ...taxsForCheck,
      ];
      let localAj = certificateData?.adjustments || [];
      localAj.map((akIt) => {
        if (akIt?.type == "advanceDeduction" || akIt?.type == "retentionDeduction") {
          locItems.push(akIt);
        }
      });
      const dataObj = {
        txId: certificateData?.transaction,
        workorder: certificateData?.workOrder?._id,
        complitionBillItemId: certificateData?.completionBillItem,
        certificateNo: certificateData?.certificateNumber,
        vendorProfileId: certificateData?.workOrder?.vendor?._id,
        vendorWalletId: certificateData?.workOrder?.vendor?.parent?.wallet,
        walletId: walletId,
        walletProfileId: entityData?.profile,
        certificateId: certificateData?._id,
        adjustmentItems: locItems,
        defaultChartAccountId: walletData?.defaultExpenseAcc,
        userId: user?._id,
        profileId: user?.profile,
        selectedBudgetIds: certificateData?.workOrder?.selectedBudgetIds || [],
        netAmount: parseFloat(certificateObjData?.netAmount || 0),
        completionAmount: parseFloat(certificateObjData?.completionAmount || 0),
      };
      setLoader(true);
      await submitCertificate(dataObj)
        .then((data) => {
          if (data?._id) {
            setValuesFromCertificate(data);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  console.log("CertificateData" , certificateData);
  console.log("Blocks are:" , blocks);

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <Typography className={classes.headerTitle}>
            <>Certificate</>
            <span style={{ marginLeft: "10px" }}>
              #{certificateData?.certificateNumber}
            </span>
          </Typography>
        </div>
        <div className={classes.barRight}>
          {!needToMakeDisable && (
            <ExportPDF
              apiUrl="/get/certificate/pdf"
              fileName="certificate"
              apiBody={{
                certificateId: certificateId,
              }}
              enableSignatures={true}
              enableTermsAndConditions={true}
              libraryId={walletData?.defaultLibrary?._id}
              profileId={walletData?.profile?._id}
            />
          )}
          {!needToMakeDisable && (
            <BudgetUpdate
              selectedBudgetId={selectedBudgetId}
              setSelectedBudgetId={setSelectedBudgetId}
              addNewSelectedItems={addNewSelectedItems}
              budgetUpdateStatus={"Certifite"}
              workspaceType={"Certificate"}
              workspaceId={certificateData?._id}
              projectProfileId={certificateData?.workOrder?.project?.profile}
              projectId={certificateData?.workOrder?.project?._id}
              projectParentId={certificateData?.workOrder?.projectParent}
              projectParentModel={certificateData?.workOrder?.projectParentModel}
              projectBlockId={certificateData?.workOrder?.projectBlock}
            />
          )}
          <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            style={{ marginLeft: "10px" }}
            color="primary"
            onClick={() => { setOpenApprovalFlow(true) }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loader}
            setLoadingBool={setLoader}
            parent={certificateId}
            parentModelName={"FinancialCertificate"}
            requestId={certificateData?.approvalRequest?._id}
            needToMakeDisable={needToMakeDisable}
            activeStage={activeStage}
          />
          <div style={{ width: "10px" }}></div>
          {needToMakeDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {certificateData?.status ? certificateData?.status : "STATUS"}
            </Button>
          ) : (<>
            <MyPopOver
              closeOnClick={true}
              appearContent={
                <Button
                  id="demo-customized-button"
                  variant="outlined"
                  size="small"
                  color="primary"
                  endIcon={<ArrowDropDown />}
                >
                  {certificateData?.status ? certificateData?.status : "STATUS"}
                </Button>
              }
              showContent={<div className={classes.optionsCont} >
                {allStatusOptions["Financial Certificate"][certificateData?.status] && allStatusOptions["Financial Certificate"][certificateData?.status].map((statusOption, i) => (
                  <div
                    key={i}
                    className={classes.typeSingleOptionSty}
                    onClick={() => { updateStatus(statusOption) }}
                  >
                    {statusOption}
                  </div>
                ))}
              </div>}
            />
            <ApprovalActionDrawer
              openAction={openAction}
              setOpenAction={setOpenAction}
              loadingBool={loader}
              setLoadingBool={setLoader}
              actionType={"takeAction"}
              parent={certificateId}
              parentModelName={"FinancialCertificate"}
              statusField={"status"}
              statusValue={actionStatus}
              requestId={certificateData?.approvalRequest?._id}
              activeStage={activeStage}
              actionCallBack={async (type, curStatues) => {
                if (type === "ApprovalForNextStage") {
                  await submitCertificateSubmit();
                } else {
                  await getPopulatedCertificateData();
                }
              }}
            />
          </>)}
        </div>
      </div>
      <div>{loader ? <LinearProgress /> : null}</div>
      <div className={classes.invoiceEditBody}>
        <div className={classes.sectionTop} style={{ marginTop: "10px" }}>
          <h3>Work Order/Purchase Order</h3>
          <div></div>
        </div>
        <Autocomplete
          value={certificateObjData?.workOrder}
          options={allWorkOrders}
          disableClearable
          disabled={needToMakeDisable ? true : certificateData?.transaction ? true : loader}
          getOptionLabel={(option) => {
            return option?.name;
          }}
          getOptionSelected={(option) => {
            return option?._id === certificateObjData?.workOrder?._id;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: "0px" }}
              margin="normal"
              variant="outlined"
              placeholder={"Select Work Order/Purchase Order"}
            />
          )}
          onChange={(event, value) => {
            setStateChange(!stateChange);
            onChangeWorkorder(value);
            setStateChange(!stateChange);
          }}
          fullWidth
          size="small"
        />
        <p className={classes.validationSty}>{validationObj?.workOrder}</p>
        {certificateData?.workOrder?._id ? (
          <>
            <BillListAddEdit
              certificateData={certificateData}
              setCertificateData={setCertificateData}
              setLoadingBool={setLoader}
              walletData={walletData}
              needToMakeDisable={needToMakeDisable}
              setItemsTotal={setItemsTotal}
            />
            <div className={classes.inputLable}>
              <div className={classes.sectionTop}>
                <h3>Retention Deduction(B)</h3>
                <div></div>
              </div>

              <div className={classes.displayFlex}>
                <TextField
                  value={certificateObjData?.retentionDeductionPercentage}
                  size="small"
                  onChange={(e) => {
                    setStateChange(!stateChange);
                    setCertificateObjData({
                      ...certificateObjData,
                      retentionDeductionPercentage: e.target.value,
                    });
                    setStateChange(!stateChange);
                  }}
                  disabled
                  variant="outlined"
                  placeholder="Enter percentage"
                  type="number"
                  className={classes.percentageSty}
                />
                <TextFieldNumberFormated
                  value={certificateObjData?.retentionDeductionAmount}
                  size="small"
                  onChange={(e) => {
                    setStateChange(!stateChange);
                    setCertificateObjData({
                      ...certificateObjData,
                      retentionDeductionAmount: e.target.value,
                    });
                    setStateChange(!stateChange);
                  }}
                  disabled
                  variant="outlined"
                  placeholder="Enter amount"
                  type="number"
                  className={classes.amountSty}
                />
              </div>
              <p className={classes.validationSty}>{ }</p>
            </div>
            <div className={classes.inputLable}>
              <div className={classes.sectionTop}>
                <h3>Advance Deduction(C)</h3>
                <div></div>
              </div>
              <div className={classes.displayFlex}>
                <TextField
                  value={certificateObjData?.advanceDeductionPercentage}
                  size="small"
                  onChange={(e) => {
                    setStateChange(!stateChange);
                    setCertificateObjData({
                      ...certificateObjData,
                      advanceDeductionPercentage: e.target.value,
                    });
                    setStateChange(!stateChange);
                  }}
                  disabled
                  variant="outlined"
                  placeholder="Enter percentage"
                  type="number"
                  className={classes.percentageSty}
                />
                <TextFieldNumberFormated
                  value={certificateObjData?.advanceDeductionAmount}
                  size="small"
                  onChange={(e) => {
                    setStateChange(!stateChange);
                    setCertificateObjData({
                      ...certificateObjData,
                      advanceDeductionAmount: e.target.value,
                    });
                    setStateChange(!stateChange);
                  }}
                  disabled
                  variant="outlined"
                  placeholder="Enter amount"
                  type="number"
                  className={classes.amountSty}
                />
              </div>
              <p className={classes.validationSty}>{ }</p>
            </div>
            <div className={classes.inputLable}>
              <div className={classes.sectionTop}>
                <h3>Tax(D)</h3>
                {needToMakeDisable ? (<div></div>) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    disabled={loader}
                    onClick={() => {
                      addItem("tax");
                    }}
                  >
                    Add New
                  </Button>
                )}
              </div>
              {certificateObjData?.taxs &&
                certificateObjData.taxs.map((tax, i) => (
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <div className={classes.displayFlex}>
                      <Autocomplete
                        value={tax?.tax}
                        options={taxes}
                        disableClearable
                        disabled={needToMakeDisable ? true : loader}
                        getOptionLabel={(option) => {
                          return option?.name;
                        }}
                        getOptionSelected={(option) => {
                          return option?._id === tax?.tax?._id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            placeholder={"Select tax"}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value?._id !== "addNew") {
                            setStateChange(!stateChange);
                            let locTax = JSON.parse(
                              JSON.stringify(certificateObjData?.taxs || [])
                            );
                            locTax[i].tax = value;
                            let amount = 0;
                            if (
                              certificateObjData?.completionAmount &&
                              parseFloat(certificateObjData?.completionAmount) >
                              0
                            ) {
                              if (value?.discountOrTax?.type === "%") {
                                amount = (
                                  (parseFloat(
                                    certificateObjData?.completionAmount || 0
                                  ) /
                                    100) *
                                  parseFloat(value?.discountOrTax?.percent || 0)
                                ).toFixed(2);
                              } else {
                                amount = value?.discountOrTax?.amount;
                              }
                            }
                            locTax[i].amount = amount;
                            setCertificateObjData({
                              ...certificateObjData,
                              taxs: locTax,
                            });
                            setTaxAndTx({
                              _id: tax?._id,
                              taxId: value?._id,
                              amount: amount,
                            });
                            setStateChange(!stateChange);
                          } else {
                            setOpenTaxDiscountCreateDrawer(true);
                          }
                        }}
                        className={classes.percentageSty}
                        size="small"
                      />
                      <TaxDiscountCreateDrawer
                        open={openTaxDiscountCreateDrawer}
                        setOpen={setOpenTaxDiscountCreateDrawer}
                        walletId={walletId}
                        libraryId={walletData?.defaultLibrary?._id}
                        onSelect={(value) => {
                          setStateChange(!stateChange);
                          let locTax = JSON.parse(
                            JSON.stringify(certificateObjData?.taxs || [])
                          );
                          locTax[i].tax = value;
                          let amount = 0;
                          if (
                            certificateObjData?.completionAmount &&
                            parseFloat(certificateObjData?.completionAmount) > 0
                          ) {
                            if (value?.discountOrTax?.type === "%") {
                              amount = (
                                (parseFloat(
                                  certificateObjData?.completionAmount || 0
                                ) /
                                  100) *
                                parseFloat(value?.discountOrTax?.percent || 0)
                              ).toFixed(2);
                            } else {
                              amount = value?.discountOrTax?.amount;
                            }
                          }
                          locTax[i].amount = amount;
                          setCertificateObjData({
                            ...certificateObjData,
                            taxs: locTax,
                          });
                          setTaxAndTx({
                            _id: tax?._id,
                            taxId: value?._id,
                            amount: amount,
                          });
                          setTaxes([...taxes, value]);
                          setStateChange(!stateChange);
                        }}
                        profileId={entityData?.profile}
                        createType={"Tax"}
                      />
                      <div className={classes.amountDeleteSty}>
                        <TextFieldNumberFormated
                          value={tax?.amount}
                          size="small"
                          disabled
                          variant="outlined"
                          placeholder="Enter amount"
                          type="number"
                          className={classes.amountSty}
                        />
                        <IconButton
                          onClick={() => {
                            deleteAdjustment(tax?._id);
                          }}
                          disabled={needToMakeDisable}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                    <p className={classes.validationSty}>{ }</p>
                  </div>
                ))}
            </div>
            {certificateObjData?.taxs && certificateObjData.taxs.length > 0 ? (
              <div className={classes.subtotalAmountView}>
                <div>
                  <p>Total Tax Amount</p>
                  <h3>
                    ₹{numberWithCommas(certificateObjData?.totalTaxAmount || 0)}
                  </h3>
                </div>
              </div>
            ) : null}
            <div className={classes.inputLable}>
              <div className={classes.sectionTop}>
                <h3>Debit Note(E)</h3>

                {needToMakeDisable ? (<div></div>) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={loader}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      addItem("debit");
                    }}
                  >
                    Add New
                  </Button>
                )}
              </div>

              {certificateObjData?.debitNotes &&
                certificateObjData.debitNotes.map((debitNote, i) => (
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <div className={classes.displayFlex}>
                      <Autocomplete
                        value={debitNote?.debitNote}
                        options={debitNotes}
                        disableClearable
                        getOptionLabel={(option) => {
                          if (option?.type === "DebitNote") {
                            return `DN-${option.txNo}`;
                          } else if (option?.type === "CreditNote") {
                            return `CN-${option.txNo}`;
                          } else {
                            return option?.name;
                          }
                        }}
                        disabled={needToMakeDisable ? true : loader}
                        getOptionSelected={(option) => {
                          return option?._id === debitNote?.debitNote?._id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            placeholder={"Select debit note"}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value?._id !== "addNew") {
                            setStateChange(!stateChange);
                            let locDebitNote = JSON.parse(
                              JSON.stringify(
                                certificateObjData?.debitNotes || []
                              )
                            );
                            locDebitNote[i].debitNote = value;
                            locDebitNote[i].amount = value?.finalAmount;
                            setCertificateObjData({
                              ...certificateObjData,
                              debitNotes: locDebitNote,
                            });
                            setTaxAndTx({
                              _id: debitNote?._id,
                              debitNote: value?._id,
                              amount: parseFloat(value?.finalAmount || 0),
                            });
                            setStateChange(!stateChange);
                          } else {
                            addCreditDebitNote("DebitNote");
                          }
                        }}
                        className={classes.percentageSty}
                        size="small"
                      />
                      <div className={classes.amountDeleteSty}>
                        <TextFieldNumberFormated
                          value={debitNote?.amount}
                          size="small"
                          disabled
                          variant="outlined"
                          placeholder="Enter amount"
                          type="number"
                          className={classes.amountSty}
                        />
                        <IconButton
                          onClick={() => {
                            deleteAdjustment(debitNote?._id);
                          }}
                          disabled={needToMakeDisable}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                    <p className={classes.validationSty}>{ }</p>
                  </div>
                ))}
            </div>
            {certificateObjData?.debitNotes &&
              certificateObjData.debitNotes.length > 0 ? (
              <div className={classes.subtotalAmountView}>
                <div>
                  <p>Total Debit Amount</p>
                  <h3>
                    ₹
                    {numberWithCommas(certificateObjData?.debitNoteAmount || 0)}
                  </h3>
                </div>
              </div>
            ) : null}
            <div className={classes.inputLable}>
              <div className={classes.sectionTop}>
                <h3>Credit Note(F)</h3>
                {needToMakeDisable ? (<div></div>) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={loader}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      addItem("credit");
                    }}
                  >
                    Add New
                  </Button>
                )}
              </div>

              {certificateObjData?.creditNotes &&
                certificateObjData.creditNotes.map((creditNote, i) => (
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <div className={classes.displayFlex}>
                      <Autocomplete
                        value={creditNote?.creditNote}
                        options={creditNotes}
                        disableClearable
                        getOptionLabel={(option) => {
                          if (option?.type === "DebitNote") {
                            return `DN-${option.txNo}`;
                          } else if (option?.type === "CreditNote") {
                            return `CN-${option.txNo}`;
                          } else {
                            return option?.name;
                          }
                        }}
                        disabled={needToMakeDisable ? true : loader}
                        getOptionSelected={(option) => {
                          return option?._id === creditNote?.creditNote?._id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            placeholder={"Select credit note"}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value?._id !== "addNew") {
                            setStateChange(!stateChange);
                            let locCreditNotes = JSON.parse(
                              JSON.stringify(
                                certificateObjData?.creditNotes || []
                              )
                            );
                            locCreditNotes[i].creditNote = value;
                            locCreditNotes[i].amount = value?.finalAmount;
                            setCertificateObjData({
                              ...certificateObjData,
                              creditNotes: locCreditNotes,
                            });
                            setTaxAndTx({
                              _id: creditNote?._id,
                              creditNote: value?._id,
                              amount: parseFloat(value?.finalAmount || 0),
                            });
                            setStateChange(!stateChange);
                          } else {
                            addCreditDebitNote("CreditNote");
                          }
                        }}
                        className={classes.percentageSty}
                        size="small"
                      />
                      <div className={classes.amountDeleteSty}>
                        <TextFieldNumberFormated
                          value={creditNote?.amount}
                          size="small"
                          disabled
                          variant="outlined"
                          placeholder="Enter amount"
                          type="number"
                          className={classes.amountSty}
                        />
                        <IconButton
                          onClick={() => {
                            deleteAdjustment(creditNote?._id);
                          }}
                          disabled={needToMakeDisable}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                    <p className={classes.validationSty}>{ }</p>
                  </div>
                ))}
            </div>
            {certificateObjData?.creditNotes &&
              certificateObjData.creditNotes.length > 0 ? (
              <div className={classes.subtotalAmountView}>
                <div>
                  <p>Total Credit Amount</p>
                  <h3>
                    ₹
                    {numberWithCommas(
                      certificateObjData?.creditNoteAmount || 0
                    )}
                  </h3>
                </div>
              </div>
            ) : null}
            <div className={classes.totalAmountView}>
              <div>
                <p>Net Amount (A - B - C + D + E - F)</p>
                <h3>₹{numberWithCommas(certificateObjData?.netAmount || 0)}</h3>
              </div>
            </div>
            <div className={classes.sectionTop}> 
            <BlocksAndTasksHandler
            tx={certificateData}
            walletId={walletId}
            selectProject={certificateData?.workOrder?.vendor?.parent?.parent}
            //setSelectedProject={setSelectedProject}
            parentModel="FinancialCertificate"
            initialBlocks={blocks}
            initialUnits={units}
            initialTasks={tasks}
            initialattachedfiles={taskattached}
          />
          </div>

            <div className={classes.sectionTop}>
              <h3>Attachments</h3>
              <div></div>
            </div>
            {certificateData?._id && (
              <AttachmentsHandler
                parentData={certificateData}
                setParentData={setCertificateData}
                parentModel={"FinancialCertificate"}
                setLoadingBool={setLoader}
                needToMakeDisable={needToMakeDisable}
              />
            )}
          </>
        ) : (
          <div className={classes.emptyWorkorder}>
            <img src={emptyBill} />
            <p>
              To work futher with certificate you need to select a workorder
              first
            </p>
          </div>
        )}
      </div>
      <NotifyAlert
        alertObj={submitCheckValidation}
        setAlertObj={setSubmitCheckValidation}
      />
    </div>
  );
}