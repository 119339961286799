import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, TextField, InputAdornment } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddContactDialog from './AddContactDialog';
import { useDebounce } from 'react-use';

const useStyles = makeStyles((theme) => ({
    noBorder: {
        border: "none"
    },
    paperCont: {
        width: "400px",
        backgroundColor: "white",
        padding: "3px 0px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    textFieldSty: {
        width: "100%"
    }
}));


export default function AddFinRelConfirm(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        notAllowedUserIds, notAllowedOrgIds, notAllowedProjectIds,
        notAllowedProfileIds, setContactsArr, setNotAllowedUserIds,
        setNotAllowedOrgIds, setNotAllowedProjectIds, contactsArr,
        wallet, parent, profileId, parentModelName, type,
        setFilteredContactsArr, setNotAllowedProfileIds,
    } = props

    const [entityText, setEntityText] = useState("")
    const [openAddDialog, setOpenAddDialog] = useState(false)

    useDebounce(() => {
        let locArr = []
        contactsArr.map((contactData) => {
            let name = contactData?.profile?.parent?.displayName
            let username = contactData?.profile?.parent?.username
            const patt = new RegExp(entityText, "i");
            const res1 = patt.test(name);
            const res2 = patt.test(username);

            if (res1 || res2) {
                locArr.push(contactData)
            }
        });
        setFilteredContactsArr(locArr)
    }, 500, [entityText])


    return (
        <Paper elevation={1} className={classes.paperCont} >
            <TextField
                placeholder={`Search ${type}`}
                variant="outlined"
                size="small"
                className={classes.textFieldSty}
                value={entityText}
                onChange={(e) => { setEntityText(e.target.value) }}
                InputProps={{
                    classes: { notchedOutline: classes.noBorder },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={() => { setOpenAddDialog(true) }}
                            >
                                Add New
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
            <AddContactDialog
                notAllowedUserIds={notAllowedUserIds}
                notAllowedOrgIds={notAllowedOrgIds}
                notAllowedProjectIds={notAllowedProjectIds}
                notAllowedProfileIds={notAllowedProfileIds}
                setNotAllowedUserIds={setNotAllowedUserIds}
                setNotAllowedOrgIds={setNotAllowedOrgIds}
                setNotAllowedProjectIds={setNotAllowedProjectIds}
                setNotAllowedProfileIds={setNotAllowedProfileIds}
                openAddDialog={openAddDialog}
                setOpenAddDialog={setOpenAddDialog}
                wallet={wallet}
                parent={parent}
                profileId={profileId}
                parentModelName={parentModelName}
                type={type}
                setFilteredContactsArr={setFilteredContactsArr}
                contactsArr={contactsArr}
                setContactsArr={setContactsArr}
            />
        </Paper>
    );
}