import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { IconButton, ClickAwayListener } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDebounce } from 'react-use';
import Api from '../../../../../helpers/Api';
import arrayToReducer from '../../../../../helpers/arrayToReducer';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CreateOffering from '../../../productsAndServices/create.offering';
import AutocompleteWithSeveralCreates from '../../../../styled/CommonComponents/AutocompleteWithSeveralCreates';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CreateDiscountOrTax from './Create.DiscountOrTax';
import { createOfferingRelationWithOffering } from '../../../commonComponent/transaction/api';
import OfferingCreateDrawer from '../../../../OfferingsAndProducts/Offering.Create.Drawer';
import { UNIT_OPTIONS } from "../../../../../helpers/constants"

const useStyles = makeStyles((theme) => ({
    mainInputCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "25px",
        paddingRight: "5px",
        borderRadius: "10px",
        border: "1px solid #dcdcdc",
        marginBottom: "20px",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            padding: "10px",
        }
    },
    allInputsCont: {
        width: "calc(100% - 50px)",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            "& .MuiCheckbox-root": {
                height: "40px"
            }
        }
    },
    deleteCont: {
        width: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            marginTop: "15px"
        }
    },
    productStyle: {
        width: "230px",
    },
    codeSty: {
        width: "calc((100% - 230px) / 5)"
    },
    qtyCont: {
        width: "calc((100% - 230px) / 4)",
        position: "relative"
    },
    rateSty: {
        width: "calc((100% - 230px) / 5)"
    },
    onlyTotalSty: {
        width: "calc((100% - 230px) / 4)"
    },
    descSty: {
        width: "100%",
        marginTop: "20px"
    },
    unitCont: {
        position: "absolute",
        top: "8px",
        right: "8px",
        width: "100px",
        height: "25px",
        zIndex: "99",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    showUnitOptionCont: {
        width: "90px",
        height: "350px",
        overflowY: "auto",
        position: "absolute",
        zIndex: "999",
        top: "35px",
        right: "-2px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
        transition: "all .2s ease-in-out"
    },
    hideUnitOptionCont: {
        width: "90px",
        height: "0px",
        overflowY: "hidden",
        position: "absolute",
        zIndex: "999",
        top: "35px",
        right: "-2px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
        transition: "all .2s ease-in-out"
    },
    unitOptionSty: {
        padding: "3px 2px",
        textAlign: "left",
        fontSize: "14px",
        cursor: "pointer",
        fontWeight: "500",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    }
}));

export default function EditListItemWithoutAccounting({
    itemId, offeringRelations, billItemDict, setBillItemDict, billList, listType,
    setBillList, totalLen, setOfferingRelations, setLoadingBool, libraryData,
    currentProfile, currentWallet, defaultIncomeAcc, defaultExpenseAcc,
    needToMakeDisable
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { walletId } = useParams();

    const { user } = useSelector((state) => state.auth);
    const billItemOld = billItemDict[itemId];

    //other datas of bill items
    const [itemObject, setItemObject] = useState(billItemOld);
    const [offeringRelationValue, setOfferingRelationValue] = useState(billItemOld?.offeringRelation || null);
    const [qty, setQty] = useState(billItemOld?.qTy || 1);
    const [rate, setRate] = useState(billItemOld?.rate || 0);
    const [code, setCode] = useState(billItemOld?.code || "");
    const [openUnit, setOpenUnit] = useState(false)
    const [unit, setUnit] = useState(billItemOld?.unit || "unit");
    const [desc, setDesc] = useState(billItemOld?.description || " ");
    const [createOfferingDawer, setCreateOfferingDrawer] = useState(false)
    const [unitsArr, setUnitsArr] = useState([])

    //to stope initial api call
    const [initialDStopper1, setInitialDStopper1] = useState(false)

    useEffect(() => {
        let arr = [];
        Object.keys(UNIT_OPTIONS.units).map((unitGroup) => {
            let groupUntitArr = UNIT_OPTIONS.units[unitGroup] || []
            groupUntitArr.map((unitData) => {
                arr.push(unitData?.unit.toLowerCase())
            })
        })
        setUnitsArr(arr)
    }, [UNIT_OPTIONS])

    useEffect(() => {
        if (billItemOld?.offeringRelation) {
            setOfferingRelationValue({
                name: billItemOld?.offeringRelation?.offering?.name,
                category: { _id: billItemOld?.offeringRelation?.category },
                code: billItemOld?.offeringRelation?.offering?.code,
                codeLibrary: { _id: billItemOld?.offeringRelation?.library },
                libraryLable: "",
                isPublic: billItemOld?.offeringRelation?.offering?.isPublic,
                unit: billItemOld?.offeringRelation?.offering?.unit,
                taxType: billItemOld?.offeringRelation?.offering?.taxType,
                tax: billItemOld?.offeringRelation?.offering?.tax,
                doSell: billItemOld?.offeringRelation?.offering?.doSell,
                incomeDescription: billItemOld?.offeringRelation?.offering?.incomeDescription,
                doPurchase: billItemOld?.offeringRelation?.offering?.doPurchase,
                expenseDescription: billItemOld?.offeringRelation?.offering?.expenseDescription,
                model: billItemOld?.offeringRelation?.offering?.model,
                default: billItemOld?.offeringRelation?.offering?.default,
                price: billItemOld?.offeringRelation?.offering?.price,
                purchasePrice: billItemOld?.offeringRelation?.offering?.purchasePrice,
                purchaseUnit: billItemOld?.offeringRelation?.offering?.purchaseUnit,
                isInventory: billItemOld?.offeringRelation?.offering?.isInventory,
                profileArr: billItemOld?.offeringRelation?.offering?.profileArr,
                profile: billItemOld?.offeringRelation?.offering?.profile,
                shared: billItemOld?.offeringRelation?.offering?.shared,
                addedBy: billItemOld?.offeringRelation?.offering?.addedBy,
                createdAt: billItemOld?.offeringRelation?.offering?.createdAt,
                updatedAt: billItemOld?.offeringRelation?.offering?.updatedAt,
                offeringRelation: billItemOld?.offeringRelation,
                incomeChartAccount: billItemOld?.offeringRelation?.incomeChartAccount,
                expenseChartAccount: billItemOld?.offeringRelation?.expenseChartAccount
            })
        }
    }, [billItemOld?.offeringRelation || null])

    // to update bill list item
    const updateApi = async (obj) => {
        if (!needToMakeDisable) {
            await Api.post('wallet/billList/update/item', obj)
                .then((res) => {
                    const item = res?.data;
                    const { newDict } = arrayToReducer([item]);

                    setBillItemDict({
                        ...billItemDict,
                        ...newDict,
                    });
                });
        }
    };

    useDebounce(() => {
        if (initialDStopper1 && !needToMakeDisable) {
            const isEqual = _.isEqual(itemObject, billItemOld);
            if (!isEqual) {
                updateApi(itemObject);
            }
        } else {
            setInitialDStopper1(true)
        }
    }, 1000, [itemObject, itemObject?.offeringRelation,
        itemObject?.chartAccount, itemObject?.type,
        itemObject?.name, itemObject?.description,
        itemObject?.qTy, itemObject?.rate, itemObject?.tax
    ]);

    // to update product and service
    const onSelectProductService = async (selected, newlyCreated = false) => {
        if (selected && selected._id && !needToMakeDisable) {
            let selectedOffering = {};
            if (selected?.offeringRelation?._id) {
                selectedOffering = {
                    ...itemObject,
                    offeringRelation: selected?.offeringRelation,
                    chartAccount: (listType === "Bill" || listType === "CreditNote") ? selected?.expenseChartAccount : selected?.incomeChartAccount,
                    type: selected.model,
                    name: selected.name,
                    description: (listType === "Bill" || listType === "CreditNote") ? selected.expenseDescription : selected.incomeDescription,
                    qTy: 1,
                    code: selected?.code,
                    unit: (listType === "Bill" || listType === "CreditNote") ? selected.purchaseUnit : selected?.unit,
                    rate: (listType === "Bill" || listType === "CreditNote") ? selected?.purchasePrice : selected?.price,
                };
            } else {
                let updateOfferingRelationObj = {
                    profile: currentProfile,
                    shared: [currentProfile],
                    user: user?._id,
                    wallet: currentWallet,
                    offering: selected?._id
                }
                if (defaultIncomeAcc) {
                    updateOfferingRelationObj.incomeChartAccount = defaultIncomeAcc
                }
                if (selected?.category?._id) {
                    updateOfferingRelationObj.category = selected?.category?._id
                }
                if (selected?.codeLibrary?._id) {
                    updateOfferingRelationObj.library = selected?.codeLibrary?._id
                }
                if (selected?.doPurchase && defaultExpenseAcc) (
                    updateOfferingRelationObj.expenseChartAccount = defaultExpenseAcc
                )
                await createOfferingRelationWithOffering(updateOfferingRelationObj)
                    .then((newRelation) => {
                        selectedOffering = {
                            ...itemObject,
                            offeringRelation: newRelation?.offeringRelation,
                            chartAccount: (listType === "Bill" || listType === "CreditNote") ? newRelation?.expenseChartAccount : newRelation?.incomeChartAccount,
                            type: newRelation.model,
                            name: newRelation.name,
                            description: (listType === "Bill" || listType === "CreditNote") ? newRelation.expenseDescription : newRelation.incomeDescription,
                            qTy: 1,
                            code: newRelation?.code,
                            unit: (listType === "Bill" || listType === "CreditNote") ? newRelation.purchaseUnit : newRelation?.unit,
                            rate: (listType === "Bill" || listType === "CreditNote") ? newRelation?.purchasePrice : newRelation?.price,
                        };
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }

            setOfferingRelationValue(selectedOffering?.offeringRelation);
            setDesc(selectedOffering.description);
            setQty(1);
            setRate(selectedOffering.rate);
            setItemObject(selectedOffering);
            setCode(selectedOffering?.code || "");
            setUnit(selectedOffering?.unit || "");
            if (newlyCreated) {
                const productArr = [...offeringRelations, selected]
                setOfferingRelations(productArr)
            }
        }
    };

    // to delete current item
    const deleteItem = async () => {
        if (billList?._id && !needToMakeDisable) {
            setLoadingBool(true);
            const itemOld = billList.items;
            const newItems = _.difference(itemOld, [itemId]);
            setBillList({
                ...billList,
                items: newItems,
            });
            await Api.post('wallet/billList/delete/item', {
                billId: billList._id,
                itemId,
            }).then((res) => {
                console.log("res")
            });
            setLoadingBool(false);
        }
    };

    return (
        <div className={classes.mainInputCont} >
            <div className={classes.allInputsCont} >
                {/* Product & Services */}
                <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={offeringRelationValue}
                    options={offeringRelations}
                    disableClearable
                    disabled={needToMakeDisable}
                    getOptionLabel={(option) => { return option?.name || " " }}
                    getOptionSelected={(option) => {
                        return option?._id === offeringRelationValue?._id
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            placeholder="Select Product & Services"
                            label="Product/Services"
                        />
                    )}
                    onChange={(event, value) => {
                        if (value?._id === "New") {
                            event.preventDefault();
                            setCreateOfferingDrawer(true)
                            setOfferingRelationValue(offeringRelationValue)
                        } else {
                            onSelectProductService(value, false)
                        }
                    }}
                    className={classes.productStyle}
                    size="small"
                />
                {!needToMakeDisable && (
                    <OfferingCreateDrawer
                        purchase={false}
                        selling={true}
                        library={libraryData}
                        profileId={currentProfile}
                        createOfferingDawer={createOfferingDawer}
                        setCreateOfferingDrawer={setCreateOfferingDrawer}
                        afterOfferingCreateOrEdit={(offeringRelation, isEdit) => {
                            onSelectProductService(offeringRelation, true)
                        }}
                    />
                )}
                {/* Product & Services code */}
                <TextField
                    placeholder="Enter code"
                    label="Code"
                    size="small"
                    variant="outlined"
                    disabled={needToMakeDisable}
                    onChange={(e) => {
                        setCode(e.target.value)
                        setItemObject({
                            ...itemObject,
                            code: e.target.value,
                        });
                    }}
                    value={code}
                    className={classes.codeSty}
                />
                {/* Quantity */}
                <ClickAwayListener onClickAway={() => { setOpenUnit(false) }} >
                    <div className={classes.qtyCont} >
                        <TextField
                            id="outlined-basic"
                            label="qTy"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={qty}
                            disabled={needToMakeDisable}
                            onChange={(e) => {
                                setQty(e.target.value)
                                setItemObject({
                                    ...itemObject,
                                    qTy: e.target.value,
                                });
                            }}
                            style={{ width: "100%" }}
                        />
                        <div
                            className={classes.unitCont}
                            onClick={() => {
                                if (!needToMakeDisable) {
                                    setOpenUnit(!openUnit)
                                }
                            }}
                        >
                            {unit}
                        </div>
                        <div className={openUnit ? classes.showUnitOptionCont : classes.hideUnitOptionCont} >
                            <div>
                                {unitsArr && unitsArr.map((unitData) => (
                                    <p
                                        onClick={() => {
                                            setUnit(unitData)
                                            setOpenUnit(false)
                                            setItemObject({
                                                ...itemObject,
                                                unit: unitData,
                                            });
                                        }}
                                        className={classes.unitOptionSty}
                                        style={unitData === unit ? { backgroundColor: "#ececec" } : { backgroundColor: "white" }}
                                    >
                                        {unitData}
                                    </p>))}
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
                {/* rate per unit */}
                <TextField
                    placeholder="Enter Rate"
                    label="Rate"
                    size="small"
                    variant="outlined"
                    type="number"
                    disabled={needToMakeDisable}
                    onChange={(e) => {
                        setRate(e.target.value)
                        setItemObject({
                            ...itemObject,
                            rate: e.target.value,
                        });
                    }}
                    value={rate}
                    className={classes.rateSty}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                        ),
                    }}
                />
                {/* Tax and total */}
                <TextField
                    label="Total"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={itemObject?.qTy * itemObject?.rate}
                    className={classes.onlyTotalSty}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                        )
                    }}
                    disabled
                />
                {/* description */}
                <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    size="small"
                    multiline
                    rows={2}
                    variant="outlined"
                    value={desc}
                    disabled={needToMakeDisable}
                    onChange={(e) => {
                        setDesc(e.target.value)
                        setItemObject({
                            ...itemObject,
                            description: e.target.value
                        });
                    }}
                    className={classes.descSty}
                />
            </div>
            {totalLen === 1 ? (<div></div>) : (<div className={classes.deleteCont} >
                <IconButton
                    onClick={() => {
                        deleteItem();
                    }}
                    disabled={needToMakeDisable}
                >
                    <DeleteIcon />
                </IconButton>
            </div>)}
        </div>
    );
}