import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import { Paper, Button, IconButton } from '@material-ui/core';
import moment from 'moment';
import CreateIcon from '@material-ui/icons/Create';
import LessText from '../../../styled/CommonComponents/LessText';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 15px",
    margin: "20px 0px",
  },
  mainCont: {
    width: "100%",
    borderRadius: "10px",
    overflow: "hidden",
  },
  topbar: {
    width: "100%",
    display: "flex",
    padding: "5px 15px",
    backgroundColor: "#d1e8f5",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "22px"
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500"
    }
  },
  infoMainCont: {
    display: "flex",
    flexWrap: "wrap",
    padding: "15px"
  },
  customerCont: {
    width: "50%",
    marginBottom: "10px",
    "& p": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#656464"
    },
    "& h3": {
      fontSize: "17px",
      fontWeight: "510"
    },
  },
  creatorCont: {
    width: "45%",
    marginBottom: "10px",
    "& p": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#656464"
    },
    "& h3": {
      fontSize: "17px",
      fontWeight: "510",
    },
  },
  sub3Date: {
    width: "33%",
    padding: "0px 5px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& p": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#656464"
    },
    "& h3": {
      fontSize: "17px",
      fontWeight: "510"
    },
    "& h4": {
      backgroundColor: theme.palette.primary.success,
      color: "white",
      padding: "0px 10px 1px",
      borderRadius: "4px",
      fontSize: "17px",
      fontWeight: "500",
    },
    "& h5": {
      backgroundColor: theme.palette.primary.fail,
      color: "white",
      padding: "0px 10px 1px",
      borderRadius: "4px",
      fontSize: "17px",
      fontWeight: "500",
    },
    [theme.breakpoints.down("xs")]: {
      width: "47%",
    }
  }
}));

export default function TemplateCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { selectedGenerator, txLen, walletId } = props;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const onclickEdit = () => {
    if (selectedGenerator?.type === "Invoice") {
      history.push(`/finance/${walletId}/invoice-generator/edit/${selectedGenerator?._id}`)
    } else {
      history.push(`/finance/${walletId}/bill-generator/edit/${selectedGenerator?._id}`)
    }
  }

  return (
    <div className={classes.root} >
      <Paper elevation={2} className={classes.mainCont} >
        <div className={classes.topbar} >
          {selectedGenerator?.type === "Invoice" && (<>
            <h3>ID-{selectedGenerator?.txNo}</h3>
            <div style={{ display: "flex", alignItems: "center" }} >
              <p>INVOICE GENERATOR</p>
              <IconButton
                style={{ marginLeft: "10px" }}
                onClick={onclickEdit}
              >
                <CreateIcon />
              </IconButton>
            </div>
          </>)}
          {selectedGenerator?.type === "Bill" && (<>
            <h3>ID-{selectedGenerator?.txNo}</h3>
            <div style={{ display: "flex", alignItems: "center" }} >
              <p>BILL GENERATOR</p>
              <IconButton
                style={{ marginLeft: "10px" }}
                onClick={onclickEdit}
              >
                <CreateIcon />
              </IconButton>
            </div>
          </>)}
        </div>

        <div className={classes.infoMainCont} >
          <div className={classes.customerCont} >
            <p>
              {selectedGenerator?.type === "Bill" && (<>Vendor</>)}
              {selectedGenerator?.type === "Invoice" && (<>Recipient</>)}
            </p>
            <div style={{ display: "flex", alignItems: "center" }} >
              <Avatar
                alt={selectedGenerator?.secondParty?.parent?.displayName}
                src={selectedGenerator?.secondParty?.parent?.displayPicture?.url}
                style={{ width: "25px", height: "25px", marginRight: "10px" }}
              />
              <h3>
                <LessText
                  limit={10}
                  string={selectedGenerator?.secondParty?.parent?.displayName}
                />
              </h3>
            </div>
          </div>
          <div className={classes.creatorCont} >
            <p>Created by</p>
            <h3>
              <LessText
                limit={12}
                string={selectedGenerator?.creatorProfile?.parent?.displayName}
              />
            </h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Start Date</p>
            <h3>{moment(selectedGenerator?.schedulingData?.startDate).format('MMM Do YYYY')}</h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Next Date</p>
            <h3>{moment(selectedGenerator?.schedulingData?.nextDate).format('MMM Do YYYY')}</h3>
          </div>
          <div className={classes.sub3Date} >
            <p>End Date</p>
            <h3>{moment(selectedGenerator?.schedulingData?.stopDate).format('MMM Do YYYY')}</h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Amount</p>
            <h3>₹ {numberWithCommas(selectedGenerator?.finalAmount || 0)}</h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Late Term</p>
            <h3>
              <LessText
                limit={7}
                string={selectedGenerator?.term?.name}
              />
            </h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Late Fess</p>
            <h3>₹ {numberWithCommas(selectedGenerator?.lateFeeAmount || 0)}</h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Transactions</p>
            <h3>{txLen}</h3>
          </div>
          <div className={classes.sub3Date} >
            <p>Status</p>
            {selectedGenerator?.schedulingData?.scheduled ? (<h4>
              Active
            </h4>) : (<h5>
              Inactive
            </h5>)}
          </div>
        </div>
      </Paper>
    </div>
  );
}