import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, IconButton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { useParams, useHistory } from 'react-router-dom';
import LessText from '../../styled/CommonComponents/LessText';
import MyPopOver from '../../styled/CommonComponents/MyPopOver';
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
    singleCardStyle: {
        width: "calc(33.3% - 20px)",
        textAlign: "center",
        borderRadius: "5px",
        padding: "10px 10px",
        marginTop: "30px",
        marginRight: "20px",
        position: "relative",
        zIndex: "11",
        [theme.breakpoints.down("md")]: {
            width: "calc(50% - 50px)",
            marginTop: "30px",
            marginRight: "50px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(50% - 15px)",
            minWidth: "310px",
            marginTop: "30px",
            marginRight: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            margin: "0px",
            marginTop: "30px",
        },
    },
    cardHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    infoCont: {
        marginLeft: "5px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510",
            textAlign: "left"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "450",
            color: "gray",
            textAlign: "left"
        }
    },
    threeDotSty: {
        position: "absolute",
        top: "5px",
        right: "10px",
        zIndex: "11",
    },
    optionsCont: {
        width: "125px",
        padding: "5px 0px",
        "& a": {
            display: "block",
            width: "100%",
            textDecoration: "none",
            color: "black",
            fontSize: "13px",
            fontWeight: "400",
            padding: "4px 5px",
            textAlign: "left",
            cursor: "pointer",
        }
    }
}));


export default function NetworkCardView({ contactData, type }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { walletId } = useParams();

    const seeFullTx = (secondPartyWallet) => {
        const goUrl = `/finance/${walletId}/related-tx/${secondPartyWallet?._id}/${type}`
        history.push(goUrl);
    }

    const seeRelationAnalysis = (secondPartyWallet) => {
        const goUrl = `/finance/${walletId}/relation-analysis/${secondPartyWallet?._id}/${type}`
        history.push(goUrl);
    }

    return (
        <Paper className={classes.singleCardStyle} >
            <div className={classes.cardHeader} >
                <Avatar
                    style={{ width: "35px", height: "35px" }}
                    src={contactData?.profile?.parent?.displayPicture?.url}
                    alt={contactData?.profile?.parent?.displayName}
                />
                <div className={classes.infoCont} >
                    <h3>
                        <LessText
                            limit={25}
                            string={contactData?.profile?.parent?.displayName}
                        />
                    </h3>
                    <p>{`@${contactData?.profile?.parent?.username || "username"}`}</p>
                </div>
            </div>
            <div className={classes.threeDotSty} >
                <MyPopOver
                    closeOnClick={true}
                    appearContent={<IconButton><MoreVertIcon /></IconButton>}
                    showContent={<div className={classes.optionsCont} >
                        <a onClick={() => { if (contactData?.profile?.parent?.wallet) { seeFullTx(contactData?.profile?.parent?.wallet) } }} >Transactions</a>
                        {/* <a>Send Message</a> */}
                        <a onClick={() => { if (contactData?.profile?.parent?.wallet) { seeRelationAnalysis(contactData?.profile?.parent?.wallet) } }} >Financial Analysis</a>
                    </div>}
                />
            </div>
        </Paper>
    );
}