import React, { useState } from 'react';
import TextField from "../../../styled/generic/TextField";
import DatePicker from "../../../styled/generic/DatePicker";
import FormBox from "../../../styled/generic/FormBox";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import Button from "../../../styled/generic/Button";
import dayjs from "dayjs";


const SearchTaskComponent = ({onSearch, projectId }) => {
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

 




  const handleSearch = () => {
    onSearch({
      taskName,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
    });
  };

  return (
    <div>
      <FormBox label="Task Name">
        <TextField
          placeholder="Enter task name"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          fullWidth={true}
        />
      </FormBox>

      <SpaceBetween
        left={
          <FormBox label="Start Date">
            <DatePicker
              fullWidth={true}
              value={startDate}
              onChange={setStartDate}
            />
          </FormBox>
        }
        right={
          <FormBox label="End Date">
            <DatePicker
              fullWidth={true}
              value={endDate}
              onChange={setEndDate}
            />
          </FormBox>
        }
      />

      <Button onClick={handleSearch}>Search Tasks</Button>
    </div>
  );
};

export default SearchTaskComponent;