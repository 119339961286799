import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Dialog from '../SearchAndAdd/AddDialog'
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({

}));

export default function AutocompleteWithSeveralCreates(props) {
  const {
    value, onSelect, placeholder, offerings, CreateDialogBox,
    open, setOpen, loading, getOptionLabel, lable, isdisabled = false
  } = props;

  const classes = useStyles();
  const [results, setResults] = useState();

  useEffect(() => {
    setResults(offerings);
  }, [offerings]);


  const onNew = () => {
    setOpen(true);
  };




  return (
    <>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        value={value}
        options={results}
        disableClearable
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option) => {
          return option?._id === value?._id
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ margin: "0px" }}
            margin="normal"
            variant="outlined"
            placeholder={placeholder}
            label={lable}
          />
        )}
        onChange={(event, value) => {
          if (value?._id === "New") {
            onNew();
          } else {
            onSelect(value)
          }
        }}
        style={{ width: "100%" }}
        size="small"
        disabled={isdisabled}
      />
      <Dialog
        open={open}
        setOpen={setOpen}
        loading={loading}
        form={CreateDialogBox}
      />
    </>
  );
}