// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { makeStyles } from "@material-ui/core/styles";
// import { useHistory } from "react-router-dom";
// import Paper from "@material-ui/core/Paper";
// import { ClickAwayListener } from "@material-ui/core";
// import { bankLogoMap } from "../../../../../helpers/PlaidBanksWithLogo";
// import { updateBankAccount } from "../../api.call";
// import LessText from "../../../../styled/CommonComponents/LessText";
// import EditIcon from "@material-ui/icons/Edit";
// import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// import IndianLinkBtn from './indian.bank.btn'; 

// const useStyles = makeStyles((theme) => ({
//   paperStyle: {
//     width: "325px",
//     marginRight: "15px",
//     marginBottom: "15px",
//     padding: "15px 10px",
//     boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
//     borderRadius: "15px",
//     "& img": {
//       height: "30px",
//     },
//     [theme.breakpoints.only("xs")]: {
//       width: "90%",
//       borderRadius: "10px",
//       marginRight: "0px",
//       "& img": {
//         height: "25px",
//       },
//     },
//   },
//   bankIcon: {
//     width: "30px",
//     height: "32px",
//     color: "#0E68AC",
//     [theme.breakpoints.only("xs")]: {
//       width: "25px",
//       height: "27px",
//     },
//   },
//   bankLogoCont: {
//     padding: "4px 8px",
//     borderRadius: "50%",
//     border: `2px solid ${theme.palette.primary.main}`,
//     [theme.breakpoints.only("xs")]: {
//       padding: "2px 6px",
//     },
//   },
//   cardHeader: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     "& h3": {
//       fontSize: "15px",
//       fontWeight: "450",
//       color: "#424242",
//     },
//   },
//   cardsCont: {
//     display: "flex",
//     justifyContent: "center",
//     flexDirection: "column",
//     padding: "20px",
//     [theme.breakpoints.only("xs")]: {
//       padding: "20px 20px 5px",
//     },
//   },
//   divider: {
//     borderTop: "1px solid #c9c8c8",
//     margin: "10px 0px",
//   },
//   nameTypeCont: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     color: "#626060",
//     "& h3": {
//       fontSize: "18px",
//       fontWeight: "500",
//     },
//     "& h4": {
//       fontSize: "14px",
//       fontWeight: "450",
//     },
//   },
//   nameInputEdit: {
//     width: "95%",
//     border: "none",
//     outline: "none",
//     fontSize: "21px",
//     fontWeight: "600",
//     color: "#263238",
//     "&:active": {
//       border: "none",
//       outline: "none",
//     },
//     "&:focus": {
//       border: "none",
//       outline: "none",
//     },
//     [theme.breakpoints.only("xs")]: {
//       width: "97%",
//     },
//   },
//   namesCont: {
//     width: "calc(100% - 60px)",
//     [theme.breakpoints.only("xs")]: {
//       width: "calc(100% - 50px)",
//     },
//   },
//   nameIconCont: {
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     "& h4": {
//       width: "calc(100% - 40px)",
//       fontSize: "20px",
//       fontWeight: "600",
//       color: "#263238",
//     },
//   },
// }));

// export default function IndianBankCard({ bankAccount }) {
//   const classes = useStyles();
//   const history = useHistory();
//   const dispatch = useDispatch();

//   return (
//     <Paper className={classes.paperStyle}>
//       <div className={classes.cardHeader}>
//         <div className={classes.bankLogoCont}>
//           <AccountBalanceIcon className={classes.bankIcon} />
//         </div>
//         <div className={classes.namesCont}>
//           <div className={classes.nameIconCont}>
//             <h4>
//               <LessText
//                 limit={15}
//                 string={bankAccount?.nickName || bankAccount?.bankName}
//               />
//             </h4>
//             <EditIcon
//               style={{ cursor: "pointer" }}
//               onClick={() => {
//                 // setOpenToEdit(true);
//                 console.log("I am used");
//               }}
//             />
//           </div>

//           <h3>
//             <LessText limit={23} string={bankAccount?.beneficiaryName} />
//           </h3>
//         </div>
//       </div>

//       <div className={classes.cardsCont}>
//         <div className={classes.divider}></div>
//         <div className={classes.nameTypeCont}>
//           <h3>{bankAccount?.ifsc}</h3>
//           <h4>{bankAccount?.branchName}</h4>
//         </div>
//       </div>
//     </Paper>
//   );
// }

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LessText from "../../../../styled/CommonComponents/LessText";
import EditIcon from "@material-ui/icons/Edit";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Dialog from "../../../../styled/generic/Dialog";
import EditIndianBankForm from "./indian.bank.edit";

const useStyles = makeStyles((theme) => ({
  // ... (keep all the existing styles)
    paperStyle: {
    width: "325px",
    marginRight: "15px",
    marginBottom: "15px",
    padding: "15px 10px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    "& img": {
      height: "30px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      borderRadius: "10px",
      marginRight: "0px",
      "& img": {
        height: "25px",
      },
    },
  },
  bankIcon: {
    width: "30px",
    height: "32px",
    color: "#0E68AC",
    [theme.breakpoints.only("xs")]: {
      width: "25px",
      height: "27px",
    },
  },
  bankLogoCont: {
    padding: "4px 8px",
    borderRadius: "50%",
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.only("xs")]: {
      padding: "2px 6px",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "15px",
      fontWeight: "450",
      color: "#424242",
    },
  },
  cardsCont: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      padding: "20px 20px 5px",
    },
  },
  divider: {
    borderTop: "1px solid #c9c8c8",
    margin: "10px 0px",
  },
  nameTypeCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#626060",
    "& h3": {
      fontSize: "18px",
      fontWeight: "500",
    },
    "& h4": {
      fontSize: "14px",
      fontWeight: "450",
    },
  },
  nameInputEdit: {
    width: "95%",
    border: "none",
    outline: "none",
    fontSize: "21px",
    fontWeight: "600",
    color: "#263238",
    "&:active": {
      border: "none",
      outline: "none",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
    [theme.breakpoints.only("xs")]: {
      width: "97%",
    },
  },
  namesCont: {
    width: "calc(100% - 60px)",
    [theme.breakpoints.only("xs")]: {
      width: "calc(100% - 50px)",
    },
  },
  nameIconCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h4": {
      width: "calc(100% - 40px)",
      fontSize: "20px",
      fontWeight: "600",
      color: "#263238",
    },
  },
}));

export default function IndianBankCard({ bankAccount, onUpdate }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleEditClick = () => {
    setShowEditDialog(true);
  };

  const handleCloseDialog = () => {
    setShowEditDialog(false);
  };

  const handleUpdateSuccess = () => {
    onUpdate();
    handleCloseDialog();
  };

  return (
    <>
      <Paper className={classes.paperStyle}>
        <div className={classes.cardHeader}>
          <div className={classes.bankLogoCont}>
            <AccountBalanceIcon className={classes.bankIcon} />
          </div>
          <div className={classes.namesCont}>
            <div className={classes.nameIconCont}>
              <h4>
                <LessText
                  limit={15}
                  string={bankAccount?.nickName || bankAccount?.bankName}
                />
              </h4>
              <EditIcon
                style={{ cursor: "pointer" }}
                onClick={handleEditClick}
              />
            </div>

            <h3>
              <LessText limit={23} string={bankAccount?.beneficiaryName} />
            </h3>
          </div>
        </div>

        <div className={classes.cardsCont}>
          <div className={classes.divider}></div>
          <div className={classes.nameTypeCont}>
            <h3>{bankAccount?.ifsc}</h3>
            <h4>{bankAccount?.branchName}</h4>
          </div>
        </div>
      </Paper>

      <Dialog
        open={showEditDialog}
        setOpen={setShowEditDialog}
        title="Edit Bank Account"
      >
        <EditIndianBankForm
          bankDetails={bankAccount}
          onClose={handleCloseDialog}
          onUpdate={handleUpdateSuccess}
        />
      </Dialog>
    </>
  );
}


