import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import AddDwollaBank from './add.dwolla.bank';
import DwollaBankCardItem from './dwolla.bank.item';
import { updateWallet, removeDwollaBankAccount } from './api';
// import dwollaOnlyIcon from "../../../../../Assets/dwollaOnlyIcon.svg"
import { getDwollaData } from '../../api.call';
import PaymentMethodSkeleton from '../../../../styled/skeletons/PaymentMethodSkeleton';

const useStyles = makeStyles((theme) => ({
  detailsCont: {
    "& h4": {
      fontSize: "20px",
      fontWeight: "550"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "450",
      color: "gray"
    }
  },
  paperStyle: {
    width: "100%",
    padding: "20px 30px 30px",
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    marginBottom: "30px",
    [theme.breakpoints.down('md')]: {
      padding: "15px 15px 20px",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "10px 10px 15px",
    }
  },
  dwollaHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "5px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoCont: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginLeft: "10px"
    },
    [theme.breakpoints.down('xs')]: {
      "& h3": {
        fontSize: "22px",
        fontWeight: "500",
        marginLeft: "5px"
      },
    }
  },
  imgStyle: {
    height: "30px",
    width: "auto",
    [theme.breakpoints.down('xs')]: {
      height: "27px",
    }
  },
  allBankAccCont: {
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
    }
  }
}));

export default function DwollaCustomer(props) {
  const { wallet, setWallet } = props;
  const classes = useStyles();
  const history = useHistory();

  const [acct, setAcct] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fundSrcs, setFundSrcs] = useState([]);
  const [fundSrcIds, setFundSrcIds] = useState([]);
  const [loading, setLoading] = useState(false)


  const getDwollaAllData = async () => {
    setLoading(true)
    await getDwollaData({
      customerId: wallet?.dwollaCustomer?._id,
      walletId: wallet?._id
    })
      .then((data) => {
        const newAcct = data?.acct;
        const newCustomer = data?.customer;
        const newFundSrcs = data?.fundSrcs || [];

        setAcct(newAcct);
        setCustomer(newCustomer);
        setFundSrcs(newFundSrcs);
      })
      .catch((err) => {
        console.log(err)
      })
    setLoading(false)
  }

  useEffect(() => {
    let arr = []
    fundSrcs.map((fund) => {
      arr.push(fund?.fundingSrcIdFromPlaid)
    })
    setFundSrcIds(arr)
  }, [fundSrcs])

  useEffect(() => {
    getDwollaAllData()
  }, [])

  // set deafult
  const setDefaultSrc = async (item) => {
    if (item && wallet?._id) {
      setWallet({
        ...wallet,
        defaultDwollaBankAccount: item
      })

      await updateWallet({
        walletId: wallet?._id,
        defaultDwollaBankAccount: item._id
      })
        .then((data) => {
          console.log("data")
        })
        .catch(err => {
          console.log(err);
        })
    }
  };

  // remove bank account
  const removeBankAcc = async (item, selected) => {
    await removeDwollaBankAccount({
      bankId: item?._id,
      bankUrl: item?.url,
    }).then(async (data) => {
      const filteredFundSrcs = fundSrcs.filter(fundSrc => fundSrc._id != item?._id);
      if (selected) {
        //it will run only if the removed account is a default account
        if (fundSrcs.length == 1) {
          //no funding source exist
          await updateWallet({
            walletId: wallet._id,
            defaultDwollaBankAccount: null
          }).then((dataW) => {
            setWallet({
              ...wallet,
              defaultDwollaBankAccount: null
            })
          })
            .catch(err => {
              console.log(err);
            })
        } else {
          //set the first funding(after filter) source as default
          await updateWallet({
            walletId: wallet._id,
            defaultDwollaBankAccount: filteredFundSrcs[0]._id
          }).then((dataW) => {
            setWallet({
              ...wallet,
              defaultDwollaBankAccount: filteredFundSrcs[0]
            })
          })
            .catch(err => {
              console.log(err);
            })
        }
      }

      setFundSrcs(filteredFundSrcs)
    })
      .catch(err => {
        console.log(err);
      })
  }


  return (<>
    {loading ? (<PaymentMethodSkeleton />) : (
      <Paper className={classes.paperStyle}>
        <div className={classes.dwollaHeader} >
          <div className={classes.logoCont} >
            {/* <img
              src={dwollaOnlyIcon}
              className={classes.imgStyle}
            /> */}
            <h3>Dwolla</h3>
          </div>
          <AddDwollaBank
            wallet={wallet}
            dwollaCustomer={customer}
            fundSrcs={fundSrcs}
            setWallet={setWallet}
            setFundSrcs={setFundSrcs}
            fundSrcIds={fundSrcIds}
          />
        </div>

        <div className={classes.detailsCont} >
          <h4>{`${acct?.firstName} ${acct?.lastName} `}</h4>
          <p>{acct?.email}</p>
        </div>

        {fundSrcs && fundSrcs.length > 0 && (
          <div className={classes.allBankAccCont} >
            {fundSrcs.map((item, index) => (
              <DwollaBankCardItem
                key={index}
                item={item}
                setDefaultSrc={setDefaultSrc}
                removeBankAcc={removeBankAcc}
                selected={item?._id === wallet?.defaultDwollaBankAccount?._id}
              />
            ))}
          </div>
        )}
      </Paper>
    )}
  </>);
}