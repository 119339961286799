
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@mui/material/Slider";
import { Select, MenuItem, FormControl, Typography , Box} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  section: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1.8rem",
  },
  taskHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  taskTitle: {
    fontSize: "12px",
    fontWeight: "800",
    color: "#193B56",
  },
  taskDropdown: {
    minWidth: 200,
  },
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  cont: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  imgCont: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "start",
    gap: "1rem",
  },
  img: {
    borderRadius: "0.5rem",
    border: "4px solid #0B79D1",
    height: "7rem",
    width: "10rem",
  },
  notFoundText: {
    fontSize: "0.8rem",
  },
}));

const TaskAttachments = ({ selectedCheckedTasks, selectedTaskFiles }) => {
  const classes = useStyles();
  const [selectedTaskId, setSelectedTaskId] = useState("");


  useEffect(() => {
    if (selectedCheckedTasks.length > 0) {
      setSelectedTaskId(selectedCheckedTasks[0]._id);
    }
  }, [selectedCheckedTasks]);

  // useEffect(() => {
  //   if (selectedCheckedTasks.length > 0 && !selectedTaskId) {
  //     setSelectedTaskId(selectedCheckedTasks[0]._id);
  //   } else if (selectedCheckedTasks.length === 0) {
  //     setSelectedTaskId("");
  //   }
  // }, [selectedCheckedTasks]);

  const handleTaskChange = (event) => {
    setSelectedTaskId(event.target.value);
  };

  const selectedTask = selectedCheckedTasks.find(task => task._id === selectedTaskId);

  return (
    <div className={classes.container}>
      <div className={classes.taskHeader}>
        {/* <Typography variant="h6" className={classes.taskTitle}>
          {selectedTask ? selectedTask.taskName.toUpperCase() : "Select tasks from task view"}
        </Typography> */}
         <Box component="span" sx={{ fontSize: selectedTask ? "21px" : "16px",
    fontWeight: selectedTask ? "600" : "400",
    color: selectedTask ? "#193B56" : "grey" }}>
            {selectedTask ? selectedTask.taskName.toUpperCase() : "Select tasks from View Tasks"}
        </Box>
        <FormControl className={classes.taskDropdown}>
          <Select
            value={selectedTaskId}
            onChange={handleTaskChange}
            displayEmpty
            renderValue={() => selectedTaskId ? selectedCheckedTasks.find(task => task._id === selectedTaskId)?.taskName : "Choose task"}
          >
            {selectedCheckedTasks.map((task) => (
              <MenuItem key={task._id} value={task._id}>
                {task.taskName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      
      {selectedTask && (
        <div className={classes.section}>
          <div className={classes.box}>
            <div className={classes.cont}>
              <div className={classes.cont}>
                <div className={classes.imgCont}>
                  <Slider
                    sx={{
                      height: "0.6em",
                      width: "35%",
                      color: "success.main",
                      "& .MuiSlider-thumb": {
                        width: "1.3rem",
                        height: "1.3rem",
                      },
                    }}
                    value={selectedTask.progress}
                    valueLabelDisplay="on"
                    min={0}
                    max={100}
                    disableSwap
                  />
                </div>
              </div>

              <div className={classes.imgCont}>
                {selectedTask.docs[0]?.files?.length > 0 ||
                selectedTask.progressHistory[0]?.docs[0]?.files?.length > 0 ? (
                  <>
                    {selectedTask.docs?.map((doc) => (
                      <React.Fragment key={doc._id}>
                        {doc?.files?.map((file) => (
                          <React.Fragment key={file._id}>
                            {selectedTaskFiles?.includes(file?._id) && (
                              <div>
                                <img
                                  className={classes.img}
                                  src={file?.url}
                                  alt={file?.name}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                    {selectedTask.progressHistory?.map(({ docs }, index) => (
                      <React.Fragment key={index}>
                        {docs?.map(({ files }) => (
                          <React.Fragment key={index}>
                            {files?.map((file) => (
                              <React.Fragment key={file._id}>
                                {selectedTaskFiles?.includes(file?._id) && (
                                  <div>
                                    <img
                                      className={classes.img}
                                      src={file?.url}
                                      alt={file?.name}
                                    />
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <span className={classes.notFoundText}>
                    No Documents found!
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default TaskAttachments;