import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core"
import { useDebounce } from 'react-use';
import { getOptionsForMuiAutocomplete } from './api.call';
import { makeStyles } from "@material-ui/core/styles";
import OfferingCreateDrawer from '../../OfferingsAndProducts/Offering.Create.Drawer';

const useStyles = makeStyles((theme) => ({
    noBorder: {
        border: "none"
    },
    defaultClass: {

    }
}))

const MuiSelect2 = ({
    selectOnFocus = false, clearOnBlur = false, handleHomeEndKeys = false, freeSolo = false, disableClearable = false,
    fullWidth = false, value, onChange, getOptionLabel, title, size, addonFilter, apiUrl, isNoBorder = false, placeholder,
    isAddnew = false, library, curProfileId, label, className, disabled=false
}) => {
    const classes = useStyles();

    const [textVal, setTextVal] = useState("")
    const [options, setOptions] = useState([])
    const [createOfferingDawer, setCreateOfferingDrawer] = useState(false)

    useDebounce(() => {
        getOptionsForMuiAutocomplete(apiUrl, { ...addonFilter, serachString: textVal })
            .then((data) => {
                console.log(data,{ ...addonFilter, serachString: textVal })
                let arr = []
                if (isAddnew) {
                    arr = [
                        {
                            _id: "addNew",
                            name: "+ Add New"
                        },
                        ...data
                    ]
                } else {
                    arr = [...data]
                }
                setOptions(arr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, 500, [textVal]);

    return (<>
        <Autocomplete
            id="free-solo-with-text-demo"
            selectOnFocus={selectOnFocus}
            clearOnBlur={clearOnBlur}
            handleHomeEndKeys={handleHomeEndKeys}
            freeSolo={freeSolo}
            disabled={disabled}
            disableClearable={disableClearable}
            fullWidth={fullWidth}
            options={options}
            value={value}
            onChange={(e, v) => {
                if (v?._id === "addNew") {
                    setCreateOfferingDrawer(true)
                } else {
                    onChange(e, v)
                }
            }}
            getOptionLabel={getOptionLabel}
            title={title}
            className={className ? className : classes.defaultClass}
            size={size}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    label={label}
                    variant="outlined"
                    value={textVal}
                    onChange={(e) => { setTextVal(e.target.value) }}
                    InputProps={{
                        ...params.InputProps,
                        classes: isNoBorder ? { notchedOutline: classes.noBorder } : {},
                    }}
                    size="small"
                />
            )}
        />

        <OfferingCreateDrawer
            purchase={true}
            selling={false}
            library={library}
            profileId={curProfileId}
            createOfferingDawer={createOfferingDawer}
            setCreateOfferingDrawer={setCreateOfferingDrawer}
            afterOfferingCreateOrEdit={(offeringRelation, isEdit) => {
                let restructureOffering = {
                    ...offeringRelation,
                    _id: offeringRelation?._id,
                    category: offeringRelation?.category?._id,
                    code: offeringRelation?.code,
                    codeLibrary: offeringRelation?.codeLibrary?._id
                }
                setOptions([...options, restructureOffering]);
                onChange("createNew", restructureOffering)
            }}
        />
    </>);
};

export default MuiSelect2;