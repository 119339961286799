import React, { useState, useEffect } from 'react';
import List from '../../expenseAndBills/bills/BillList/bill.list.edit';
import { processReceivedTx } from './hooks';
import { getLibraryAndCategoryByLibraryId, getOfferingsByLibrary } from '../../../OfferingsAndProducts/Api.call';


export default function TxBillListEdit({
  tx, setTx, showGL, updateApi, setLoadingBool, discounts, setDiscounts, taxes, setTaxes,
  chartAccounts, setChartAccounts, offerings, setOfferings, incomeChartAccounts,
  setIncomeChartAccounts, expenseChartAccounts, setExpenseChartAccounts, needToMakeDisable
}) {

  const [billList, setBillList] = useState(null);
  const [billItemDictionary, setBillItemDictionary] = useState({});
  const [libraryData, setLibraryData] = useState(null)
  const [categoriesData, setCategoriesData] = useState([])

  useEffect(() => {
    if (tx?.billList?._id) {
      const {
        billItemDictionary: newBillItemDict,
        billList: newBillList,
      } = processReceivedTx(tx);

      setBillItemDictionary(newBillItemDict);
      setBillList(newBillList);
    }
  }, [tx?._id]);

  useEffect(() => {
    if (!needToMakeDisable) {
      setTx({
        ...tx,
        billList: {
          ...billList,
          items: billList?.items.map(itemId => billItemDictionary[itemId])
        }
      })
    }
  }, [billList, billItemDictionary, needToMakeDisable])

  useEffect(() => {
    if (tx?.firstPartyWallet?.defaultLibrary && tx?.firstParty?._id) {
      getOfferingsByLibrary({
        libraryId: tx?.firstPartyWallet?.defaultLibrary,
        profileId: tx?.firstParty?._id,
        type: tx?.type === "Bill" ? "buy" : "sell"
      })
        .then((data) => {
          const addObject = {
            _id: 'New',
            name: "+ Add New",
            numDays: "0"
          };
          setOfferings([addObject, ...data])
        })
        .catch((err) => {
          console.log(err)
        })

      getLibraryAndCategoryByLibraryId({ libraryId: tx?.firstPartyWallet?.defaultLibrary })
        .then((data) => {
          setLibraryData(data?.libraryData)
          setCategoriesData(data?.allCatgories || [])
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [tx?.firstPartyWallet?.defaultLibrary, tx?.firstParty?._id])


  return (
    <>
      {billList?._id && (
        <List
          tx={tx}
          setTx={setTx}
          updateApi={updateApi}
          billList={billList}
          setBillList={setBillList}
          billItemDictionary={billItemDictionary}
          setBillItemDictionary={setBillItemDictionary}
          showGL={showGL}
          setLoadingBool={setLoadingBool}
          discounts={discounts}
          setDiscounts={setDiscounts}
          taxes={taxes}
          incomeChartAccounts={incomeChartAccounts}
          setIncomeChartAccounts={setIncomeChartAccounts}
          expenseChartAccounts={expenseChartAccounts}
          setExpenseChartAccounts={setExpenseChartAccounts}
          setTaxes={setTaxes}
          chartAccounts={chartAccounts}
          setChartAccounts={setChartAccounts}
          offerings={offerings}
          needToMakeDisable={needToMakeDisable}
          setOfferings={setOfferings}
          libraryData={libraryData}
          categoriesData={categoriesData}
          setCategoriesData={setCategoriesData}
        />
      )}
    </>
  );
}