import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 200px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  mainImageCont: {
    width: "100%",
    height: "calc(100% - 130px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  thumImages: {
    width: "100%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    overflowY: "hidden",
    overflowX: "auto",
  },
  singleThumCont: {
    width: "140px",
    minWidth: "160px",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
    marginRight: "10px",
    cursor: "pointer",
  },
  titleCont: {
    position: "absolute",
    top: "0px",
    left: "0px",
    color: "black",
    fontSize: "17px",
    fontWeight: "510",
    padding: "5px 15px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    boxShadow: "2px 2px 12px 12px rgba(255, 255, 255, 0.5)",
    [theme.breakpoints.down('xs')]: {
      fontSize: "13px",
      fontWeight: "500"
    }
  },
  previousClick: {
    width: "30%",
    height: "calc(100% - 50px)",
    position: "absolute",
    left: "0px",
    bottom: "0px",
    cursor: "pointer",
  },
  nextClick: {
    width: "30%",
    height: "calc(100% - 50px)",
    position: "absolute",
    right: "0px",
    bottom: "0px",
    cursor: "pointer"
  },
  deleteBtn: {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "white",
    backgroundColor: "#EE1D52",
    "&:hover": {
      backgroundColor: "#d20f41"
    },
    "& .MuiButton-label": {
      textTransform: "capitalize"
    },
    [theme.breakpoints.down('xs')]: {
      padding: "2px 8px",
      "& .MuiButton-label": {
        textTransform: "capitalize",
        fontSize: "13px"
      }
    }
  }
}));

export default function FilesViewer({ imageTitleArr, deleteFile }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const imageRef = useRef();
  const imageContRef = useRef();

  const [curFileIndex, setCurFileIndex] = useState(0)
  const [imageClass, setImageClass] = useState({
    maxWidth: "100%",
    maxHeight: "100%",
  })

  useEffect(() => {
    if ((imageTitleArr.length - 1) < curFileIndex) {
      setCurFileIndex(0)
    }

    setImageClass({
      maxWidth: "100%",
      maxHeight: "100%",
    })
  }, [imageTitleArr])

  useEffect(() => {
    //original image dimension
    let imageHeight = imageRef.current.clientHeight;
    let imageWidth = imageRef.current.clientWidth;

    //container dimension
    let elementImageHeight = imageContRef.current.clientHeight;
    let elementImageWidth = imageContRef.current.clientWidth;

    //width full
    let ImgWidthFullW = elementImageWidth;
    let ImgHeightFullW = (elementImageWidth * imageHeight) / imageWidth

    //height full
    let ImgHeightFullH = elementImageHeight;
    let ImgWidthFullH = (elementImageHeight * imageWidth) / imageHeight


    if (imageHeight > imageWidth) {
      // height main
      if (ImgHeightFullH <= elementImageHeight && ImgWidthFullH <= elementImageWidth) {
        setImageClass({
          height: "100%",
          width: "auto",
        })
      } else if (ImgWidthFullH > elementImageWidth) {
        let locImageWidth = elementImageWidth; // will reduce width of the image
        let locImageHeight = (locImageWidth * imageHeight) / imageWidth
        let widthPercent = 100;

        while (elementImageHeight < locImageHeight) {
          widthPercent = widthPercent - 1;
          locImageWidth = locImageWidth * (widthPercent / 100);
          locImageHeight = (locImageWidth * imageHeight) / imageWidth
        }

        setImageClass({
          width: `${widthPercent}%`,
          height: "auto",
        })
      } else {
        setImageClass({
          maxWidth: "100%",
          maxHeight: "100%",
        })
      }

    } else {
      // width main
      if (ImgWidthFullW <= elementImageWidth && ImgHeightFullW <= elementImageHeight) {
        setImageClass({
          width: "100%",
          height: "auto",
        })
      } else if (ImgHeightFullW > elementImageHeight) {

        let locImageHeight = elementImageHeight; // will reduce image height
        let locImageWidth = (locImageHeight * imageWidth) / imageHeight
        let heightPercent = 100;

        while (elementImageWidth < locImageWidth) {
          heightPercent = heightPercent - 1;
          locImageHeight = locImageHeight * (heightPercent / 100);
          locImageWidth = (locImageHeight * imageWidth) / imageHeight
        }

        setImageClass({
          height: `${heightPercent}%`,
          width: "auto",
        })

      } else {
        setImageClass({
          maxWidth: "100%",
          maxHeight: "100%",
        })
      }
    }

  }, [curFileIndex, imageTitleArr[curFileIndex]])

  return (
    <div className={classes.root}>
      <div ref={imageContRef} className={classes.mainImageCont} >
        <div className={classes.titleCont} >
          {imageTitleArr[curFileIndex]?.title}
        </div>
        <Button
          className={classes.deleteBtn}
          onClick={() => { deleteFile(imageTitleArr[curFileIndex]) }}
        >
          Delete Attachment
        </Button>


        <div
          className={classes.previousClick}
          onClick={() => {
            if (imageTitleArr.length > 1) {
              if (curFileIndex === 0) {
                setCurFileIndex(imageTitleArr.length - 1)
              } else {
                setCurFileIndex(curFileIndex - 1)
              }
            }
          }}
        ></div>
        <div
          className={classes.nextClick}
          onClick={() => {
            if (imageTitleArr.length > 1) {
              if (curFileIndex === imageTitleArr.length - 1) {
                setCurFileIndex(0)
              } else {
                setCurFileIndex(curFileIndex + 1)
              }
            }
          }}
        ></div>



        <img
          ref={imageRef}
          src={imageTitleArr[curFileIndex]?.fileUrl}
          alt={imageTitleArr[curFileIndex]?.title}
          style={imageClass}
        />
      </div>
      <div className={classes.thumImages} >
        {imageTitleArr.map((imageData, i) => (
          <div
            key={i}
            className={classes.singleThumCont}
            onClick={() => { setCurFileIndex(i) }}
            style={i === curFileIndex ? { border: `4px solid ${theme.palette.primary.main}`, backgroundImage: `url(${imageData?.fileUrl})` } : { backgroundImage: `url(${imageData?.fileUrl})` }}
          >
          </div>
        ))}
      </div>
    </div>
  );
}