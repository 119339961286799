import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDialog from '../CommonComponents/NormalDialog';


export default function AddDialog({ open, setOpen, loading, form }) {

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => { setOpen(false) }}
      pageTitle={"Add New"}
      content={<div>
        {form}
        <div style={{ width: "100%", height: "4px" }} >
          {loading && <LinearProgress />}
        </div>
      </div>
      }
    />
  );
}