import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getCommentsByParent } from "./api.call";
import MessageAndImgCont from "./MessageAndImgCont";
import ApprovalStageDetails from "./Approval.Stage.Details";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
  selfMessageCont: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
    padding: "0px 15px",
  },
  othersMessageCont: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: "10px",
    padding: "0px 15px",
  },
  mainMessageContSelf: {
    width: "calc(100% - 100px)",
    display: "flex",
    justifyContent: "flex-end",
  },
  mainMessageContOther: {
    width: "calc(100% - 135px)",
    // width: "15rem",
    // height: " 10rem",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "3px",
  },
}));

export default function DisscusionPagination({
  openApprovalFlow,
  parent,
  parentModelName,
  activeStage,
  currPage,
  setCurrPage,
  changePageState,
  setChangePageState,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const observer = useRef();

  const { user } = useSelector((state) => state.auth);

  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMoreItems, sethasMoreItems] = useState(true);

  const reloadChat = async () => {
    setLoading(true);
    await getCommentsByParent({
      parent: parent,
      model: parentModelName,
      limit: 15,
      page: currPage,
    })
      .then(async (data) => {
        if (currPage === 0) {
          if (data && data.length > 0) {
            setComments(data);
          } else {
            setComments([]);
          }
        } else {
          setComments((prev) => [...prev, ...data]);
        }
        if (data.length < 15) {
          sethasMoreItems(false);
        } else {
          sethasMoreItems(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (openApprovalFlow) {
      setCurrPage(0);
      setChangePageState(!changePageState);
    }
  }, [openApprovalFlow]);

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreItems) {
          setCurrPage((prev) => {
            return prev + 1;
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMoreItems]
  );

  useDebounce(
    () => {
      if (openApprovalFlow) {
        reloadChat();
      }
    },
    1000,
    [currPage, changePageState]
  );

  return (
    <>
      {loading && comments.length === 0 ? null : (
        <div>
          {comments && comments.length > 0 ? (
            comments.map((commentData, index) => {
              let lastItem = comments.length - 2;
              let StageStampComp = null;
              if (
                commentData?.action &&
                (index === 0 ||
                  comments[index - 1]?.action != commentData?.action)
              ) {
                if (index === 0 && commentData?.action != activeStage?._id) {
                  StageStampComp = (
                    <>
                      <ApprovalStageDetails stageId={activeStage?._id} />
                      <ApprovalStageDetails stageId={commentData?.action} />
                    </>
                  );
                } else {
                  StageStampComp = (
                    <>
                      <ApprovalStageDetails stageId={commentData?.action} />
                    </>
                  );
                }
              }
              if (commentData?.user?._id == user?._id) {
                return (
                  <>
                    {StageStampComp}
                    <div
                      className={classes.selfMessageCont}
                      ref={lastItem === index ? lastElementRef : null}
                    >
                      <div className={classes.mainMessageContSelf}>
                        <MessageAndImgCont
                          commentData={commentData}
                          isOwn={true}
                        />
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    {StageStampComp}
                    <div
                      className={classes.othersMessageCont}
                      ref={lastItem === index ? lastElementRef : null}
                    >
                      <Avatar
                        src={commentData?.user?.displayPicture?.url}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginBottom: "1px",
                        }}
                      />
                      <div className={classes.mainMessageContOther}>
                        <MessageAndImgCont
                          commentData={commentData}
                          isOwn={false}
                        />
                      </div>
                    </div>
                  </>
                );
              }
            })
          ) : (
            <ApprovalStageDetails stageId={activeStage?._id} />
          )}
        </div>
      )}
      {loading && <p style={{ textAlign: "center" }}>Loading...</p>}
    </>
  );
}
