import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TextField, InputAdornment } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useParams, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import {
  searchEntities,
  getInitialEntities,
  addNewFinancialRelation,
} from "../../contacts/api.call";
import AddIcon from "@material-ui/icons/Add";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import PalForm from "../../AddMember/newPalForm";
import emptyIcon from "../../../Assets/EmptyInboxOutline.svg";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      width: "75vw",
    },
  },
  inputPaper: {
    width: "100%",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  roleAutoSty: {
    width: "130px",
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "85px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
  },
  textFieldSty: {
    width: "100%",
    backgroundColor: "white",
  },
  noBorder: {
    border: "none",
  },
  resultBody: {
    width: "100%",
    height: "250px",
    overflowY: "auto",
    margin: "20px 0px 10px",
  },
  btnCont: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  itemCont: {
    width: "100%",
    padding: "7px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    cursor: "pointer",
    "& h3": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "gray",
    },
    "& h4": {
      backgroundColor: "gray",
      color: "white",
      fontSize: "10px",
      fontWeight: "350",
      padding: "0px 5px",
      borderRadius: "3px",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  emptyCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
    },
  },
}));

export default function AddContactDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const {
    notAllowedUserIds,
    notAllowedOrgIds,
    notAllowedProjectIds,
    notAllowedProfileIds,
    setNotAllowedUserIds,
    setNotAllowedOrgIds,
    setNotAllowedProjectIds,
    setNotAllowedProfileIds,
    openAddDialog,
    setOpenAddDialog,
    wallet,
    parent,
    profileId,
    parentModelName,
    type,
    setFilteredContactsArr,
    contactsArr,
    setContactsArr,
  } = props;

  const [text, setText] = useState("");
  const [initialEntities, setInitialEntities] = useState([]);
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [entityLoader, setEntityLoader] = useState(false);
  const [openNewPal, setOpenNewPal] = useState(false);
  const [relationCreateLoader, setRelationCreateLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEntityLoader(true);
    getInitialEntities({
      notAllowedUserIds: notAllowedUserIds,
      notAllowedOrgIds: notAllowedOrgIds,
    })
      .then((data) => {
        setEntities(data);
        setInitialEntities(data);
        setEntityLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (entities.length === 0) {
      setEntities(initialEntities);
    }
  }, [initialEntities, entities]);

  const sendQuery = async () => {
    setEntityLoader(true);
    await searchEntities({
      name: text,
      notAllowedUserIds,
      notAllowedOrgIds,
      notAllowedProjectIds,
    })
      .then((users) => {
        setEntities(users);
        setEntityLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(
    () => {
      if (text && text.length > 2) {
        sendQuery();
      } else if (text && text.length === 0) {
        setEntities([]);
      }
    },
    1200,
    [text]
  );

  const submitToAdd = async (selectedContact) => {
    setRelationCreateLoader(true);


    //if (type && wallet && selectedContact?._id && user?.profile && user?._id && parent && profileId && parentModelName) {
    if (true) {
      await addNewFinancialRelation({
        type: type,
        wallet: wallet,
        otherProfile: selectedContact?._id,
        userProfileId: user?.profile,
        userId: user?._id,
        parent: parent,
        mainProfile: profileId,
        parentModelName: parentModelName,
      })
        .then((contactData) => {
          let updatedArr = [contactData, ...contactsArr];
          let locNotAllowedUserIds = [...notAllowedUserIds];
          let locNotAllowedOrgIds = [...notAllowedOrgIds];
          let locNotAllowedProjectIds = [...notAllowedProjectIds];
          let locNotAllowedProfileIds = [...notAllowedProfileIds];

          locNotAllowedProfileIds.push(contactData?.profile?._id);

          if (
            contactData?.profile?.parentModelName === "Project" ||
            (contactData?.profile?.parentModelName === "Pal" &&
              contactData?.profile?.parent?.type === "Project")
          ) {
            locNotAllowedProjectIds.push(
              contactData?.profile?.parentModelName === "Pal"
                ? contactData?.profile?.parent?.parent?._id
                : contactData?.profile?.parent?._id
            );
          } else if (
            contactData?.profile?.parentModelName === "Organization" ||
            (contactData?.profile?.parentModelName === "Pal" &&
              contactData?.profile?.parent?.type === "Organization")
          ) {
            locNotAllowedOrgIds.push(
              contactData?.profile?.parentModelName === "Pal"
                ? contactData?.profile?.parent?.parent?._id
                : contactData?.profile?.parent?._id
            );
          } else if (
            contactData?.profile?.parentModelName === "User" ||
            (contactData?.profile?.parentModelName === "Pal" &&
              contactData?.profile?.parent?.type === "User")
          ) {
            locNotAllowedUserIds.push(
              contactData?.profile?.parentModelName === "Pal"
                ? contactData?.profile?.parent?.parent?._id
                : contactData?.profile?.parent?._id
            );
          }

          setContactsArr(updatedArr);
          setFilteredContactsArr(updatedArr);
          setText("");
          setNotAllowedUserIds(locNotAllowedUserIds);
          setNotAllowedOrgIds(locNotAllowedOrgIds);
          setNotAllowedProjectIds(locNotAllowedProjectIds);
          setNotAllowedProfileIds(locNotAllowedProfileIds);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setOpenAddDialog(false);
    setRelationCreateLoader(false);
  };

  const newOnSelect = async (newPalData) => {
    setOpenNewPal(false);
    if (newPalData) {
      submitToAdd(newPalData?.profile);
    }
  };

  return (
    <NormalDialog
      openDialog={openAddDialog}
      handleCloseShare={() => {
        setOpenAddDialog(false);
      }}
      pageTitle={`Add New ${type}`}
      content={
        <div className={classes.dialogBody}>
          <Paper className={classes.inputPaper}>
            <TextField
              placeholder="Search user/project/organization"
              variant="outlined"
              size="small"
              className={classes.textFieldSty}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpenNewPal(true);
                      }}
                    >
                      Add Pal
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <NormalDialog
              openDialog={openNewPal}
              handleCloseShare={() => {
                setOpenNewPal(false);
              }}
              pageTitle={`Add New Contact(Pal)`}
              content={
                <>
                  <div style={{ height: "5px" }}>
                    {loading && <LinearProgress />}
                  </div>
                  <PalForm
                    text={text}
                    onSelect={newOnSelect}
                    setLoading={setLoading}
                  />
                </>
              }
            />
          </Paper>
          <div className={classes.resultBody}>
            {entityLoader ? (
              <div className={classes.emptyCont}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {entities && entities.length > 0 ? (
                  entities.map((option, i) => {
                    if (option && !notAllowedProfileIds.includes(option._id)) {
                      return (
                        <div
                          className={classes.itemCont}
                          onClick={() => {
                            setSelectedEntity(option);
                          }}
                          key={i}
                          style={
                            selectedEntity?._id === option?._id
                              ? { backgroundColor: "#d0eeff" }
                              : { backgroundColor: "white" }
                          }
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={
                                option?.parent?.displayPicture?.thumbUrl
                                  ? option?.parent?.displayPicture?.thumbUrl
                                  : option?.parent?.displayPicture?.url
                              }
                              alt={option?.parent?.displayName}
                              style={{ width: "35px", height: "35px" }}
                            />
                            <div style={{ marginLeft: "7px" }}>
                              <h3>{option?.parent?.displayName}</h3>
                              <p>@{option?.parent?.username}</p>
                            </div>
                          </div>
                          <h4>
                            {option?.parent?.model == "Pal"
                              ? "Connection"
                              : option?.parentModelName}
                          </h4>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className={classes.emptyCont}>
                    <img src={emptyIcon} />
                    <p>No contact is available.</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div style={{ width: "100%", height: "5px" }}>
            {relationCreateLoader && <LinearProgress />}
          </div>
          <div className={classes.btnCont}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              // disabled={type && wallet && selectedEntity?._id && user?.profile && user?._id && parent && profileId && parentModelName ? false : true}
              onClick={() => {
                submitToAdd(selectedEntity);
              }}
            >
              Add
            </Button>
          </div>
        </div>
      }
    />
  );
}
