const { default: Api } = require('../../../../helpers/Api');

export const getFullTemplateInitalData = async (obj) => {
    try {
        const billRes = await Api.post('txtemplate/get/populated/all-data', obj);
        return billRes;
    }
    catch (error) {
        console.log(error)
    }
};