import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState , useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AvatarGroup,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import dayjs from "dayjs";
import { getProgressColor } from "../../../helpers/colorHelper";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import SelectTaskDocsDrawer from "./SelectTaskDocsDrawer";
import Api from "../../../helpers/Api";
import SpaceBetween from "../../styled/generic/SpaceBetween";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles((theme) => ({
  textExpand: {
    fontSize: "0.9rem",
    color: "#0B79D1",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  checkAndExpandContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  secUp: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "0.5rem",
  },
  selectDocsBtn: {
    fontSize: "0.9rem",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    color: "#0B79D1",
  },
 
  
}));

export default function InvoiceTaskCard({
  task,
  setSelectedCheckedTasks,
  selectedCheckedTasks,
  txId,
  setSelectedTaskFiles,
  selectedTaskFiles,
  fullTaskView = false,
  parentModel,
}) {
  console.log(task);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  // const taskId = task?._id;
  const date = new Date(task?.startDate);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  const selectTask = useCallback(async (event) => {
    // if (!taskId) return; 
    const isChecked = event.target.checked;
    
    try {
      if (isChecked) {
        setSelectedCheckedTasks((prevVal) => [...prevVal, task]);
      } else {
        setSelectedCheckedTasks((prevVal) =>
          prevVal?.filter(({ _id }) => _id !== task?._id)
        );
      }

      const response = await Api.post("/transaction/remove/task", {
        taskId: task?._id,
        txId: txId,
        parentModel: parentModel,
        isTaskSelected: isChecked
      });

      // If the task is deselected, also clear its files from selectedTaskFiles
      if (!isChecked) {
        setSelectedTaskFiles(prevFiles => 
          prevFiles.filter(fileId => !task.docs.some(doc => 
            doc.files.some(file => file._id === fileId)
          ))
        );
      }

      console.log("Task selection updated:", response.data);
      // You might want to update the UI or refetch data here based on the response
    } catch (error) {
      console.error("Error updating task selection:", error);
      // Handle error (e.g., show an error message to the user)
    }
  }, [task, txId, parentModel, setSelectedCheckedTasks, setSelectedTaskFiles]);
  return (
    <Card sx={{ width: "300px"}}>
      <SelectTaskDocsDrawer
        setSelectedTaskFiles={setSelectedTaskFiles}
        selectedTaskFiles={selectedTaskFiles}
        open={open}
        parentId={txId}
        parentModel = {parentModel}
        setOpen={setOpen}
        task={task}
      />
      <CardContent
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          
        }}
      >
        
        <div className={classes.secUp}>
          <span>
            <Avatar
              alt={task?.creator?.parent?.displayName}
              src={task?.creator?.parent?.displayPicture?.url}
              aria-label="recipe"
            />
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <span>{task?.creator?.parent?.displayName}</span>
            <span>{formattedDate}</span>
          </div>
          </div>
        

        <div>
          <FormControlLabel
            label="Select"
            control={
              <Checkbox
                checked={
                  selectedCheckedTasks?.find(({ _id }) => _id === task?._id)
                    ? true
                    : false
                }
                onChange={selectTask}
              />
            }
            // onClick={(e) => selectTask(e)}
            //checked={selectedCheckedTasks?.find(({ _id }) => _id === task?._id)}
          />
          </div>
        

      </CardContent>
      {selectedCheckedTasks?.find(({ _id }) => _id === task?._id)?._id && (
        <CardContent>
          <button
            onClick={() => setOpen(true)}
            className={classes.selectDocsBtn}
          >
            Select documents
          </button>
        </CardContent>
      )}
      <CardContent>
        <div className={classes.taskbg}>
          {!fullTaskView ? (
            <>
              {String(task?.taskName).toUpperCase() ? (
                String(task?.taskName).toUpperCase().length > 80 ? (
                  <>{String(task?.taskName).toUpperCase().slice(0, 80)}.....</>
                ) : (
                  String(task?.taskName).toUpperCase()
                )
              ) : (
                "Untitled"
              )}
            </>
          ) : String(task?.taskName).toUpperCase() ? (
            String(task?.taskName).toUpperCase()
          ) : (
            "Untitled"
          )}
        </div>
      </CardContent>
    </Card>
  );
}
