import React, { useEffect, useState } from "react";
import { Checkbox, Drawer, FormControlLabel } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Api from "../../../helpers/Api";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "50vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  drawerSecUp: {
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  drawerSecDown: {
    marginTop: "2rem",
    width: "95%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "1rem",
  },
  img: {
    borderRadius: "0.5rem",

    height: "6.8rem",
    width: "100%",
  },

  bigText: {
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
  imgContainer: {
    width: "15rem",
    height: "12rem",
    border: "2px solid gray",
    borderRadius: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  imgSection: {
    width: "90%",
    height: "95%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
const SelectTaskDocsDrawer = ({
  // open,
  // setOpen,
  // task,
  // setSelectedTaskFiles,
  // selectedTaskFiles,
  // txId,
  open,
  setOpen,
  task,
  setSelectedTaskFiles,
  selectedTaskFiles,
  parentId,
  parentModel,
}) => {
  const classes = useStyles();
  const [reRander, setRerander] = useState(false);
  console.log("The task id:" , task?._id);
  
 
      console.log("ParentModel", parentModel);
  useEffect(() => {
    // Reset selected files when task changes
    setSelectedTaskFiles([]);
  }, [task, setSelectedTaskFiles]);

  const selectTaskFile = async (event, fileId) => {
    console.log("selectTaskFile called:", { checked: event.target.checked, fileId });
    
    try {
      setRerander(false);
      
      const isChecked = event.target.checked;
      let updatedTaskFiles = [...selectedTaskFiles];
      
      if (isChecked) {
        updatedTaskFiles.push(fileId);
      } else {
        updatedTaskFiles = updatedTaskFiles.filter(id => id !== fileId);
      }
      
      setSelectedTaskFiles(updatedTaskFiles);
      
      const payload = {
        taskId: task._id,
        files: updatedTaskFiles,
        newSelectedFile: isChecked ? fileId : '',
        removeFile: isChecked ? '' : fileId,
        parentId: parentId,
        parentModel: parentModel,
        isTaskSelected: true
      };
      
      console.log("Payload being sent to API:", payload);
      
      const response = await Api.post("file/update/task/transaction", payload);
      console.log("API response:", response);
      
      setRerander(true);
    } catch (error) {
      console.error("Error in selectTaskFile:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };


  useEffect(() => {}, [reRander]);

  return (
    <Drawer anchor={"right"} open={open}>
      <div className={classes.container}>
        <div className={classes.drawerSecUp}>
          <span className={classes.bigText}>Tasks's documents</span>
          <span onClick={() => setOpen(false)}>
            <IconButton aria-label="close">
              <CloseIcon />
            </IconButton>
          </span>
        </div>

        <div className={classes.drawerSecDown}>
          {task?.docs?.map((doc) => (
            <>
              {doc?.files?.map((file) => {
                console.log("Rendering file with id:", file?._id);
                return (
                
                <div key={file?._id} className={classes.imgContainer}>
                  <div className={classes.imgSection}>
                    <div>
                      <FormControlLabel
                        onChange={ (e) => selectTaskFile(e, file?._id)}
                        label="Select"
                        control={
                          <Checkbox
                            checked={
                              selectedTaskFiles?.includes(file?._id)
                                ? true
                                : false
                            }
                          />
                        }
                      />
                    </div>
                    <div>
                      {" "}
                      <img
                        className={classes.img}
                        src={file?.url}
                        alt={file?.name}
                      />
                    </div>
                  </div>
                </div>
              )})}
            </>
          ))}
          {task?.progressHistory?.map(({ docs }) => (
            <>
              {docs?.map(({ files }) => (
                <>
                  {files?.map((file) => (
                    <div key={file?._id} className={classes.imgContainer}>
                      <div className={classes.imgSection}>
                        <div>
                          <FormControlLabel
                            label="Select"
                            onChange={(e) => selectTaskFile(e, file?._id)}
                            control={
                              <Checkbox
                                checked={
                                  selectedTaskFiles?.includes(file?._id)
                                    ? true
                                    : false
                                }
                              />
                            }
                          />
                        </div>
                        <div>
                          <img
                            className={classes.img}
                            src={file?.url || file?.name}
                            alt={file?.name}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}
            </>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default SelectTaskDocsDrawer;

