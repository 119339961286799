import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { Button, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import DialogBillNo from "../../bills/DialogBillNo";
import { useSelector } from "react-redux";
import { getPopulatedTxTemplate, updateTxTemplate } from "../../../commonComponent/transaction/api";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SchedulerForm from "../../../../scheduler/SchedulerForm";
import { getIncomeChartAccounts } from "../../../productsAndServices/utils";
import ChartAccountCreateForBank from "../../../salesAndInvoices/ChartAccountCreateForBank";
import Api from "../../../../../helpers/Api";
import NormalDialog from "../../../../styled/CommonComponents/NormalDialog";
import { allStatusOptions } from "../../../../../helpers/allStatuses";
import MyPopOver from "../../../../styled/CommonComponents/MyPopOver";
import { MdOutlineForkRight } from "react-icons/md";
import LockIcon from '@material-ui/icons/Lock';
import { ArrowDropDown } from "@material-ui/icons";
import { getTx } from "../../../commonComponent/payBackTx/api"
import ApprovalActionDrawer from "../../../../approval/ApprovalActionDrawer";
import ApprovalAndActions from "../../../../approval/ApprovalAndActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px"
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
    }
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px",
    }
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
  invoiceEditBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 25px 100px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px 10px 50px",
    }
  },
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    marginTop: "50px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56"
    },
    [theme.breakpoints.down('sm')]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    }
  },
  autocompleteSty: {
    width: "400px",
    margin: "10px 0px 15px",
    [theme.breakpoints.down('xs')]: {
      width: "80%",
    }
  }
}));


export default function BillTemplatePaymentSetting(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const DateNow = new Date();

  const {
    txTemplate, setTxTemplate, walletId, openPayment, setOpenPayment,
    setLoadingBool, templateNosArr, setTemplateNosArr, maxNo,
    setMaxNo, bankChartAccounts, setBankChartAccounts, needToMakeDisable,
    activeStage, loadingBool
  } = props;

  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [scheduler, setScheduler] = useState(txTemplate?.schedulingData || {});
  const [selectedBankChartAccount, setSelectedBankChartAccount] = useState(txTemplate?.BankChartAccount || null);
  const [openChartAcc, setOpenChartAcc] = useState(false);
  const [openErr, setOpenErr] = useState(false);
  const [submitionFailMsg, setSubmitionFailMsg] = useState("");
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [actionStatus, setActionStatus] = useState(null)

  useEffect(() => {
    async function joinScheduler() {
      if (scheduler?._id && !needToMakeDisable) {
        const txTemplateRes = await Api.post("txtemplate/update", {
          ...txTemplate,
          schedulingData: scheduler?._id,
        });

        if (txTemplateRes) {
          setTxTemplate(txTemplateRes?.data);
        }
      }
    }

    joinScheduler();
  }, [scheduler?._id]);

  useEffect(() => {
    if (typeof scheduler === "object" && !Array.isArray(scheduler) && !needToMakeDisable) {
      setTxTemplate({
        ...txTemplate,
        schedulingData: { ...scheduler },
      });
    }
  }, [scheduler]);

  const update = async (obj, isBankChartUpdate = false) => {
    if (!needToMakeDisable) {
      setLoadingBool(true);
      if (isBankChartUpdate) {
        const newObj = {
          _id: obj?._id,
          BankChartAccount: obj?.BankChartAccount?._id,
        };
        await updateTxTemplate(newObj);
        setTxTemplate({
          ...txTemplate,
          BankChartAccount: obj?.BankChartAccount,
        });
      } else {
        await updateTxTemplate(obj);
        setTxTemplate({
          ...txTemplate,
          ...obj,
        });
      }
      setLoadingBool(false);
    }
  };

  const onSelectChartAccount = async (value) => {
    if (!needToMakeDisable) {
      setSelectedBankChartAccount(value);
      await update(
        {
          _id: txTemplate?._id,
          BankChartAccount: value,
        },
        true
      );
    }
  };

  const chartAccountCreateDialog = async (value) => {
    setOpenChartAcc(value);
    const type = { classification: "Bank", wallet: walletId };
    getIncomeChartAccounts({ type })
      .then(async (accounts) => {
        const addObject = {
          _id: "New",
          name: "+ Add New",
          numDays: "0",
        };

        const newFiltered = [addObject, ...accounts];
        const len = accounts?.length;
        setBankChartAccounts(newFiltered);
        setSelectedBankChartAccount(accounts[len - 1]);

        await update(
          {
            _id: txTemplate?._id,
            BankChartAccount: accounts[len - 1],
          },
          true
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const turnOnGenerator = async () => {
    if (!needToMakeDisable) {
      setLoadingBool(true);
      let taxItemBool = false;
      let isItemMissChart = false;
      let items = txTemplate?.billList?.items;
      if (!txTemplate?.secondParty || !txTemplate?.secondPartyWallet) {
        setSubmitionFailMsg("Customer is missing! Please select a customer");
        setOpenErr(true);
        return false;
      }
      items.length > 0 && items.map((item) => {
        if (item?.tax) {
          taxItemBool = true;
        }
        if (!item?.chartAccount?._id) {
          isItemMissChart = true;
        }
      });
      if (isItemMissChart) {
        setSubmitionFailMsg("item Chart Account is missing! Please select category in item carefully");
        setOpenErr(true);
        return false;
      }
      if (txTemplate?.billList?.tax?.enabled && taxItemBool && !txTemplate?.billList?.taxRelationModel?.chartAccount?._id) {
        setSubmitionFailMsg("Tax Chart Account is missing! Please select a tax");
        setOpenErr(true);
        return false;
      }
      if (txTemplate?.billList?.discount?.enabled && !txTemplate?.billList?.discountRelationModel?.chartAccount?._id) {
        setSubmitionFailMsg("Discount Chart Account is missing! Please select a discount")
        setOpenErr(true);
        return false;
      }
      setLoadingBool(false);
      return true;
    }
  };

  const updateStatus = (statusVal) => {
    if (!needToMakeDisable) {
      let taxItemBool = false;
      let isItemMissChart = false;
      let items = txTemplate?.billList?.items;
      if (!txTemplate?.secondParty || !txTemplate?.secondPartyWallet) {
        setSubmitionFailMsg("Customer is missing! Please select a customer");
        setOpenErr(true);
        return false;
      }
      items.length > 0 && items.map((item) => {
        if (item?.tax) {
          taxItemBool = true;
        }
        if (!item?.chartAccount?._id) {
          isItemMissChart = true;
        }
      });
      if (isItemMissChart) {
        setSubmitionFailMsg("item Chart Account is missing! Please select category in item carefully");
        setOpenErr(true);
        return false;
      }
      if (txTemplate?.billList?.tax?.enabled && taxItemBool && !txTemplate?.billList?.taxRelationModel?.chartAccount?._id) {
        setSubmitionFailMsg("Tax Chart Account is missing! Please select a tax");
        setOpenErr(true);
        return false;
      }
      if (txTemplate?.billList?.discount?.enabled && !txTemplate?.billList?.discountRelationModel?.chartAccount?._id) {
        setSubmitionFailMsg("Discount Chart Account is missing! Please select a discount")
        setOpenErr(true);
        return false;
      }
      setActionStatus(statusVal)
      setOpenAction(true)
    }
  }

  const getFreshData = async () => {
    await getPopulatedTxTemplate({ txTemplateId: txTemplate?._id })
      .then((data) => {
        console.log(data)
        setTxTemplate(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={classes.root} >
      <div className={classes.topBar} >
        <div className={classes.barLeft} >
          <IconButton
            onClick={() => { setOpenPayment(false) }}
          >
            <KeyboardBackspaceIcon
              className={classes.backArrow}
            />
          </IconButton>
          <Typography className={classes.headerTitle} >
            Bill Generator
            <span style={{ marginLeft: "10px" }} >#{txTemplate?.txNo}</span>
          </Typography>
          {!needToMakeDisable && (<>
            <IconButton
              color="primary"
              onClick={() => { setOpen(true) }}
            >
              <EditIcon
                className={classes.editIconSty}
              />
            </IconButton>
            <DialogBillNo
              open={open}
              handleClose={() => { setOpen(false); }}
              txNos={templateNosArr}
              tx={txTemplate}
              setTx={setTxTemplate}
              type={"BillTemplate"}
              maxNo={maxNo}
              setMaxNo={setMaxNo}
              walletId={walletId}
              setLoadingBool={setLoadingBool}
            />
          </>)}
        </div>

        <div className={classes.barRight} >
          <Button
            id="demo-customized-button"
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => { setOpenApprovalFlow(true) }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loadingBool}
            setLoadingBool={setLoadingBool}
            parent={txTemplate?._id}
            parentModelName={"TxTemplate"}
            requestId={txTemplate?.approvalRequest?._id}
            needToMakeDisable={needToMakeDisable}
            activeStage={activeStage}
          />
          <div style={{ width: "10px" }}></div>
          {needToMakeDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {txTemplate?.status ? txTemplate?.status : "STATUS"}
            </Button>
          ) : (<>
            <MyPopOver
              closeOnClick={true}
              appearContent={
                <Button
                  id="demo-customized-button"
                  variant="outlined"
                  size="small"
                  color="primary"
                  endIcon={<ArrowDropDown />}
                >
                  {txTemplate?.status ? txTemplate?.status : "STATUS"}
                </Button>
              }
              showContent={<div className={classes.optionsCont} >
                {allStatusOptions["Transaction Template"][txTemplate?.status] && allStatusOptions["Transaction Template"][txTemplate?.status].map((statusOption, i) => (
                  <div
                    key={i}
                    className={classes.typeSingleOptionSty}
                    onClick={() => { updateStatus(statusOption) }}
                  >
                    {statusOption}
                  </div>
                ))}
              </div>}
            />
            <ApprovalActionDrawer
              openAction={openAction}
              setOpenAction={setOpenAction}
              loadingBool={loadingBool}
              setLoadingBool={setLoadingBool}
              actionType={"takeAction"}
              parent={txTemplate?._id}
              parentModelName={"TxTemplate"}
              statusField={"status"}
              statusValue={actionStatus}
              requestId={txTemplate?.approvalRequest?._id}
              activeStage={activeStage}
              actionCallBack={async (type, curStatues) => {
                await getFreshData()
              }}
            />
          </>)}
        </div>
      </div>
      <div className={classes.invoiceEditBody} >
        <div className={classes.sectionTop} style={{ marginTop: "10px" }} >
          <h3>Scheduler</h3>
        </div>
        <SchedulerForm
          createByDefault
          scheduler={scheduler}
          setScheduler={setScheduler}
          parent={txTemplate?._id}
          needToMakeDisable={needToMakeDisable}
          parentModelName={"TxTemplate"}
          turnOnGenerator={turnOnGenerator}
        />
        <div className={classes.sectionTop} style={{ marginTop: "10px" }} >
          <h3>Bank Chart Account</h3>
        </div>
        <Autocomplete
          value={selectedBankChartAccount}
          options={bankChartAccounts}
          disabled={needToMakeDisable}
          getOptionLabel={(option) => { return option?.name || "" }}
          getOptionSelected={(option) => {
            return option?._id === selectedBankChartAccount?._id
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: "0px" }}
              margin="normal"
              variant="outlined"
              label="Bank Chart Account"
              placeholder={"Select bank chart account"}
            />
          )}
          onChange={(event, value) => {
            if (value?._id === "New") {
              setOpenChartAcc(true);
            } else {
              onSelectChartAccount(value)
            }
          }}
          className={classes.autocompleteSty}
          size="small"
        />
        {!needToMakeDisable && openChartAcc && (
          <ChartAccountCreateForBank
            walletId={walletId}
            openDialog={openChartAcc}
            setOpenChart={chartAccountCreateDialog}
          />
        )}
        <p style={{ fontSize: "12px", color: "gray" }} ><span style={{ color: "red" }} >*</span>Choose the chart bank account where the amount will added after the payment done</p>
      </div>
      <NormalDialog
        openDialog={openErr}
        handleCloseShare={() => { setOpenErr(false) }}
        pageTitle={"Something is wrong"}
        content={<>
          <p style={{ color: "red", textAlign: "center" }} >{submitionFailMsg}</p>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }} >
            <Button
              onClick={() => { setOpenErr(false) }}
              color="primary"
            >
              ok
            </Button>
          </div>
        </>}
      />
    </div>
  );
}