import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import BillTemplateEdit from '../../expenseAndBills/generator/generatorBill/bill.template.edit';
import InvoiceTemplateEdit from '../../salesAndInvoices/generator/generatorInvoice/invoice.template.edit';
import { getFullTemplateInitalData } from '../../expenseAndBills/generator/api.call';
import TxSeleton from '../../../styled/skeletons/Tx.Seleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%"
  }
}));

export default function TxTemplateEdit(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { walletId, txtemplateId } = useParams();
  const curUrl = window.location.href

  const { user } = useSelector((state) => state.auth);

  const [txTemplate, setTxTemplate] = useState(null);
  const [discounts, setDiscounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [chartAccounts, setChartAccounts] = useState([]);
  const [bankChartAccounts, setBankChartAccounts] = useState([]);
  const [offerings, setOfferings] = useState([]);
  const [terms, setTerms] = useState([]);
  const [templateNosArr, setTemplateNosArr] = useState([]);
  const [maxNo, setMaxNo] = useState()
  const [templateLoading, setTemplateLoading] = useState(false)
  const [needToMakeDisable, setNeedToMakeDisable] = useState(false);
  const [activeStage, setActiveStage] = useState(null)

  useEffect(() => {
    if (curUrl.includes("invoice-generator") && curUrl.includes("edit")) {
      setTemplateLoading(true)
      getFullTemplateInitalData({
        walletId,
        templateId: txtemplateId,
        type: "Invoice",
        own: true,
        isView: false
      })
        .then((data) => {
          const addObject = {
            _id: 'New',
            name: "+ Add New",
            numDays: "0"
          };

          // to get tax and discounts
          if (data?.resDiscountAndTxs && data?.resDiscountAndTxs.length > 0) {
            const discountOrTaxs = data?.resDiscountAndTxs;
            let discounts = []
            let taxes = []

            discountOrTaxs.length > 0 && discountOrTaxs.map((discountOrTax) => {
              if (discountOrTax.model === "Discount") {
                discounts.push(discountOrTax)
              } else if (discountOrTax.model === "Tax") {
                taxes.push(discountOrTax)
              }
            })

            const modifiedDiscounts = [addObject, ...discounts];
            setDiscounts(modifiedDiscounts)
            const modifiedtaxes = [addObject, ...taxes];
            setTaxes(modifiedtaxes)
          }

          // to get products and services
          const offeringRelations = data?.resProductsAndServices || []
          let newOfferingsArr = [addObject, ...offeringRelations]
          setOfferings(newOfferingsArr);

          // to get terms for late fee and due date
          const getTerms = data?.resTerms || []
          const newFiltered = [addObject, ...getTerms];
          setTerms(newFiltered);

          // to get tx no array
          if (data?.resTemplatesForTemplateNoEdit && data.resTemplatesForTemplateNoEdit.length > 0) {
            let newArr = []
            let maxNoff = 1
            data.resTemplatesForTemplateNoEdit.map((templateData) => {
              if (templateData?.txNo && templateData?.txNo !== data?.resTemplateData?.txNo) {
                newArr.push(templateData.txNo)
              }
              if (templateData?.txNo) {
                if (parseInt(templateData?.txNo) > maxNoff) {
                  maxNoff = parseInt(templateData?.txNo)
                }
              }
            })

            setTemplateNosArr(newArr);
            setMaxNo(maxNoff)
          }

          // to get transaction full data
          setTxTemplate(data?.resTemplateData)

          // to get chart accounts
          setChartAccounts(data?.resChartAccounts)

          // to get bank accounts
          const getBankChartAccs = data?.rexBankAccounts
          const locBankAccs = [addObject, ...getBankChartAccs]
          setBankChartAccounts(locBankAccs)

          setTemplateLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
    } else if (curUrl.includes("bill-generator") && curUrl.includes("edit")) {
      setTemplateLoading(true)
      getFullTemplateInitalData({
        walletId,
        templateId: txtemplateId,
        type: "Bill",
        own: false,
        isView: false
      })
        .then((data) => {
          const addObject = {
            _id: 'New',
            name: "+ Add New",
            numDays: "0"
          };

          // to get products and services
          const offeringRelations = data?.resProductsAndServices || []
          let newOfferingsArr = [addObject, ...offeringRelations]
          setOfferings(newOfferingsArr);

          // to get tx no array
          if (data?.resTemplatesForTemplateNoEdit && data.resTemplatesForTemplateNoEdit.length > 0) {
            let newArr = []
            let maxNoff = 1
            data.resTemplatesForTemplateNoEdit.map((templateData) => {
              if (templateData?.txNo && templateData?.txNo !== data?.resTemplateData?.txNo) {
                newArr.push(templateData.txNo)
              }
              if (templateData?.txNo) {
                if (parseInt(templateData?.txNo) > maxNoff) {
                  maxNoff = parseInt(templateData?.txNo)
                }
              }
            })

            setTemplateNosArr(newArr);
            setMaxNo(maxNoff)
          }

          // to get transaction full data
          setTxTemplate(data?.resTemplateData)

          // to get chart accounts
          setChartAccounts(data?.resChartAccounts)

          // to get bank accounts
          const getBankChartAccs = data?.rexBankAccounts
          const locBankAccs = [addObject, ...getBankChartAccs]
          setBankChartAccounts(locBankAccs)

          setTemplateLoading(false)
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [txtemplateId, walletId])

  useEffect(() => {
    let locActiveStage = txTemplate?.approvalRequest?.activeStage || null
    setActiveStage(locActiveStage)
    let canEdit = false
    if (locActiveStage?.approvalType === "anybody") {
      canEdit = true
    } else {
      let approvers = locActiveStage?.approvers || [];
      for (let i = 0; i < approvers.length; i++) {
        if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
          canEdit = true;
          break;
        }
      }
    }
    setNeedToMakeDisable(!canEdit)
  }, [txTemplate?.approvalRequest?.activeStage])


  let TemplateView = null;
  switch (txTemplate?.type) {
    case 'Bill':
      TemplateView = <BillTemplateEdit
        txTemplate={txTemplate}
        setTxTemplate={setTxTemplate}
        walletId={walletId}
        chartAccounts={chartAccounts}
        setChartAccounts={setChartAccounts}
        offerings={offerings}
        setOfferings={setOfferings}
        templateNosArr={templateNosArr}
        setTemplateNosArr={setTemplateNosArr}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        bankChartAccounts={bankChartAccounts}
        setBankChartAccounts={setBankChartAccounts}
        needToMakeDisable={needToMakeDisable}
        activeStage={activeStage}
      />
      break;
    case 'Invoice':
      TemplateView = <InvoiceTemplateEdit
        txTemplate={txTemplate}
        setTxTemplate={setTxTemplate}
        walletId={walletId}
        chartAccounts={chartAccounts}
        setChartAccounts={setChartAccounts}
        offerings={offerings}
        setOfferings={setOfferings}
        templateNosArr={templateNosArr}
        setTemplateNosArr={setTemplateNosArr}
        maxNo={maxNo}
        setMaxNo={setMaxNo}
        bankChartAccounts={bankChartAccounts}
        setBankChartAccounts={setBankChartAccounts}
        terms={terms}
        setTerms={setTerms}
        discounts={discounts}
        setDiscounts={setDiscounts}
        taxes={taxes}
        setTaxes={setTaxes}
        needToMakeDisable={needToMakeDisable}
        activeStage={activeStage}
      />
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      {templateLoading ? (<TxSeleton isInvoice={curUrl.includes("invoice-generator") ? true : false} />) : TemplateView}
    </div>
  );
}