
import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { makeStyles } from "@material-ui/core/styles";
import Api from '../../../helpers/Api';
import useBlockUnitViewer from '../../planning/tasks/components/BlockUnitViewer';
import ProjectPicker from '../../styled/generic/ProjectPicker';
import AttachTaskDrawer from './AttachTaskDrawer';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import TaskAttachments from "./TaskAttachments";

const useStyles = makeStyles((theme) => ({
  projectContainer: {
    width: "100%",
    
  },
  projectBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    marginTop: "50px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  barRight: {
    display: "flex",
        alignItems: "center",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
          textTransform: "capitalize",
        },
  },
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    top: 0,
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  blockandtask: {
    gap: "1px",
  },
  container: {
    display: 'flex',
    width: '100%',
    height: "350px"
  },
  leftSection: {
    width: '40%',
    marginRight: '20px',
   
  },
  rightSection: {
    width: '60%',
    height: '350px',
    position: 'sticky',
    top: 0,
  },
  projectContainer: {
    width: "100%",
  },
  taskattach: {
    paddingLeft: "5px",
    overflowY: "auto",
    width: '100%',
    height: '300px',
    position: 'sticky',
  }
}));

const BlocksAndTasksHandler = ({ 
  tx,
  setTx,
  needToMakeDisable,
  setLoadingBool,
  walletId,
  selectProject,
  setSelectedProject,
  parentModel,
  initialBlocks,
  initialUnits,
  initialTasks,
  initialattachedfiles
}) => {
  const classes = useStyles();
  const [allTasks, setAllTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedCheckedTasks, setSelectedCheckedTasks] = useState([]);
  const [selectedTaskFiles, setSelectedTaskFiles] = useState([]);
  const [openTaskDrawer, setOpenTaskDrawer] = useState(false);
  const [txBlocks, setTxBlocks] = useState(tx?.projectBlocks || []);
  const [filteredTasks, setFilteredTasks] = useState(null);

  console.log("The initial blocks:" , initialBlocks);
  console.log("The initial Units:" , initialUnits);
  console.log("The initial Tasks:" , initialTasks);
  console.log("TxId:" , tx?._id);
  console.log("ParentModel:", parentModel);
  console.log("ProjectId-->" , selectProject);

  const { blockUnitView, loading, selectedUnitIds, selectedBlockIds } = useBlockUnitViewer({
    projectId: selectProject || tx?.project,
    initialBlockIds: initialBlocks || tx?.projectBlocks || [],
    initialUnitIds: initialUnits || tx?.units || [],
  });

  const getAllWbsTasksByProjectId = async () => {
    try {
      const { data } = await Api.post("/wbs/by-projectId", {
        projectId: selectProject || tx?.project,
      });
      setAllTasks(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (searchResults) => {
    setFilteredTasks(searchResults);
  };

  
  
  const setBlockAndUnitinTx = async () => {
    try {
      

      const { data } = await Api.post("/transaction/submit/block-unit", {
        txId: tx?._id,
        blockIds: selectedBlockIds || [],
        unitIds: selectedUnitIds || [],
        projectId: selectProject === "EMPTY" ? "" : selectProject,
        taskIds: selectedCheckedTasks?.map(({ _id }) => _id),
        parentModel : parentModel
      } 
    );
    
  
      setTxBlocks(data.projectBlocks);
      // setTx(prevTx => ({
      //   ...prevTx,
      //   projectBlocks: data.projectBlocks,
      //   tasks: data.tasks,
      //   taskAttachedFiles: data.taskAttachedFiles
      // }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDrawer = () => {
        setTasksByBlockId();
      setOpenTaskDrawer(true);
  };

  const setTasksByBlockId = () => {
    if (selectedBlockIds.length === 0) {
        setSelectedTasks(allTasks);
      }
    else{
    let allCollectedTasks = [];
    for (let i = 0; i < selectedBlockIds?.length; i++) {
      const blockId = selectedBlockIds[i];
      const newTasks = allTasks?.filter((task) => task?.projectBlocks?.includes(blockId));
      allCollectedTasks = new Set([...allCollectedTasks, ...newTasks]);
    }
    setSelectedTasks(Array.from(allCollectedTasks));}
  };


  const removeBlockTasks = async () => {
    if (selectedBlockIds?.length < txBlocks?.length && !loading) {
      const removedBlockId = txBlocks?.filter((id) => !selectedBlockIds?.includes(id));
      const removeTaskIds = selectedCheckedTasks?.filter((task) => 
        task.projectBlocks?.includes(removedBlockId[0])
      );
      
      const { data } = await Api.post("/transaction/remove/task", {
        taskIds: removeTaskIds?.map(({ _id }) => _id) || [],
        txId: tx?._id,
        removeProjectId: selectProject === "EMPTY",
        parentModel: parentModel
      });
      
      if (selectProject && selectProject !== "EMPTY") {
        setTxBlocks(data?.projectBlocks);
        setSelectedCheckedTasks(data?.tasks?.length > 0 ? data?.tasks : []);
        setSelectedTaskFiles(data?.taskAttachedFiles?.length > 0 ? data?.taskAttachedFiles : []);
        // setTx(prevTx => ({
        //   ...prevTx,
        //   projectBlocks: data.projectBlocks,
        //   tasks: data.tasks,
        //   taskAttachedFiles: data.taskAttachedFiles
        // }));
      }
    }
  };

  // const removeBlockTasks = async () => {
  //   if (!tx || !tx.projectBlocks) {
  //     console.warn("Transaction or projectBlocks is undefined");
  //     return;
  //   }
  
  //   if ((selectedBlockIds?.length ?? 0) < (txBlocks?.length ?? 0) && !loading) {
  //     const removedBlockId = txBlocks?.filter((id) => !selectedBlockIds?.includes(id)) ?? [];
  //     const removeTaskIds = selectedCheckedTasks?.filter((task) => 
  //       task?.projectBlocks?.some(blockId => removedBlockId.includes(blockId))
  //     ) ?? [];
      
  //     try {
  //       const { data } = await Api.post("/transaction/remove/task", {
  //         taskIds: removeTaskIds.map(({ _id }) => _id),
  //         txId: tx?._id,
  //         removeProjectId: selectProject === "EMPTY",
  //       });
        
  //       if (selectProject && selectProject !== "EMPTY") {
  //         setTxBlocks(data?.projectBlocks ?? []);
  //         setSelectedCheckedTasks(data?.tasks?.length > 0 ? data?.tasks : []);
  //         setSelectedTaskFiles(data?.taskAttachedFiles?.length > 0 ? data?.taskAttachedFiles : []);
  //         setTx(prevTx => ({
  //           ...prevTx,
  //           projectBlocks: data.projectBlocks ?? [],
  //           tasks: data.tasks ?? [],
  //           taskAttachedFiles: data.taskAttachedFiles ?? []
  //         }));
  //       }
  //     } catch (error) {
  //       console.error("Error removing tasks:", error);
  //     }
  //   }
  // };

  useEffect(() => {
    setSelectedCheckedTasks(initialTasks || []);
    setSelectedTaskFiles(initialattachedfiles || []);
    getAllWbsTasksByProjectId();
  }, []);

  useEffect(() => {
    if (selectedCheckedTasks.length > 0) {
      setOpenTaskDrawer(true);
    }
  }, [selectedCheckedTasks]);

  // useEffect(() => {
  //   setSelectedCheckedTasks(initialTasks || (tx?.tasks?.length > 0 ? tx?.tasks : []));
  //   setSelectedTaskFiles(initialattachedfiles || (tx?.taskAttachedFiles?.length > 0 ? tx?.taskAttachedFiles : []));
  //   getAllWbsTasksByProjectId();
  // }, []);

  useEffect(() => {
    if (selectProject === "EMPTY") {
      removeBlockTasks(true);
    }
  }, [selectProject]);

  useDebounce(
    () => {
      if (selectProject && selectProject !== "EMPTY" && selectedBlockIds?.length > 0) {
        setBlockAndUnitinTx();
        getAllWbsTasksByProjectId();
      }
    },
    1000,
    [selectProject, selectedBlockIds?.length, selectedCheckedTasks?.length, selectedUnitIds?.length]
  );

  useEffect(() => {
    if (selectProject === "EMPTY") {
      setSelectedCheckedTasks([]);
    }
    removeBlockTasks();
    setTasksByBlockId();
    setBlockAndUnitinTx();
  }, [selectedBlockIds?.length, selectProject]);

  return (
    <div className={classes.container}>
    <div className={classes.leftSection}>
      <div className={classes.projectContainer}>
        {blockUnitView}
        <div className={classes.barRight}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDrawer}
            size="medium"
          >
            View tasks
          </Button>
        </div>
      </div>
      <AttachTaskDrawer
        selectedTasks={selectedTasks}
        txId={tx?._id}
        open={openTaskDrawer}
        setSelectedTaskFiles={setSelectedTaskFiles}
        selectedTaskFiles={selectedTaskFiles}
        selectedCheckedTasks={selectedCheckedTasks}
        setSelectedCheckedTasks={setSelectedCheckedTasks}
        setOpen={setOpenTaskDrawer}
        onSearch={handleSearch}
        projectId={selectProject}
        parentModel = {parentModel}
      />
    </div>
    <div className={classes.rightSection}>
      <div className={classes.sectionTop}>
        <Typography variant="h3">Task attachments</Typography>
      </div>
      <div className={classes.taskattach}>
      <TaskAttachments
        selectedTaskFiles={selectedTaskFiles}
        txId={tx?._id}
        selectedCheckedTasks={selectedCheckedTasks}
      />
      </div>
      
    </div>
  </div>
);
};

export default BlocksAndTasksHandler;