import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountingPrivate from "./AccountingPrivate";

const AccountingRoute = ({ Component, noTopBar, header, curOption, curSubOption, path, ...rest }) => {
    const { user } = useSelector((state) => state.auth);
    return (
        <Route
            key={path}
            path={path}
            {...rest}
            render={(props) =>
                user ? (
                    <AccountingPrivate
                        curOption={curOption}
                        noTopBar={noTopBar}
                        header={header}
                        curSubOption={curSubOption}
                    >
                        <Component
                            key={path}
                            {...props}
                        />
                    </AccountingPrivate>
                ) : (
                    <Redirect
                        to={{ pathname: "/about", state: { from: props.location } }}
                    />
                )
            }
        />
    );
};
export default AccountingRoute;