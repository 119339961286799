import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from './Dialog';
import { useDebounce } from "react-use";
import { createTermForWallet } from './api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56"
    },
    [theme.breakpoints.down('sm')]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    }
  },
  mainPaperCont: {
    padding: "0px",
    marginTop: "50px",
    width: "100%",
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
  },
  termDataFieldCont: {
    width: "180px",
    margin: "0px",
    marginRight: "20px",
    [theme.breakpoints.down('sm')]: {
      width: "160px"
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      margin: "0px",
      marginBottom: "20px"
    },
  },
  checkboxTextCont: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    "& p": {
      fontSize: "16px",
      fontWeight: "500"
    },
    "& .MuiCheckbox-root": {
      marginRight: "5px",
      padding: "5px",
      marginLeft: "-8px"
    }
  },
  latefeesAmount: {
    width: "320px",
    marginTop: "15px"
  }
}));

const DateNow = new Date();

export default function TxLateFee({
  tx, setTx, updateApi, setShowGL, walletId, terms, setTerms,
  needToMakeDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const oldDueDate = tx?.dueDate || DateNow;
  const [dueDate, setDueDate] = useState("" + oldDueDate);
  const oldInvoiceDate = tx.invoiceDate || DateNow;
  const [invoiceDate, setInvoiceDate] = useState("" + oldInvoiceDate);
  const oldlateFeeApplicable = tx?.lateFeeApplicable || false;
  const [lateFeeApplicable, setLateFeeApplicable] = useState(oldlateFeeApplicable);
  const oldlateFeeAmount = tx?.lateFeeAmount || 0;
  const [lateFeeAmount, setLateFeeAmount] = useState(oldlateFeeAmount);
  const term = tx?.term;
  const [open, setOpen] = useState(false);
  const [termValue, setTermValue] = useState(term);
  const [onlyDays, setOnlyDays] = useState(false);
  const [onlyDayMonth, setOnlyDayMonth] = useState(false);
  const [termName, setTermName] = useState("");
  const [termDays, setTermDays] = useState(0);
  const [termDMDay, setTermDMDay] = useState(0);
  const [termDMMonth, setTermDMMonth] = useState(0);
  const [initialDStopper1, setInitialDStopper1] = useState(false)
  const [initialDStopper2, setInitialDStopper2] = useState(false)

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const update = async (obj) => {
    if (obj && !needToMakeDisable) {
      await updateApi(obj)
      setTx({
        ...tx,
        ...obj
      })
    }
  }

  //(effect on initial call)
  useDebounce(() => {
    if (initialDStopper1 && !needToMakeDisable) {
      update({
        _id: tx?._id,
        dueDate: dueDate,
        invoiceDate: invoiceDate,
        lateFeeApplicable: lateFeeApplicable,
        lateFeeAmount: lateFeeAmount
      })
    } else {
      setInitialDStopper1(true)
    }
  }, 1000, [dueDate, invoiceDate, lateFeeApplicable, lateFeeAmount]);

  const checkboxClicked = async (boolVal) => {
    if (!needToMakeDisable) {
      setLateFeeApplicable(boolVal)
      setShowGL(boolVal)
    }
  }

  //(effect on initial call) (add conditon to stop initial call)
  useDebounce(() => {
    if (initialDStopper2 && !needToMakeDisable) {
      changeTermValue()
    } else {
      setInitialDStopper2(true)
    }
  }, 1000, [invoiceDate, termValue, termValue?._id]);

  const changeTermValue = async () => {
    if (!needToMakeDisable) {
      let newTx = null;
      if (termValue?.isNumDays) {
        const changedDate = moment(invoiceDate).add(parseInt(termValue?.numDays), 'days')
        setDueDate(changedDate)
        newTx = {
          _id: tx?._id,
          dueDate: changedDate,
        }
      } else if (termValue?.isDayOfMonth) {
        let changedDate = null;
        const dateAfterDayAdded = moment(invoiceDate).add(parseInt(termValue?.month), 'days')
        const dayOfMonth = dateAfterDayAdded.date();

        if (dayOfMonth < parseInt(termValue?.day)) {
          // in current month
          const dateStr = moment(`${dateAfterDayAdded.month() + 1}-${termValue?.day}-${dateAfterDayAdded.year()}`, "MM-DD-YYYY")
          if (dateStr.isValid()) {
            changedDate = dateStr
          } else {
            changedDate = dateAfterDayAdded.endOf('month').format('MM-DD-YYYY');
          }
        } else if (dayOfMonth > parseInt(termValue?.day)) {
          // next month
          const dateStr = moment(`${parseInt(dateAfterDayAdded.month()) + 2}-${termValue?.day}-${dateAfterDayAdded.year()}`, "MM-DD-YYYY")
          if (dateStr.isValid()) {
            changedDate = dateStr
          } else {
            const endOfMonth = dateAfterDayAdded.endOf('month').format('MM-DD-YYYY');
            changedDate = moment(`${parseInt(dateAfterDayAdded.month()) + 2}-${endOfMonth}-${dateAfterDayAdded.year()}`, "MM-DD-YYYY")
          }
        } else {
          changedDate = dateAfterDayAdded;
        }

        setDueDate(changedDate);
        newTx = {
          _id: tx?._id,
          dueDate: changedDate,
        }
      }
      await update(newTx);
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  const onSelect = async (selected) => {
    if (selected && selected?._id && !needToMakeDisable) {
      const newTerm = {
        _id: tx?._id,
        term: selected._id
      }
      await update(newTerm);
      const updatedTx = tx
      updatedTx.term = selected

      setTx(updatedTx)
      setTermValue(selected);
    }
  };

  const saveNewTerm = async (e) => {
    e.preventDefault()
    if (!needToMakeDisable) {
      const newTerm = {
        name: termName,
        isNumDays: onlyDays,
        numDays: termDays,
        isDayOfMonth: onlyDayMonth,
        day: termDMDay,
        month: termDMMonth,
        wallet: walletId,
        public: false
      };
      if (termName.length > 3 && (onlyDays || onlyDayMonth)) {
        await createTermForWallet({
          term: newTerm,
          _id: tx._id
        })
          .then((data) => {
            const newTerms = terms || [];
            newTerms.push(data);
            setTerms(newTerms);
            onSelect(data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      setTermName("");
      setTermDays(0);
      setTermDMDay(0);
      setTermDMMonth(0)
      setOpen(false);
    }
  }

  return (
    <div className={classes.mainPaperCont} >
      <div className={classes.sectionTop} >
        <h3>Due Date & Late Fees</h3>
      </div>

      <div className={classes.alignItems} >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={invoiceDate}
            className={classes.termDataFieldCont}
            disabled={needToMakeDisable}
            margin="normal"
            id="due-date-picker"
            label="Invoice Date"
            format="MM/dd/yyyy"
            onChange={(date) => { setInvoiceDate(date) }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <Autocomplete
          value={termValue}
          options={terms}
          disabled={needToMakeDisable}
          getOptionLabel={(option) => { return option?.name || "" }}
          getOptionSelected={(option) => {
            return option?._id === termValue?._id
          }}
          renderInput={(params) => (
            <TextField
              {...params} style={{ margin: "0px" }} margin="normal" variant="outlined" placeholder={"Enter Terms"}
            />
          )}
          onChange={(event, value) => {
            if (value?._id === "New") {
              setOpen(true);
            } else {
              onSelect(value)
            }
          }}
          style={isExSmall ? { marginBottom: "20px" } : { marginBottom: "-10px" }}
          className={classes.termDataFieldCont}
          size="small"
        />
        <Dialog
          open={open}
          handleClose={handleClose}
          setOnlyDays={setOnlyDays}
          setOnlyDayMonth={setOnlyDayMonth}
          onlyDayMonth={onlyDayMonth}
          onlyDays={onlyDays}
          termName={termName}
          setTermName={setTermName}
          termDays={termDays}
          setTermDays={setTermDays}
          termDMDay={termDMDay}
          setTermDMDay={setTermDMDay}
          termDMMonth={termDMMonth}
          setTermDMMonth={setTermDMMonth}
          saveNewTerm={saveNewTerm}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={dueDate}
            className={classes.termDataFieldCont}
            margin="normal"
            id="due-date-picker"
            disabled={needToMakeDisable}
            label="Due Date"
            format="MM/dd/yyyy"
            onChange={async (date) => { setDueDate(date) }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.checkboxTextCont}>
        <Checkbox
          color="primary"
          checked={lateFeeApplicable}
          disabled={needToMakeDisable}
          onClick={(e) => checkboxClicked(e.target.checked)}
        />
        <p>Late Fees Applicable</p>
      </div>
      <Collapse in={lateFeeApplicable}>
        <TextField
          variant="outlined"
          value={lateFeeAmount}
          disabled={needToMakeDisable}
          onChange={(e) => { setLateFeeAmount(e.target.value); }}
          label={"Late fee amount"}
          placeholder={"Enter Late Fee Amount"}
          className={classes.latefeesAmount}
          size="small"
          InputProps={{
            startAdornment: (<InputAdornment position="start">₹</InputAdornment>)
          }}
        />
      </Collapse>
    </div>
  );
}
