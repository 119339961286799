import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Services from '../../../Assets/services.svg';
import Product from '../../../Assets/product.svg';
import emptyIcon from "../../../Assets/emptyData.svg"
import {
    getChartAccounts, getNumbersOfOfferings,
    getOfferingsRelation, updateOfferingRelation
} from './utils';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
import LessText from '../../styled/CommonComponents/LessText';
import NormalDialog from "../../styled/CommonComponents/NormalDialog"
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from '@material-ui/lab/Skeleton';
import { SettingsInputCompositeRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflowX: "auto",
    },
    root: {
        width: "100%",
        minWidth: "1200px",
        height: "100%",
        overflowX: "auto",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        "& .MuiTablePagination-root": {
            border: "1px solid #d2d1d1",
            marginTop: "-3px"
        }
    },
    container: {
        width: '100%',
        height: "calc(100% - 52px)",
    },
    EditCont: {
        width: "47%",
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        }
    },
    editMainCont: {
        display: "flex",
        flexDirection: 'column',
        width: "500px",
        overflowY: "auto",
        maxHeight: "350px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        padding: "15px 5px 0px",
        [theme.breakpoints.only("xs")]: {
            width: "280px",
            padding: "15px 5px"
        }
    },
    twoRowCont: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
            justifyContent: "center",
        }
    },
    tbHeaderCell: {
        color: "#696969",
        fontSize: "15px",
        padding: "0px",
    },
    tbBodyCell: {
        padding: "8px 5px"
    },
    btnCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    loderCont: {
        height: "5px",
        margin: "5px 0px"
    },
    pageLoaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: "250px",
            height: "auto"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "400",
            marginTop: "10px"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    }
}));

export default function ProductsServices(props) {
    const { walletId } = useParams()
    const history = useHistory();
    const classes = useStyles();

    const [allData, setAllData] = useState([{ data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }])
    const [totalPages, setTotalPages] = useState(0)
    const [pagesBool, setPagesBool] = useState([true])
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)

    const [editLoading, setEditLoading] = useState(false);
    const [desc, setDesc] = useState("");
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [vendor, setVendor] = useState("");
    const [code, setCode] = useState("");
    const [unit, setUnit] = useState("");
    const [taxRate, setTaxRate] = useState(0);
    const [price, setPrice] = useState(0);
    const [selectedOfferingRelation, setSelectedOfferingRelation] = useState({})
    const [selectedChartAcc, setSelectedChartAcc] = useState({});

    const [openEdit, setOpenEdit] = useState(false);
    const [incomechartAcc, setIncomeChartAcc] = useState([])
    const [expensechartAcc, setExpenseChartAcc] = useState([])
    const [chartAcc, setChartAcc] = useState([]);



    useEffect(() => {
        getChartAccounts({ walletId })
            .then((chartAccounts) => {
                let incomeChartAccounts = []
                let expenseChartAccounts = []
                chartAccounts.map((acc) => {
                    if (acc.classification === "Income") {
                        incomeChartAccounts.push(acc)
                    } else if (acc.topLevel === 'Expense') {
                        expenseChartAccounts.push(acc)
                    }
                })

                setIncomeChartAcc(incomeChartAccounts)
                setExpenseChartAcc(expenseChartAccounts)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [walletId]);

    const getProductsAndServices = async (allDatak) => {
        await getOfferingsRelation({
            walletId,
            curPage: (curPage - 1),
            limit: pageLimit
        })
            .then((offeringRelations) => {
                if (offeringRelations && offeringRelations.length > 0) {
                    let pageStart = (curPage - 1) * pageLimit

                    let updatedOfferings = [...allDatak]

                    offeringRelations.map((offering) => {
                        updatedOfferings[pageStart] = offering;
                        pageStart++;
                    })

                    setAllData(updatedOfferings)
                } else {
                    setAllData([])
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (!pagesBool[curPage - 1] && curPage !== 1) {
            getProductsAndServices(allData)
            let arr = [...pagesBool]
            arr[curPage - 1] = true
            setPagesBool(arr)
        }
    }, [curPage])

    useEffect(() => {
        getNumbersOfOfferings({ walletId: walletId })
            .then((data) => {
                let totalNumbers = data[0]?.numberOfOfferings || 0
                let dataArr = [];

                if (totalNumbers && totalNumbers !== 0) {
                    for (let i = 0; i < totalNumbers; i++) {
                        dataArr.push({ data: null })
                    }
                    setAllData(dataArr)

                    let locTotalPage = Math.ceil(totalNumbers / pageLimit);
                    setTotalPages(locTotalPage)

                    let arr = []
                    for (let i = 0; i < locTotalPage; i++) {
                        if (i === 0) {
                            arr.push(true)
                        } else {
                            arr.push(false)
                        }
                    }
                    setPagesBool(arr)
                }

                getProductsAndServices(dataArr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [walletId])

    const openEditDialog = (data) => {
        setSelectedChartAcc(data?.chartAccount)
        setDesc(data?.description)
        setName(data?.name)
        setType(data?.model)
        setVendor(data?.vendor?.parent?.displayName)
        setTaxRate(data?.taxRate)
        setPrice(data?.price)
        setCode(data?.code || "")
        setUnit(data?.unit || "")
        setSelectedOfferingRelation(data)
        setOpenEdit(true);

        if (data?.doYouOwnIt === true) {
            setChartAcc(incomechartAcc)
        } else {
            setChartAcc(expensechartAcc)
        }
    };

    const getFormatedData = (data) => {
        let formatedData = new Intl.NumberFormat('en-GB', { notation: "compact", compactDisplay: "short" }).format(data);
        return formatedData;
    }

    const editOfferingRelation = async () => {
        setEditLoading(true)
        await updateOfferingRelation({
            _id: selectedOfferingRelation?._id,
            name: name,
            chartAccount: selectedChartAcc?._id,
            description: desc,
            taxRate: taxRate,
            price: price,
            code: code,
            unit: unit,
            wallet: walletId,
            offeringId: selectedOfferingRelation?.offering?._id,
            offeringCreater: selectedOfferingRelation?.offering?.wallet
        })
            .then((data) => {
                const updatedRelation = data.updatedRelation
                const updatedOffering = data.updatedOffering
                let newAllData = []

                allData.map((offeringRelation) => {
                    if (offeringRelation._id === updatedRelation._id) {
                        let newOfferingRelation = updatedRelation
                        newOfferingRelation.chartAccount = selectedChartAcc
                        newOfferingRelation.addedBy = offeringRelation.addedBy
                        newOfferingRelation.vendor = offeringRelation.vendor
                        if (updatedOffering === null) {
                            newOfferingRelation.offering = offeringRelation.offering
                        } else {
                            newOfferingRelation.offering = updatedOffering
                        }
                        newAllData.push(newOfferingRelation)
                    } else {
                        newAllData.push(offeringRelation)
                    }
                })
                setAllData(newAllData)
                setEditLoading(false);
                setOpenEdit(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }




    return (
        <div className={classes.mainCont} >
            {allData && allData.length > 0 ? (<Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "15px", width: "190px" }} >
                                    Name
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "10px", width: "85px" }} >
                                    Code
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", }} >
                                    Description
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "130px" }} >
                                    Category
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "120px" }} >
                                    Own By
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "80px" }} >
                                    Price
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "90px" }} >
                                    Tax Rate
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "15px", width: "70px" }} >
                                    Unit
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "120px" }} >
                                    Created By
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "80px" }} >
                                    Type
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "15px", width: "90px" }} >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allData && allData.length > 0 && allData.slice((curPage - 1) * pageLimit, (curPage - 1) * pageLimit + pageLimit).map((data) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={data._id}
                                    >
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "15px" }} >
                                            {data?._id ? (
                                                <LessText
                                                    limit={20}
                                                    string={data?.name}
                                                />
                                            ) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "10px", width: "86px" }} >
                                            {data?._id ? (
                                                <LessText
                                                    limit={8}
                                                    string={data?.code}
                                                />
                                            ) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", fontSize: "12px", wordBreak: "break-all" }} >
                                            {data?._id ? (
                                                <LessText
                                                    limit={95}
                                                    string={data?.description}
                                                />
                                            ) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "130px" }} >
                                            {data?._id ? (
                                                <LessText
                                                    limit={12}
                                                    string={data?.chartAccount?.name}
                                                />
                                            ) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "120px" }} >
                                            {data?._id ? (<>
                                                {data?.doYouOwnIt ? (<>Mine</>) : (
                                                    <LessText
                                                        limit={9}
                                                        string={data?.vendor?.parent?.displayName || " "}
                                                    />
                                                )}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "80px" }} >
                                            {data?._id ? (<>
                                                ₹{getFormatedData(data?.price)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                {data?.taxRate}%
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "70px" }} >
                                            {data?._id ? (<>
                                                {data?.unit}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "120px" }} >
                                            {data?._id ? (<>
                                                <LessText
                                                    limit={9}
                                                    string={data?.addedBy?.parent?.displayName}
                                                />
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", paddingTop: "4px", width: "80px" }} >
                                            {data?._id ? (<>
                                                {data?.model === "Product" && <img style={{ width: "40px", height: "auto" }} src={Product} />}
                                                {data?.model === "Service" && <img style={{ width: "40px", height: "auto" }} src={Services} />}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "15px", width: "90px" }} >
                                            {data?._id ? (<>
                                                <IconButton onClick={() => { openEditDialog(data) }} >
                                                    <EditIcon style={{ caursor: "pointer", color: "#3FAD5D", fontSize: "25px" }} />
                                                </IconButton>
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </Paper>) : (<div className={classes.pageLoaderCont} >
                <img src={emptyIcon} />
                <h3>No products and services available</h3>
            </div>)}

            <NormalDialog
                openDialog={openEdit}
                handleCloseShare={() => { setOpenEdit(false); }}
                pageTitle={"Edit Offering"}
                content={<div>
                    <div className={classes.editMainCont} >
                        <div className={classes.twoRowCont} >
                            <div className={classes.EditCont} >
                                <TextField
                                    id="outlined-password-input"
                                    label="Name"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => { setName(e.target.value); }}
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                                <Autocomplete
                                    value={selectedChartAcc}
                                    options={chartAcc}
                                    getOptionLabel={(option) => { return option?.name || "null" }}
                                    getOptionSelected={(option) => {
                                        return option?._id === selectedChartAcc?._id
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Category"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setSelectedChartAcc(value)
                                    }}
                                    style={{ marginBottom: "15px", width: "100%" }}
                                    size="small"
                                />
                                <TextField
                                    id="outlined-password-input"
                                    label="Price"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={price}
                                    onChange={(e) => { setPrice(e.target.value); }}
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                                <TextField
                                    id="outlined-password-input"
                                    label="Tax Rate"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={taxRate}
                                    onChange={(e) => { setTaxRate(e.target.value); }}
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                            </div>
                            <div className={classes.EditCont} >
                                <TextField
                                    id="outlined-password-input"
                                    label="Code"
                                    variant="outlined"
                                    value={code}
                                    onChange={(e) => { setCode(e.target.value) }}
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                                <TextField
                                    id="outlined-password-input"
                                    label="Unit"
                                    variant="outlined"
                                    onChange={(e) => { setUnit(e.target.value) }}
                                    value={unit}
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                                <TextField
                                    id="outlined-password-input"
                                    label="Type"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={type}
                                    disabled
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                                <TextField
                                    id="outlined-password-input"
                                    label="Vendor"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    value={vendor}
                                    disabled
                                    size="small"
                                    style={{ marginBottom: "15px", width: "100%" }}
                                />
                            </div>
                        </div>
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={3}
                            variant="outlined"
                            value={desc}
                            onChange={(e) => { setDesc(e.target.value) }}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className={classes.loderCont} >
                        {editLoading && (<LinearProgress />)}
                    </div>
                    <div className={classes.btnCont} >
                        <Button
                            onClick={editOfferingRelation}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Save and Close
                        </Button>
                    </div>
                </div>}
            />
        </div>
    );
}