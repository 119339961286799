import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
    },
    topBar: {
        width: "100%",
        height: "60px",
        padding: "0px 15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #d0cfcf",
    },
    bodyMain: {
        width: "100%",
        height: "calc(100vh - 60px)",
        padding: "20px 25px 50px",
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: "20px 10px 50px",
        }
    },
    width130: {
        width: "130px",
    },
    width200: {
        width: "200px",
        marginRight: "15px"
    },
    width250: {
        width: "250px",
    },
    width170: {
        width: "170px",
    },
    width130: {
        width: "130px",
    },
    iconSty: {
        width: "30px",
        height: "30px"
    },
    sectionTop: {
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        paddingLeft: "0px",
        marginTop: "50px",
    },
    mainInputCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "25px",
        paddingRight: "5px",
        borderRadius: "10px",
        border: "1px solid #dcdcdc",
        marginBottom: "20px",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            padding: "10px",
        }
    },
    allInputsCont: {
        width: "calc(100% - 50px)",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            "& .MuiCheckbox-root": {
                height: "40px"
            }
        }
    },
    deleteCont: {
        width: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            marginTop: "15px"
        }
    },
    productsCont: {
        width: "250px",
        [theme.breakpoints.down('md')]: {
            width: "230px",
            marginBottom: "15px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "35%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    codeSty: {
        width: "calc((100% - 700px) / 2)",
        [theme.breakpoints.down('md')]: {
            width: "calc(100% - 500px)",
            marginBottom: "15px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "28%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    chartAccCont: {
        width: "250px",
        [theme.breakpoints.down('md')]: {
            width: "230px",
            marginBottom: "15px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "35%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    qtyCont: {
        width: "100px",
        [theme.breakpoints.down('md')]: {
            width: "calc((100% - 90px) / 2)",
        },
        [theme.breakpoints.down('xs')]: {
            width: "calc((100% - 60px) / 2)",
            marginBottom: "20px"
        },
    },
    unitSty: {
        width: "calc((100% - 700px) / 2)",
        [theme.breakpoints.down('md')]: {
            width: "calc((100% - 90px) / 2)",
        },
        [theme.breakpoints.down('xs')]: {
            width: "calc((100% - 60px) / 2)",
            marginBottom: "20px"
        },
    },
    amountsDescCont: {
        width: "100%",
        marginTop: "20px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            marginTop: "0px",
        }
    },
    amountsCont: {
        width: "240px",
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    rateSty: {
        width: "100%",
    },
    totalSty: {
        width: "100%",
        marginTop: "15px",
    },
    descSty: {
        width: "calc(100% - 260px)",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            marginTop: "15px"
        }
    }
}));

export default function TxSeleton({ isInvoice }) {
    const classes = useStyles()



    return (
        <div className={classes.root}>
            <div className={classes.topBar} >
                <div style={{ display: "flex", alignItems: "center" }} >
                    <Skeleton className={classes.iconSty} style={{ marginRight: "10px" }} variant="rect" />
                    <Skeleton className={classes.width130} style={{ height: "30px" }} variant="rect" />
                    <Skeleton className={classes.iconSty} style={{ marginLeft: "10px" }} variant="rect" />
                </div>
                <Skeleton className={classes.width130} style={{ height: "40px" }} variant="rect" />
            </div>
            <div className={classes.bodyMain} >
                <div className={classes.sectionTop} style={{ marginTop: "0px", }} >
                    <Skeleton className={classes.width250} style={{ height: "30px" }} variant="rect" />
                </div>
                <div style={{ display: "flex", alignItems: "center" }} >
                    <Skeleton className={classes.width200} variant="rect" style={{ height: "30px" }} />
                    <Skeleton className={classes.iconSty} variant="rect" style={{ marginLeft: "15px" }} />
                </div>

                {isInvoice && (<>
                    <div className={classes.sectionTop} >
                        <Skeleton className={classes.width250} style={{ height: "30px" }} variant="rect" />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "25px" }} >
                        <Skeleton className={classes.width200} style={{ height: "40px" }} variant="rect" />
                        <Skeleton className={classes.width200} style={{ height: "40px" }} variant="rect" />
                        <Skeleton className={classes.width200} style={{ height: "40px" }} variant="rect" />
                    </div>
                    <Skeleton className={classes.width250} style={{ height: "30px", marginBottom: "10px" }} variant="rect" />
                    <Skeleton className={classes.width250} style={{ height: "40px" }} variant="rect" />
                </>)}


                <div className={classes.sectionTop} >
                    <Skeleton className={classes.width250} style={{ height: "30px" }} variant="rect" />
                </div>


                <div className={classes.mainInputCont} >
                    <div className={classes.allInputsCont} >
                        {/* Product & Services */}
                        <Skeleton className={classes.productsCont} style={{ height: "40px" }} variant="rect" />

                        {/* Product & Services code */}
                        <Skeleton className={classes.codeSty} style={{ height: "40px" }} variant="rect" />

                        {/* Category(Chart Account) */}
                        <Skeleton className={classes.chartAccCont} style={{ height: "40px" }} variant="rect" />

                        {/* Tax */}
                        <Skeleton style={{ height: "35px", width: "35px" }} variant="rect" />

                        {/* Quantity */}
                        <Skeleton className={classes.qtyCont} style={{ height: "40px" }} variant="rect" />

                        {/* unit */}
                        <Skeleton className={classes.unitSty} style={{ height: "40px" }} variant="rect" />

                        <div className={classes.amountsDescCont} >
                            <div className={classes.amountsCont} >
                                {/* Rate */}
                                <Skeleton className={classes.rateSty} style={{ height: "40px" }} variant="rect" />

                                {/* total value */}
                                <Skeleton className={classes.totalSty} style={{ height: "40px" }} variant="rect" />
                            </div>

                            {/* Description */}
                            <Skeleton className={classes.descSty} style={{ height: "95px" }} variant="rect" />
                        </div>
                    </div>

                    <div className={classes.deleteCont} >
                        <Skeleton style={{ height: "50px", width: "40px" }} variant="rect" />
                    </div>
                </div>

                <div className={classes.dividerLine} ></div>

                <div className={classes.mainInputCont} >
                    <div className={classes.allInputsCont} >
                        {/* Product & Services */}
                        <Skeleton className={classes.productsCont} style={{ height: "40px" }} variant="rect" />

                        {/* Product & Services code */}
                        <Skeleton className={classes.codeSty} style={{ height: "40px" }} variant="rect" />

                        {/* Category(Chart Account) */}
                        <Skeleton className={classes.chartAccCont} style={{ height: "40px" }} variant="rect" />

                        {/* Tax */}
                        <Skeleton style={{ height: "35px", width: "35px" }} variant="rect" />

                        {/* Quantity */}
                        <Skeleton className={classes.qtyCont} style={{ height: "40px" }} variant="rect" />

                        {/* unit */}
                        <Skeleton className={classes.unitSty} style={{ height: "40px" }} variant="rect" />

                        <div className={classes.amountsDescCont} >
                            <div className={classes.amountsCont} >
                                {/* Rate */}
                                <Skeleton className={classes.rateSty} style={{ height: "40px" }} variant="rect" />

                                {/* total value */}
                                <Skeleton className={classes.totalSty} style={{ height: "40px" }} variant="rect" />
                            </div>

                            {/* Description */}
                            <Skeleton className={classes.descSty} style={{ height: "95px" }} variant="rect" />
                        </div>
                    </div>

                    <div className={classes.deleteCont} >
                        <Skeleton style={{ height: "50px", width: "40px" }} variant="rect" />
                    </div>
                </div>

                <div className={classes.dividerLine} ></div>

                <div className={classes.mainInputCont} >
                    <div className={classes.allInputsCont} >
                        {/* Product & Services */}
                        <Skeleton className={classes.productsCont} style={{ height: "40px" }} variant="rect" />

                        {/* Product & Services code */}
                        <Skeleton className={classes.codeSty} style={{ height: "40px" }} variant="rect" />

                        {/* Category(Chart Account) */}
                        <Skeleton className={classes.chartAccCont} style={{ height: "40px" }} variant="rect" />

                        {/* Tax */}
                        <Skeleton style={{ height: "35px", width: "35px" }} variant="rect" />

                        {/* Quantity */}
                        <Skeleton className={classes.qtyCont} style={{ height: "40px" }} variant="rect" />

                        {/* unit */}
                        <Skeleton className={classes.unitSty} style={{ height: "40px" }} variant="rect" />

                        <div className={classes.amountsDescCont} >
                            <div className={classes.amountsCont} >
                                {/* Rate */}
                                <Skeleton className={classes.rateSty} style={{ height: "40px" }} variant="rect" />

                                {/* total value */}
                                <Skeleton className={classes.totalSty} style={{ height: "40px" }} variant="rect" />
                            </div>

                            {/* Description */}
                            <Skeleton className={classes.descSty} style={{ height: "95px" }} variant="rect" />
                        </div>
                    </div>

                    <div className={classes.deleteCont} >
                        <Skeleton style={{ height: "50px", width: "40px" }} variant="rect" />
                    </div>
                </div>
            </div>
        </div>
    );
}