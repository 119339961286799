import React, { useState, useEffect } from 'react';
import arrayToReducer from '../../../../helpers/arrayToReducer';
import BillListEditItemAdd from './Bill.List.Edit.Bill.Item.Add';

export default function BillListAddEdit({
  certificateData, setCertificateData, setLoadingBool, walletData,
  setItemsTotal, needToMakeDisable
}) {
  const [billList, setBillList] = useState(null);
  const [billItemDictionary, setBillItemDictionary] = useState({});

  useEffect(() => {
    if (certificateData?.billList?._id) {
      const billList = certificateData?.billList;
      const items = billList?.items || [];
      const { newDict, idArr } = arrayToReducer(items);
      billList.items = idArr;
      setBillItemDictionary(newDict);
      setBillList(billList);
    }
  }, [certificateData?._id]);

  useEffect(() => {
    setCertificateData({
      ...certificateData,
      billList: {
        ...billList,
        items: billList?.items.map(itemId => billItemDictionary[itemId])
      }
    })
    let locTotal = 0;
    billList?.items && billList.items.map(itemId => {
      locTotal = locTotal + (parseFloat(billItemDictionary[itemId]?.rate || 0) * parseFloat(billItemDictionary[itemId]?.qTy || 0))
    })
    setItemsTotal({
      simpleTotal: locTotal
    })
  }, [billList, billItemDictionary])

  return (
    <BillListEditItemAdd
      billList={billList}
      setBillList={setBillList}
      billItemDictionary={billItemDictionary}
      setBillItemDictionary={setBillItemDictionary}
      setLoadingBool={setLoadingBool}
      type={"Expense"}
      needToMakeDisable={needToMakeDisable}
      walletData={walletData}
    />
  );
}