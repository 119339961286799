import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import PhotoAndFullView from "../styled/CommonComponents/PhotosAndFullView";

const useStyles = makeStyles((theme) => ({
  msgCont: {
    maxWidth: "100%",
    borderRadius: "7px",
    padding: "10px",
    backgroundColor: "#ececec",
  },
  nameSty: {
    fontSize: "15px",
    fontWeight: "600",
  },
  timeSty: {
    fontSize: "11px",
    color: "gray",
    textAlign: "right",
    marginTop: "3px",
  },
  filesCont: {
    width: "100%",
  },
  actionStyle: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      width: "15px",
      height: "15px",
    },
  },
}));

export default function MessageAndImgCont({ commentData, isOwn }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  return (
    <div style={{ maxWidth: "100%" }}>
      {commentData?.actionText && commentData.actionText.length > 0 && (
        <div
          className={classes.actionStyle}
          style={
            commentData?.actionText === "Rejected"
              ? { color: "red" }
              : { color: "green" }
          }
        >
          {commentData?.actionText === "Rejected" ? (
            <CancelIcon />
          ) : (
            <CheckCircleIcon />
          )}
          <p>{commentData?.actionText}</p>
        </div>
      )}
      <div
        className={classes.msgCont}
        style={
          isOwn
            ? { borderBottomRightRadius: "0px" }
            : { borderBottomLeftRadius: "0px" }
        }
      >
        {!isOwn && (
          <h3 className={classes.nameSty}>{commentData?.user?.displayName}</h3>
        )}
        <div>{ReactHtmlParser(commentData?.text)}</div>
        <div className={classes.filesCont}>
          <PhotoAndFullView actualImages={commentData?.files || []} />
        </div>
        <p className={classes.timeSty}>
          {moment(commentData?.createdAt).format("Do MMM, HH:mm")}
        </p>
      </div>
    </div>
  );
}
