import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import { getCertificatePagination } from './api.call';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { Avatar } from "@material-ui/core"
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #dfdfdf",
        paddingRight: "20px"
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    tableRoot: {
        width: "100%",
        minWidth: "1200px",
        height: "100%",
        overflowX: "auto",
        overflowY: "auto",
        "& .MuiTablePagination-root": {
            border: "1px solid #d2d1d1",
            marginTop: "-3px"
        }
    },
    container: {
        width: "100%",
        height: "calc(100vh - 100px)",
        "& tr": {
            height: "50px"
        },
        "&.MuiTableCell-root": {
            outline: "1px solid #ececec",
            border: "none",
        },
        "& table": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
        },
        "& th": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "10px"
        },
        "& td": {
            outline: "1px solid #d2d2d2",
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px 10px",
        }
    },
    pageLoaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: "250px",
            height: "auto"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "400",
            marginTop: "10px"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    },
    selectedUserOrgSty: {
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    }
}));

export default function WorkorderCertificates() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { walletId } = useParams();

    const [certificates, setCertificates] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [totalResultsCount, setTotalResultsCount] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [loader, setLoader] = useState(true)

    const getCertificateData = async () => {
        setLoader(true)
        await getCertificatePagination({
            walletId,
            curPage: curPage - 1,
            limit: pageLimit
        })
            .then((data) => {
                console.log(data);
                setCertificates(data?.certificateDataArr || [])
                let locTotalPage = Math.ceil((data?.certificateDataCount || 0) / pageLimit);
                setTotalPages(locTotalPage)
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })
    }

    useEffect(() => {
        getCertificateData()
    }, [curPage])

    return (
        <div className={classes.root}>
            <div className={classes.topBar} >
                <div className={classes.barLeft} >
                    <IconButton
                        className={classes.iconButtonStyle}
                        onClick={() => { history.goBack() }}
                    >
                        <KeyboardBackspaceIcon
                            className={classes.backArrow}
                        />
                    </IconButton>
                    <h3 className={classes.headerTitle} >Workorder Certificates Management</h3>
                </div>

                <div className={classes.barRight} >
                </div>
            </div>
            <div className={classes.bodyCont} >
                {!loader ? (<>
                    {certificates && certificates.length > 0 ? (<Paper className={classes.tableRoot}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" >
                                            Vendor
                                        </TableCell>
                                        <TableCell align="center"  >
                                            Certificate No.
                                        </TableCell>
                                        <TableCell align="center" >
                                            Work/Purchase Order
                                        </TableCell>
                                        <TableCell align="center"  >
                                            Submitted
                                        </TableCell>
                                        <TableCell align="right"  >
                                            Net Payment
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {certificates && certificates.map((cfData) => (
                                        <TableRow
                                            onClick={() => {
                                                history.push(`/finance/${walletId}/workorder-certificate/edit/${cfData?._id}`)
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell align="left" >
                                                <div className={classes.selectedUserOrgSty} >
                                                    <Avatar
                                                        src={cfData?.workOrder?.vendor?.parent?.displayPicture?.thumbUrl ? cfData?.workOrder?.vendor?.parent?.displayPicture?.thumbUrl : cfData?.workOrder?.vendor?.parent?.displayPicture?.url}
                                                        alt={cfData?.workOrder?.vendor?.parent?.displayName}
                                                    />
                                                    <div style={{ marginLeft: "5px" }} >
                                                        <h3>{cfData?.workOrder?.vendor?.parent?.displayName}</h3>
                                                        <p>{cfData?.workOrder?.vendor?.parent?.username}</p>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center"  >
                                                {cfData?.certificateNumber}
                                            </TableCell>
                                            <TableCell align="center" >
                                                {cfData?.workOrder?.name}
                                            </TableCell>
                                            <TableCell align="center"  >
                                                {cfData?.isSubmitted ? "Submitted" : "Not Submitted"}
                                            </TableCell>
                                            <TableCell align="right"  >
                                                {cfData?.netPayment}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.paginationCont} >
                            <Pagination
                                count={totalPages}
                                page={curPage}
                                color="primary"
                                siblingCount={0}
                                onChange={(event, value) => { setCurPage(value) }}
                            />
                        </div>
                    </Paper>) : (<div className={classes.pageLoaderCont} >
                        {/* <img src={emptyIcon} /> */}
                        <h3>No data available</h3>
                    </div>)}
                </>) : null}
            </div>
        </div>
    );
}