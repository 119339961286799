import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { getRelationWithType } from './api';
import AddFinRelConfirm from './AddFinRel.Confirm';
import NetworkCardView from './CommonView';
import emptyIcon from "../../../Assets/emptyData.svg"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "calc(100vh - 50px)",
        overflow: "hidden",
    },
    headerSty: {
        width: "100%",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    allCardsCont: {
        width: "100%",
        maxHeight: "calc(100% - 65px)",
        padding: "20px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "50px",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        overflowY: "auto",
        [theme.breakpoints.down("xs")]: {
            padding: "10px",
        }
    },
    cardSkeleton: {
        width: "calc(33.3% - 20px)",
        height: "50px",
        borderRadius: "5px",
        marginBottom: "20px",
        marginRight: "20px",
        [theme.breakpoints.down("md")]: {
            width: "calc(50% - 50px)",
            marginBottom: "20px",
            marginRight: "50px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(50% - 15px)",
            minWidth: "310px",
            marginBottom: "15px",
            marginRight: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            margin: "0px",
            marginBottom: "20px",
        },
    },
    emptyCont: {
        width: "100%",
        height: "450px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "30px 10px",
        "& img": {
            width: "250px",
            height: "auto"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "400",
            marginTop: "10px"
        }
    }
}))

export default function NetworkView(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { walletId, networkType } = useParams();

    const { team: teamReducer } = useSelector((state) => state);
    const { teamIds, teamDictionary } = teamReducer;

    const [loader, setLoader] = useState(false)
    const [contactsArr, setContactsArr] = useState([]);
    const [filteredContactsArr, setFilteredContactsArr] = useState([]);
    const [notAllowedUserIds, setNotAllowedUserIds] = useState([]);
    const [notAllowedOrgIds, setNotAllowedOrgIds] = useState([]);
    const [notAllowedProjectIds, setNotAllowedProjectIds] = useState([]);
    const [notAllowedProfileIds, setNotAllowedProfileIds] = useState([]);
    const [parent, setParent] = useState(null);
    const [profileId, setProfileId] = useState(null);

    useEffect(() => {
        teamIds.map((teamId) => {
            var value = teamDictionary[teamId];
            if (value?.parent?.wallet === walletId) {
                setParent(value?.parent?._id)
                setProfileId(value?.parent?.profile?._id)
            }
        });
    }, [])

    useEffect(() => {
        if (profileId) {
            setLoader(true)
            getRelationWithType({
                profileId: profileId,
                type: `${networkType.charAt(0).toUpperCase()}${networkType.slice(1)}`
            })
                .then((data) => {
                    if (data && data.length > 0) {
                        let locNotAllowedUserIds = []
                        let locNotAllowedOrgIds = []
                        let locNotAllowedProjectIds = []
                        let locNotAllowedProfileIds = []

                        data.map((contactData) => {
                            locNotAllowedProfileIds.push(contactData?.profile?._id)
                            if (contactData?.profile?.parentModelName === "Project" || (contactData?.profile?.parentModelName === "Pal" && contactData?.profile?.parent?.type === "Project")) {
                                locNotAllowedProjectIds.push(contactData?.profile?.parentModelName === "Pal" ? contactData?.profile?.parent?.parent?._id : contactData?.profile?.parent?._id)
                            } else if (contactData?.profile?.parentModelName === "Organization" || (contactData?.profile?.parentModelName === "Pal" && contactData?.profile?.parent?.type === "Organization")) {
                                locNotAllowedOrgIds.push(contactData?.profile?.parentModelName === "Pal" ? contactData?.profile?.parent?.parent?._id : contactData?.profile?.parent?._id)
                            } else if (contactData?.profile?.parentModelName === "User" || (contactData?.profile?.parentModelName === "Pal" && contactData?.profile?.parent?.type === "User")) {
                                locNotAllowedUserIds.push(contactData?.profile?.parentModelName === "Pal" ? contactData?.profile?.parent?.parent?._id : contactData?.profile?.parent?._id)
                            }
                        })

                        setNotAllowedUserIds(locNotAllowedUserIds)
                        setNotAllowedOrgIds(locNotAllowedOrgIds)
                        setNotAllowedProjectIds(locNotAllowedProjectIds)
                        setNotAllowedProfileIds(locNotAllowedProfileIds)
                        setContactsArr(data)
                        setFilteredContactsArr(data)
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [profileId])

    return (
        <div className={classes.root}>
            <div className={classes.headerSty} >
                <AddFinRelConfirm
                    notAllowedUserIds={notAllowedUserIds}
                    notAllowedOrgIds={notAllowedOrgIds}
                    notAllowedProjectIds={notAllowedProjectIds}
                    notAllowedProfileIds={notAllowedProfileIds}
                    setNotAllowedProfileIds={setNotAllowedProfileIds}
                    setNotAllowedUserIds={setNotAllowedUserIds}
                    setNotAllowedOrgIds={setNotAllowedOrgIds}
                    setNotAllowedProjectIds={setNotAllowedProjectIds}
                    type={`${networkType.charAt(0).toUpperCase()}${networkType.slice(1)}`}
                    contactsArr={contactsArr}
                    setContactsArr={setContactsArr}
                    filteredContactsArr={filteredContactsArr}
                    setFilteredContactsArr={setFilteredContactsArr}
                    wallet={walletId}
                    parentModelName={"Project"}
                    parent={parent}
                    profileId={profileId}
                />
            </div>

            <div className={classes.allCardsCont} >
                {loader ? (<>
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                    <Skeleton variant="rect" className={classes.cardSkeleton} />
                </>) : (<>
                    {filteredContactsArr && filteredContactsArr.length > 0 ? filteredContactsArr.map((contactData, i) => (
                        <NetworkCardView
                            contactData={contactData}
                            type={networkType}
                            key={i}
                        />
                    )) : (<div className={classes.emptyCont} >
                        <img src={emptyIcon} />
                        <p>No {networkType} is available</p>
                    </div>)}
                </>)}
            </div>
        </div>
    );
}