import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TxSecondParty from "../commonComponent/transaction/secondParty";
import TxBillListEdit from "../commonComponent/transaction/tx.bill.list.edit";
import TxLateFee from "../commonComponent/transaction/lateFee";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DialogBillNo from "../expenseAndBills/bills/DialogBillNo";
import ExportPDF from "../../styled/generic/ExportPDF";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  invoiceEditBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "0px 25px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 50px",
    },
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  nextIcon: {
    transform: "rotate(180deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
}));

export default function InvoiceOtherSetting({
  tx,
  setTx,
  updateApi,
  walletId,
  setOpenPayment,
  setLoadingBool,
  discounts,
  setDiscounts,
  taxes,
  setTaxes,
  chartAccounts,
  setChartAccounts,
  offerings,
  setOfferings,
  terms,
  setTerms,
  txNosArr,
  setTxNosArr,
  maxNo,
  setMaxNo,
  incomeChartAccounts,
  setIncomeChartAccounts,
  expenseChartAccounts,
  setExpenseChartAccounts,
  needToMakeDisable,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);
  const BudgetUpdateRef = useRef(null);

  const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const [open, setOpen] = useState(false);
  const oldlateFeeApplicable = tx?.lateFeeApplicable || false;
  const [showGL, setShowGL] = useState(oldlateFeeApplicable);
  const [openPrint, setOpenPrint] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <div className={classes.barLeft}>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon className={classes.backArrow} />
          </IconButton>
          <Typography className={classes.headerTitle}>
            {walletId === tx?.firstPartyWallet._id ? <>Invoice</> : <>Bill</>}
            <span style={{ marginLeft: "10px" }}>#{tx?.txNo}</span>
          </Typography>
          {!needToMakeDisable && (
            <>
              <IconButton
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <EditIcon className={classes.editIconSty} />
              </IconButton>
              <DialogBillNo
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                tx={tx}
                setTx={setTx}
                type={"Invoice"}
                maxNo={maxNo}
                setMaxNo={setMaxNo}
                walletId={walletId}
                setLoadingBool={setLoadingBool}
                txNos={txNosArr}
              />
            </>
          )}
        </div>

        <div className={classes.barRight}>
          {/* <IconButton
            onClick={() => { setOpenPrint(true) }}
            style={{ marginRight: "10px", color: theme.palette.primary.main }}
          >
            <PrintIcon className={classes.editIconSty} />
          </IconButton>
          <InvoicePrintDialog
            openPrint={openPrint}
            setOpenPrint={setOpenPrint}
            tx={tx}
            setTx={setTx}
            updateApi={updateApi}
          /> */}

          <ExportPDF
            apiUrl="/transaction/get/pdf"
            fileName="invoice"
            apiBody={{
              transactionId: tx?._id,
            }}
            enableSignatures={true}
            enableTermsAndConditions={true}
            libraryId={tx?.firstPartyWallet?.defaultLibrary}
            profileId={tx?.firstPartyWallet?.profile}
          />
          {tx?.secondParty ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenPayment(true);
              }}
              size={isExSmall ? "small" : "medium"}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size={isExSmall ? "small" : "medium"}
              disabled
            >
              Next
            </Button>
          )}
        </div>
      </div>

      <div className={classes.invoiceEditBody}>
        <TxSecondParty
          tx={tx}
          setTx={setTx}
          updateApi={updateApi}
          relationType={"Customer"}
          walletId={walletId}
          needToMakeDisable={needToMakeDisable}
          setLoadingBool={setLoadingBool}
        />

        <TxLateFee
          tx={tx}
          setTx={setTx}
          updateApi={updateApi}
          setShowGL={setShowGL}
          walletId={walletId}
          terms={terms}
          needToMakeDisable={needToMakeDisable}
          setTerms={setTerms}
        />

        <TxBillListEdit
          tx={tx}
          setTx={setTx}
          updateApi={updateApi}
          showGL={showGL}
          setLoadingBool={setLoadingBool}
          discounts={discounts}
          setDiscounts={setDiscounts}
          taxes={taxes}
          incomeChartAccounts={incomeChartAccounts}
          setIncomeChartAccounts={setIncomeChartAccounts}
          expenseChartAccounts={expenseChartAccounts}
          setExpenseChartAccounts={setExpenseChartAccounts}
          setTaxes={setTaxes}
          chartAccounts={chartAccounts}
          setChartAccounts={setChartAccounts}
          offerings={offerings}
          setOfferings={setOfferings}
          needToMakeDisable={needToMakeDisable}
        />
      </div>
    </div>
  );
}
