// import React from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import { IconButton } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
// import { Drawer } from "@mui/material";
// import InvoiceTaskCard from "./InvoiceTaskCardDrawer";

// const useStyles = makeStyles((theme) => ({
//   drawerContainer: {
//     width: "100vw",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   drawerSecUp: {
//     width: "95%",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "start",
//   },

//   drawerSecDown: {
//     width: "95%",
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "start",
//     gap: "1rem",
//   },

//   bigText: {
//     fontSize: "1.4rem",
//     fontWeight: "bold",
//   },
// }));

// const AttachTaskDrawer = ({
//   open,
//   setOpen,
//   selectedTasks,
//   selectedCheckedTasks,
//   setSelectedCheckedTasks,
//   selectedTaskFiles,
//   txId,
//   setSelectedTaskFiles,
// }) => {
//   console.log(selectedTasks);
//   const classes = useStyles();
//   return (
//     <Drawer anchor={"right"} open={open}>
//       <div className={classes.drawerContainer}>
//         <div className={classes.drawerSecUp}>
//           <span className={classes.bigText}>Tasks</span>
//           <span onClick={() => setOpen(false)}>
//             <IconButton aria-label="close">
//               <CloseIcon />
//             </IconButton>
//           </span>
//         </div>

//         <div className={classes.drawerSecDown}>
//           {selectedTasks?.length > 0 ? (
//             <>
//               {selectedTasks?.map((task) => (
//                 <InvoiceTaskCard
//                   selectedTaskFiles={selectedTaskFiles}
//                   setSelectedTaskFiles={setSelectedTaskFiles}
//                   setSelectedCheckedTasks={setSelectedCheckedTasks}
//                   selectedCheckedTasks={selectedCheckedTasks}
//                   key={task?._id}
//                   txId={txId}
//                   task={task}
//                 />
//               ))}
//             </>
//           ) : (
//             <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
//               No task found!
//             </span>
//           )}
//         </div>
//       </div>
//     </Drawer>
//   );
// };

// export default AttachTaskDrawer;

import React, { useState , useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Drawer } from "@mui/material";
import InvoiceTaskCard from "./InvoiceTaskCardDrawer";
import dayjs from "dayjs";
import SearchTaskComponent from "../../planning/tasks/components/SearchTaskComponent";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  drawerContent: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
  },
  searchSection: {
    width: "30%",
    height: "100%",
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    overflowY: "auto",
  },
  tasksSection: {
    display: 'flex',
    width: '70%',
    height: "100",
    padding: theme.spacing(2),
    overflowY: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'start',
    gap: '1rem',
  },
  bigText: {
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
  taskCardWrapper: {
    marginBottom: '1rem',
  },
}));

const AttachTaskDrawer = ({
  open,
  setOpen,
  selectedTasks,
  selectedCheckedTasks,
  setSelectedCheckedTasks,
  selectedTaskFiles,
  txId,
  setSelectedTaskFiles,
  projectId,
  parentModel
}) => {
  const classes = useStyles();
  const [filteredTasks, setFilteredTasks] = useState(null);

  useEffect(() => {
    if (selectedCheckedTasks.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedCheckedTasks]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Returns "YYYY-MM-DD"
  };

  const handleSearch = (searchParams) => {
    const filtered = selectedTasks.filter(task => {
      
  
      const nameMatch = task.taskName.toLowerCase().includes(searchParams.taskName.toLowerCase());
      
      const startDateMatch = !searchParams.startDate || 
        formatDate(task.startDate) === formatDate(searchParams.startDate);
      
      const endDateMatch = !searchParams.endDate || 
        formatDate(task.endDate) === formatDate(searchParams.endDate);
  
     
  
      return nameMatch && startDateMatch && endDateMatch
    });
    setFilteredTasks(filtered);
  };

  const tasksToDisplay = filteredTasks || selectedTasks;

  return (
    <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
      <div className={classes.drawerContainer}>
        <div className={classes.drawerHeader}>
          <span className={classes.bigText}>Tasks</span>
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        
        <div className={classes.drawerContent}>
          <div className={classes.searchSection}>
            <SearchTaskComponent 
              onSearch={handleSearch}
              projectId={projectId}
            />
          </div>
          
          <div className={classes.tasksSection}>
          {tasksToDisplay.length > 0 ? (
              tasksToDisplay.map((task) => (
                <div key={task?._id} className={classes.taskCardWrapper}>
                <InvoiceTaskCard
                  selectedTaskFiles={selectedTaskFiles}
                  setSelectedTaskFiles={setSelectedTaskFiles}
                  setSelectedCheckedTasks={setSelectedCheckedTasks}
                  selectedCheckedTasks={selectedCheckedTasks}
                  key={task?._id}
                  txId={txId}
                  task={task}
                  parentModel = {parentModel}
                />
                </div>
              ))
            ) : (
              <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                No tasks found!
              </span>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AttachTaskDrawer;
