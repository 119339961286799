import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { IconButton, ClickAwayListener } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDebounce } from 'react-use';
import Api from '../../../../../helpers/Api';
import arrayToReducer from '../../../../../helpers/arrayToReducer';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutocompleteWithSeveralCreates from '../../../../styled/CommonComponents/AutocompleteWithSeveralCreates';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CreateDiscountOrTax from './Create.DiscountOrTax';
import { createOfferingRelationWithOffering } from '../../../commonComponent/transaction/api';
import OfferingCreateDrawer from '../../../../OfferingsAndProducts/Offering.Create.Drawer';
import { UNIT_OPTIONS } from "../../../../../helpers/constants"

const useStyles = makeStyles((theme) => ({
  mainInputCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "25px",
    paddingRight: "5px",
    borderRadius: "10px",
    border: "1px solid #dcdcdc",
    marginBottom: "20px",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      padding: "10px",
    }
  },
  allInputsCont: {
    width: "calc(100% - 50px)",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      "& .MuiCheckbox-root": {
        height: "40px"
      }
    }
  },
  deleteCont: {
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginTop: "15px"
    }
  },
  productsCont: {
    width: "230px",
    // [theme.breakpoints.down('md')]: {
    //   width: "230px",
    //   marginBottom: "15px"
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: "35%",
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: "100%",
    // }
  },
  codeSty: {
    width: "calc(100% - 900px)",
    // [theme.breakpoints.down('md')]: {
    //   width: "calc(100% - 500px)",
    //   marginBottom: "15px"
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: "28%",
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: "100%",
    // }
  },
  chartAccCont: {
    width: "230px",
    // [theme.breakpoints.down('md')]: {
    //   width: "230px",
    //   marginBottom: "15px"
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: "35%",
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: "100%",
    // },
  },
  qtyCont: {
    width: "220px",
    position: "relative"
    // [theme.breakpoints.down('md')]: {
    //   width: "calc((100% - 90px) / 2)",
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: "calc((100% - 60px) / 2)",
    //   marginBottom: "20px"
    // },
  },
  unitCont: {
    position: "absolute",
    top: "8px",
    right: "8px",
    width: "100px",
    height: "25px",
    zIndex: "99",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  showUnitOptionCont: {
    width: "90px",
    height: "350px",
    overflowY: "auto",
    position: "absolute",
    zIndex: "999",
    top: "35px",
    right: "-2px",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
    transition: "all .2s ease-in-out"
  },
  hideUnitOptionCont: {
    width: "90px",
    height: "0px",
    overflowY: "hidden",
    position: "absolute",
    zIndex: "999",
    top: "35px",
    right: "-2px",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
    transition: "all .2s ease-in-out"
  },
  unitOptionSty: {
    padding: "3px 2px",
    textAlign: "left",
    fontSize: "14px",
    cursor: "pointer",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#ececec"
    }
  },
  rateSty: {
    width: "180px"
  },
  taxTotalDescCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  taxTotalCont: {
    width: "420px",
    marginTop: "20px",
  },
  taxCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputAutocomplete: {
    width: "calc(100% - 45px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    transition: "all .2s ease-in-out",
  },
  inputCont: {
    width: "45%",
    position: "relative",
  },
  totalSty: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  onlyTotalSty: {
    width: "48%",
    marginTop: "15px",
  },
  totalWithTaxSty: {
    width: "48%",
    marginTop: "15px",
  },
  taxType: {
    width: "25px",
    height: "25px",
    position: "absolute",
    top: "9px",
    right: "5px",
    zIndex: "10",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "510",
    cursor: "pointer",
  },
  taxAutoComplete: {
    width: "53%"
  },
  dropdownCont: {
    position: "absolute",
    zIndex: "20",
    right: "6px",
    top: "9px",
    backgroundColor: "white",
    width: "56px",
    height: "25px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all .2s ease-in-out",
    "& p": {
      padding: "2px 9px",
      fontWeight: "510",
      cursor: "pointer",
    }
  },
  hideDropDownCont: {
    position: "absolute",
    zIndex: "20",
    right: "6px",
    top: "9px",
    backgroundColor: "white",
    width: "0px",
    height: "25px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all .2s ease-in-out",
    "& p": {
      padding: "2px 9px",
      fontWeight: "510",
      cursor: "pointer",
    }
  },
  descSty: {
    width: "calc(100% - 435px)",
    marginTop: "20px"
  }
}));

export default function EditBillListItem({
  itemId, offeringRelations, billItemDict, setBillItemDict, billList,
  setBillList, tx, totalLen, index, setOfferingRelations, setLoadingBool,
  chartAccounts, setChartAccounts, taxes, isIndividualTax, setTaxes,
  libraryData, categoriesData, setCategoriesData, needToMakeDisable,
  incomeChartAccounts, setIncomeChartAccounts, expenseChartAccounts,
  setExpenseChartAccounts
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { walletId } = useParams();

  const { user } = useSelector((state) => state.auth);
  const billItemOld = billItemDict[itemId];

  //other datas of bill items
  const [itemObject, setItemObject] = useState(billItemOld);
  const [offeringRelationValue, setOfferingRelationValue] = useState(billItemOld?.offeringRelation || null);
  const [selectedChartAccount, setSelectedChartAccount] = useState(billItemOld?.chartAccount || null);
  const [qty, setQty] = useState(billItemOld?.qTy || 1);
  const [rate, setRate] = useState(billItemOld?.rate || 0);
  const [code, setCode] = useState(billItemOld?.code || "");
  const [openUnit, setOpenUnit] = useState(false)
  const [unit, setUnit] = useState(billItemOld?.unit || "unit");
  const [desc, setDesc] = useState(billItemOld?.description || " ");

  //check box for tax
  const [isTax, setIsTax] = useState(billItemOld?.tax || false)
  //tax relation from bill item
  const [taxValueObj, setTaxValueObj] = useState(billItemOld?.taxObj || null)
  const [taxValueRelation, setTaxValueRelation] = useState(billItemOld?.taxRelationModel || null)
  const [taxCreateOpen, setCreateTaxOpen] = useState(false)
  const [taxCreateLoading, setTaxCreateLoading] = useState(false);
  const [taxText, setTaxText] = useState("")
  //to handle "%" or "₹" type for tax
  const [showTaxType, setShowTaxType] = useState(false)
  const [taxTypeValue, setTaxTypeValue] = useState(billItemOld?.taxObj?.type || "%")
  const [taxTypeAmount, setTaxTypeAmount] = useState(billItemOld?.taxObj?.amount || 0)
  const [taxTypePercent, setTaxTypePercent] = useState(billItemOld?.taxObj?.percent || 0)
  const [createOfferingDawer, setCreateOfferingDrawer] = useState(false)
  const [unitsArr, setUnitsArr] = useState([])

  //calculated total with tax
  const [totalWithTax, setTotalWithTax] = useState(0)

  //to stope initial api call
  const [initialDStopper1, setInitialDStopper1] = useState(false)

  useEffect(() => {
    let arr = [];
    Object.keys(UNIT_OPTIONS.units).map((unitGroup) => {
      let groupUntitArr = UNIT_OPTIONS.units[unitGroup] || []
      groupUntitArr.map((unitData) => {
        arr.push(unitData?.unit.toLowerCase())
      })
    })

    setUnitsArr(arr)
  }, [UNIT_OPTIONS])

  useEffect(() => {
    if (billItemOld?.offeringRelation) {
      setOfferingRelationValue({
        name: billItemOld?.offeringRelation?.offering?.name,
        category: { _id: billItemOld?.offeringRelation?.category },
        code: billItemOld?.offeringRelation?.offering?.code,
        codeLibrary: { _id: billItemOld?.offeringRelation?.library },
        libraryLable: "",
        isPublic: billItemOld?.offeringRelation?.offering?.isPublic,
        unit: billItemOld?.offeringRelation?.offering?.unit,
        taxType: billItemOld?.offeringRelation?.offering?.taxType,
        tax: billItemOld?.offeringRelation?.offering?.tax,
        doSell: billItemOld?.offeringRelation?.offering?.doSell,
        incomeDescription: billItemOld?.offeringRelation?.offering?.incomeDescription,
        doPurchase: billItemOld?.offeringRelation?.offering?.doPurchase,
        expenseDescription: billItemOld?.offeringRelation?.offering?.expenseDescription,
        model: billItemOld?.offeringRelation?.offering?.model,
        default: billItemOld?.offeringRelation?.offering?.default,
        price: billItemOld?.offeringRelation?.offering?.price,
        purchasePrice: billItemOld?.offeringRelation?.offering?.purchasePrice,
        purchaseUnit: billItemOld?.offeringRelation?.offering?.purchaseUnit,
        isInventory: billItemOld?.offeringRelation?.offering?.isInventory,
        profileArr: billItemOld?.offeringRelation?.offering?.profileArr,
        profile: billItemOld?.offeringRelation?.offering?.profile,
        shared: billItemOld?.offeringRelation?.offering?.shared,
        addedBy: billItemOld?.offeringRelation?.offering?.addedBy,
        createdAt: billItemOld?.offeringRelation?.offering?.createdAt,
        updatedAt: billItemOld?.offeringRelation?.offering?.updatedAt,
        offeringRelation: billItemOld?.offeringRelation,
        incomeChartAccount: billItemOld?.offeringRelation?.incomeChartAccount,
        expenseChartAccount: billItemOld?.offeringRelation?.expenseChartAccount
      })
    }
  }, [billItemOld?.offeringRelation || null])

  useEffect(() => {
    let totalVal = parseInt(qty * rate)
    let calculatedTotal = 0;
    if (isTax && isIndividualTax) {
      if (taxTypeValue === "%") {
        calculatedTotal = parseInt(totalVal) + (parseInt(totalVal) * (parseInt(taxTypePercent) / 100))
      } else {
        calculatedTotal = parseInt(totalVal) + parseInt(taxTypeAmount)
      }
    } else {
      calculatedTotal = parseInt(totalVal);
    }
    setTotalWithTax(calculatedTotal)
  }, [qty, rate, taxTypeValue, taxTypePercent, taxTypeAmount, isTax, isIndividualTax])

  // to update bill list item
  const updateApi = async (obj) => {
    if (!needToMakeDisable) {
      await Api.post('wallet/billList/update/item', obj)
        .then((res) => {
          const item = res?.data;
          const { newDict } = arrayToReducer([item]);

          setBillItemDict({
            ...billItemDict,
            ...newDict,
          });
        });
    }
  };

  useDebounce(() => {
    if (initialDStopper1 && !needToMakeDisable) {
      const isEqual = _.isEqual(itemObject, billItemOld);
      if (!isEqual) {
        updateApi(itemObject);
      }
    } else {
      setInitialDStopper1(true)
    }
  }, 1000, [itemObject, itemObject?.offeringRelation,
    itemObject?.chartAccount, itemObject?.type,
    itemObject?.name, itemObject?.description,
    itemObject?.qTy, itemObject?.rate, itemObject?.tax
  ]);

  // to update product and service
  const onSelectProductService = async (selected, newlyCreated = false) => {
    if (selected && selected._id && !needToMakeDisable) {
      let selectedOffering = {};
      if (selected?.offeringRelation?._id) {
        selectedOffering = {
          ...itemObject,
          offeringRelation: selected?.offeringRelation,
          chartAccount: (tx?.type === "Bill" || tx?.type === "CreditNote") ? selected?.expenseChartAccount : selected?.incomeChartAccount,
          type: selected.model,
          name: selected.name,
          description: (tx?.type === "Bill" || tx?.type === "CreditNote") ? selected.expenseDescription : selected.incomeDescription,
          qTy: 1,
          code: selected?.code,
          unit: (tx?.type === "Bill" || tx?.type === "CreditNote") ? selected.purchaseUnit : selected?.unit,
          rate: (tx?.type === "Bill" || tx?.type === "CreditNote") ? selected?.purchasePrice : selected?.price,
        };
      } else {
        let updateOfferingRelationObj = {
          profile: tx?.firstParty?._id,
          shared: [tx?.firstParty?._id],
          user: user?._id,
          wallet: tx?.firstPartyWallet?._id,
          offering: selected?._id
        }
        if (tx?.defaultIncomeAcc?._id) {
          updateOfferingRelationObj.incomeChartAccount = tx?.defaultIncomeAcc?._id
        }
        if (selected?.category?._id) {
          updateOfferingRelationObj.category = selected?.category?._id
        }
        if (selected?.codeLibrary?._id) {
          updateOfferingRelationObj.library = selected?.codeLibrary?._id
        }
        if (selected?.doPurchase) (
          updateOfferingRelationObj.expenseChartAccount = tx?.defaultExpenseAcc?._id
        )
        await createOfferingRelationWithOffering(updateOfferingRelationObj)
          .then((newRelation) => {
            selectedOffering = {
              ...itemObject,
              offeringRelation: newRelation?.offeringRelation,
              chartAccount: (tx?.type === "Bill" || tx?.type === "CreditNote") ? newRelation?.expenseChartAccount : newRelation?.incomeChartAccount,
              type: newRelation.model,
              name: newRelation.name,
              description: (tx?.type === "Bill" || tx?.type === "CreditNote") ? newRelation.expenseDescription : newRelation.incomeDescription,
              qTy: 1,
              code: newRelation?.code,
              unit: (tx?.type === "Bill" || tx?.type === "CreditNote") ? newRelation.purchaseUnit : newRelation?.unit,
              rate: (tx?.type === "Bill" || tx?.type === "CreditNote") ? newRelation?.purchasePrice : newRelation?.price,
            };
          })
          .catch((err) => {
            console.log(err)
          })
      }

      setOfferingRelationValue(selectedOffering?.offeringRelation);
      setDesc(selectedOffering.description);
      setQty(1);
      setRate(selectedOffering.rate);
      setSelectedChartAccount(selectedOffering?.chartAccount)
      setItemObject(selectedOffering);
      setCode(selectedOffering?.code || "");
      setUnit(selectedOffering?.unit || "");
      if (newlyCreated) {
        const productArr = [...offeringRelations, selected]
        setOfferingRelations(productArr)
      }
    }
  };

  // to update category (chart account)
  const categorySelected = (category) => {
    if (!needToMakeDisable) {
      const selectedOffering = {
        ...itemObject,
        chartAccount: category,
      };

      setSelectedChartAccount(category)
      setItemObject(selectedOffering);
    }
  }

  // to delete current item
  const deleteItem = async () => {
    if (billList?._id && !needToMakeDisable) {
      setLoadingBool(true);
      const itemOld = billList.items;
      const newItems = _.difference(itemOld, [itemId]);
      setBillList({
        ...billList,
        items: newItems,
      });
      await Api.post('wallet/billList/delete/item', {
        billId: billList._id,
        itemId,
      }).then((res) => {
        console.log("res")
      });
      setLoadingBool(false);
    }
  };

  //select tax
  const selectTax = (selected, newlyCreated = false) => {
    if (selected && selected._id && !needToMakeDisable) {
      let newBillTaxObj = {
        enabled: true,
        type: selected?.type,
      };
      if (selected.type === "%") {
        newBillTaxObj.percent = selected?.percent
        setTaxTypeValue("%")
      } else if (selected.type === "$") {
        newBillTaxObj.amount = selected?.amount
        setTaxTypeValue("₹")
      }
      setTaxTypeAmount(selected?.amount || 0)
      setTaxTypePercent(selected?.percent || 0)
      setTaxValueObj(newBillTaxObj)
      setTaxValueRelation(selected)
      setItemObject({
        ...itemObject,
        taxRelationModel: selected,
        taxObj: newBillTaxObj
      });
      if (newlyCreated) {
        const taxesArr = [...taxes, selected]
        setTaxes(taxesArr)
      }
    }
  }

  const onClickTaxCheckBox = (event) => {
    if (!needToMakeDisable) {
      setIsTax(event.target.checked)
      setItemObject({
        ...itemObject,
        tax: event.target.checked,
      });
      if (event.target.checked) {
        if (!itemObject?.taxRelationModel) {
          const deafultTax = taxes[1]

          let newTaxObj = {
            enabled: true,
            type: deafultTax?.type,
          };

          if (deafultTax.type === "%") {
            newTaxObj.percent = deafultTax?.percent
            setTaxTypeValue("%")
          } else if (deafultTax.type === "$") {
            newTaxObj.amount = deafultTax?.amount
            setTaxTypeValue("₹")
          }

          setTaxTypeAmount(deafultTax?.amount || 0)
          setTaxTypePercent(deafultTax?.percent || 0)
          setTaxValueObj(newTaxObj)
          setTaxValueRelation(deafultTax)

          setItemObject({
            ...itemObject,
            taxRelationModel: deafultTax,
            tax: true,
            taxObj: newTaxObj
          });

        }
      }
    }
  }

  return (
    <div className={classes.mainInputCont} >
      <div className={classes.allInputsCont} >
        {/* Product & Services */}
        <div className={classes.productsCont} >
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            disabled={needToMakeDisable}
            value={offeringRelationValue}
            options={offeringRelations}
            disableClearable
            getOptionLabel={(option) => { return option?.name || " " }}
            getOptionSelected={(option) => {
              return option?._id === offeringRelationValue?._id
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ margin: "0px" }}
                margin="normal"
                variant="outlined"
                placeholder="Select Product & Services"
                label="Product/Services"
              />
            )}
            onChange={(event, value) => {
              if (value?._id === "New") {
                event.preventDefault();
                setCreateOfferingDrawer(true)
                setOfferingRelationValue(offeringRelationValue)
              } else {
                onSelectProductService(value, false)
              }
            }}
            style={{ width: "100%" }}
            size="small"
          />
          {!needToMakeDisable && (
            <OfferingCreateDrawer
              purchase={true}
              selling={false}
              library={libraryData}
              profileId={tx?.firstParty?._id}
              createOfferingDawer={createOfferingDawer}
              setCreateOfferingDrawer={setCreateOfferingDrawer}
              afterOfferingCreateOrEdit={(offeringRelation, isEdit) => {
                onSelectProductService(offeringRelation, true)
              }}
            />
          )}
        </div>

        {/* Product & Services code */}
        <TextField
          placeholder="Enter code"
          label="Code"
          size="small"
          variant="outlined"
          disabled={needToMakeDisable}
          onChange={(e) => {
            setCode(e.target.value)
            setItemObject({
              ...itemObject,
              code: e.target.value,
            });
          }}
          value={code}
          className={classes.codeSty}
        />

        {/* Category(Chart Account) */}
        <Autocomplete
          id="grouped-demo"
          freeSolo
          value={selectedChartAccount}
          options={chartAccounts}
          getOptionLabel={(option) => { return option?.name || " "; }}
          groupBy={(option) => option.classification}
          disableClearable
          disabled={needToMakeDisable}
          getOptionSelected={(option) => {
            return option?._id === selectedChartAccount?._id
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Enter Category"
              label="Category"
            />
          )}
          onChange={(event, value) => {
            categorySelected(value)
          }}
          className={classes.chartAccCont}
          size="small"
        />

        {/* Quantity */}
        <ClickAwayListener onClickAway={() => { setOpenUnit(false) }} >
          <div className={classes.qtyCont} >
            <TextField
              id="outlined-basic"
              label="qTy"
              variant="outlined"
              size="small"
              type="number"
              value={qty}
              disabled={needToMakeDisable}
              onChange={(e) => {
                setQty(e.target.value)
                setItemObject({
                  ...itemObject,
                  qTy: e.target.value,
                });
              }}
              style={{ width: "100%" }}
            />
            <div
              className={classes.unitCont}
              onClick={() => {
                if (!needToMakeDisable) {
                  setOpenUnit(!openUnit)
                }
              }}
            >
              {unit}
            </div>
            <div className={openUnit ? classes.showUnitOptionCont : classes.hideUnitOptionCont} >
              <div>
                {unitsArr && unitsArr.map((unitData) => (
                  <p
                    onClick={() => {
                      setUnit(unitData)
                      setOpenUnit(false)
                      setItemObject({
                        ...itemObject,
                        unit: unitData,
                      });
                    }}
                    className={classes.unitOptionSty}
                    style={unitData === unit ? { backgroundColor: "#ececec" } : { backgroundColor: "white" }}
                  >
                    {unitData}
                  </p>))}
              </div>
            </div>
          </div>
        </ClickAwayListener>

        {/* rate per unit */}
        <TextField
          placeholder="Enter Rate"
          label="Rate"
          size="small"
          variant="outlined"
          type="number"
          disabled={needToMakeDisable}
          onChange={(e) => {
            setRate(e.target.value)
            setItemObject({
              ...itemObject,
              rate: e.target.value,
            });
          }}
          value={rate}
          className={classes.rateSty}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">₹</InputAdornment>
            ),
          }}
        />

        {/* Tax and total */}
        <div className={classes.taxTotalCont} >
          <div className={classes.taxCont} >
            <Checkbox
              color="primary"
              checked={isTax}
              disabled={needToMakeDisable}
              onChange={onClickTaxCheckBox}
            />
            <div className={classes.inputAutocomplete} >
              <ClickAwayListener onClickAway={() => { setShowTaxType(false) }}>
                <div className={classes.inputCont} >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    size="small"
                    placeholder="Enter value"
                    style={{ width: "100%" }}
                    value={taxTypeValue === "%" ? taxTypePercent : taxTypeAmount}
                    disabled={needToMakeDisable ? true : isTax && isIndividualTax ? false : true}
                    onChange={(e) => {
                      if (taxTypeValue === "%") {
                        let curVal = e.target.value
                        if (curVal < 0) {
                          curVal = 0
                        } else if (curVal > 100) {
                          curVal = 100
                        }
                        setTaxTypePercent(curVal)
                        let taxObj = itemObject?.taxObj
                        setItemObject({
                          ...itemObject,
                          taxObj: {
                            ...taxObj,
                            percent: curVal
                          }
                        });
                      } else {
                        let curVal = parseInt(e.target.value)
                        if (curVal < 0) {
                          curVal = 0
                        } else if (curVal > (parseInt(qty) * parseInt(rate))) {
                          curVal = parseInt(qty) * parseInt(rate)
                        }
                        setTaxTypeAmount(curVal)
                        let taxObj = itemObject?.taxObj
                        setItemObject({
                          ...itemObject,
                          taxObj: {
                            ...taxObj,
                            amount: curVal
                          }
                        });
                      }
                    }}
                  />
                  <div
                    className={classes.taxType}
                    style={isTax && isIndividualTax ? { opacity: "1" } : { opacity: "0.2" }}
                    onClick={() => {
                      if (isTax && isIndividualTax && !needToMakeDisable) {
                        setShowTaxType(!showTaxType)
                      }
                    }}
                  >
                    {taxTypeValue}
                  </div>
                  <div className={showTaxType ? classes.dropdownCont : classes.hideDropDownCont} >
                    <p
                      onClick={() => {
                        setShowTaxType(false);
                        setTaxTypeValue("₹");
                        let taxObj = itemObject?.taxObj
                        setItemObject({
                          ...itemObject,
                          taxObj: {
                            ...taxObj,
                            type: "$"
                          }
                        });
                      }}
                    >₹</p>
                    <p
                      onClick={() => {
                        setShowTaxType(false);
                        setTaxTypeValue("%")
                        let taxObj = itemObject?.taxObj
                        setItemObject({
                          ...itemObject,
                          taxObj: {
                            ...taxObj,
                            type: "%"
                          }
                        });
                      }}
                    >%</p>
                  </div>
                </div>
              </ClickAwayListener>
              <div className={classes.taxAutoComplete} >
                <AutocompleteWithSeveralCreates
                  value={taxValueRelation}
                  offerings={taxes}
                  placeholder={"Select Tax"}
                  text={taxText}
                  setText={setTaxText}
                  open={taxCreateOpen}
                  setOpen={setCreateTaxOpen}
                  loading={taxCreateLoading}
                  setLoading={setTaxCreateLoading}
                  getOptionLabel={(option) => { return option?.name || " " }}
                  onSelect={selectTax}
                  isdisabled={needToMakeDisable ? true : isTax && isIndividualTax ? false : true}
                  CreateDialogBox={
                    <CreateDiscountOrTax
                      setOpen={setCreateTaxOpen}
                      setLoading={setTaxCreateLoading}
                      tx={tx}
                      createType={"Tax"}
                      text={taxText}
                      onSelect={selectTax}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.totalSty} >
            <TextField
              label="Total"
              size="small"
              variant="outlined"
              type="number"
              value={itemObject?.qTy * itemObject?.rate}
              className={classes.onlyTotalSty}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              disabled
            />

            <TextField
              label="Total with tax"
              size="small"
              variant="outlined"
              type="number"
              value={totalWithTax}
              className={classes.totalWithTaxSty}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              disabled
            />
          </div>
        </div>

        {/* description */}
        <TextField
          id="outlined-multiline-static"
          label="Description"
          size="small"
          multiline
          rows={4}
          variant="outlined"
          value={desc}
          disabled={needToMakeDisable}
          onChange={(e) => {
            setDesc(e.target.value)
            setItemObject({
              ...itemObject,
              description: e.target.value
            });
          }}
          className={classes.descSty}
        />
      </div>

      {totalLen === 1 ? (<div></div>) : (<div className={classes.deleteCont} >
        <IconButton
          onClick={() => {
            deleteItem();
          }}
          disabled={needToMakeDisable}
        >
          <DeleteIcon />
        </IconButton>
      </div>)}
    </div>
  );
}