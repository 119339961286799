import React from 'react';
import totalBilllist from './total.billlist';
import { useDebounce } from "react-use";

export default function useTotalBlocks({ tx, updateApi }) {
  const { subTotal, totalTax, totalDiscount, grandTotal } = totalBilllist(tx);

  useDebounce(
    () => {
      if (updateApi) {
        if (tx?.amount != subTotal || tx?.finalAmount != Number(grandTotal)?.toFixed(2)) {
          updateApi({
            _id: tx?._id,
            amount: subTotal,
            finalAmount: Number(grandTotal)?.toFixed(2)
          })
        }
      }
    },
    1000,
    [subTotal, grandTotal]
  );

  const SubTotalView = (
    <span>
      ₹{Number(subTotal)?.toFixed(1)}
    </span>
  );

  const TaxView = (
    <span>
      ₹{Number(totalTax)?.toFixed(1)}
    </span>
  );

  const DiscountView = (
    <span>
      ₹{Number(totalDiscount)?.toFixed(1)}
    </span>
  );

  const GrandTotalView = (
    <span>
      ₹{Number(grandTotal)?.toFixed(1)}
    </span>
  );

  return {
    SubTotalView, TaxView, DiscountView, GrandTotalView, grandTotal
  };
}