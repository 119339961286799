import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ReactToPrint from 'react-to-print';
import useTotalBlocks from '../../expenseAndBills/bills/BillList/total.hook';
import NormalDialog from "../../../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles((theme) => ({
    dialogCont: {
        width: "100%",
        height: "70vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "50px 0px",
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('sm')]: {
            overflowX: "auto", 
            alignItems: "flex-start",
        }
    },
    pdfCont: {
        width: "800px",
        padding: "8px 15px",
        paddingLeft: "25px",
    },
    logoCont: {
        fontSize: "35px",
        fontWeight: "700",
        color: theme.palette.primary.main
    },
    compName: {
        fontSize: "17px",
        fontWeight: "550",
    },
    invoiceDataCont: {
        width: "100%",
        height: "80px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "45px"
    },
    contLeft: {
        width: "65%",
        height: "100%",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        color: "white",
        paddingLeft: "15px",
        "& p": {
            fontSize: "23px",
            fontWeight: "550"
        }
    },
    contRight: {
        width: "35%",
        height: "100%",
        backgroundColor: "#4d4c4c",
        color: "white",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        paddingLeft: "15px",
        "& p": {
            fontSize: "15px"
        }
    },
    allAddressCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "45px"
    },
    addressCont: {
        width: "42%"
    },
    addHeader: {
        borderBottom: "2px solid black",
        fontSize: "16px",
        fontWeight: "550"
    },
    tableCont: {
        width: "100%",
        border: "1px solid black",
        borderCollapse: "collapse",
        marginTop: "40px"
    },
    headerRow: {
        backgroundColor: "#4d4c4c",
        color: "white",
        height: "30px"
    },
    header_title: {
        padding: "0px",
        height: "25px",
        padding: "0px",
        fontSize: "15px",
        fontWeight: "400"
    },
    displayFlex: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "-1px"
    },
    noteCont: {
        width: "70%",
        padding: '10px',
        border: "1px solid #E1E2E5",
    },
}));

function BillPrintDialog(props) {
    const { openPrint, setOpenPrint, tx, updateApi } = props;
    const componentRef = useRef();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { SubTotalView, TaxView, DiscountView, GrandTotalView } = useTotalBlocks({ tx, updateApi });

    const handleClose = () => { setOpenPrint(false) }

    const getPageMargins = () => {
        return `@page { margin: 10px 5px 30px 5px !important; }`;
    };

    return (
        <NormalDialog
            openDialog={openPrint}
            handleCloseShare={handleClose}
            pageTitle={`Print`}
            content={<>
                <div className={classes.dialogCont} >
                    <div className={classes.pdfCont} ref={componentRef} >
                        <style>{getPageMargins()}</style>
                        <div>
                            <p className={classes.logoCont} >
                                {tx?.firstPartyWallet?.printLogo?.url ? (
                                    <img
                                        src={tx?.firstPartyWallet?.printLogo?.url}
                                        style={{
                                            height: "70px",
                                            width: "auto"
                                        }}
                                    />
                                ) : (
                                    <i>LOGO</i>
                                )}
                            </p>
                            <p className={classes.compName} style={{ marginTop: "-7px" }} >{tx?.firstParty?.parent?.displayName || "Company Name"}</p>
                            <p style={{ fontSize: "14px", fontWeight: "510" }} >Company No : {tx?.firstParty?.parent?.companyNo || "XX-XXXXX"}</p>
                        </div>

                        <div className={classes.invoiceDataCont} >
                            <div className={classes.contLeft} >
                                <p>INVOICE NO : </p>
                                <p>#{tx?.txNo}</p>
                            </div>
                            <div className={classes.contRight} >
                                <p>Date : {new Date(tx?.createdAt).getMonth() + 1 + "/" + new Date(tx?.createdAt).getDate() + "/" + new Date(tx?.createdAt).getFullYear()}</p>
                                <p>Due Date : <span style={{ fontSize: "16px", fontWeight: "550" }} >{new Date(tx?.dueDate).getMonth() + 1 + "/" + new Date(tx?.dueDate).getDate() + "/" + new Date(tx?.dueDate).getFullYear()}</span></p>
                                <p>Term : {tx?.term?.name}</p>
                            </div>
                        </div>

                        <div className={classes.allAddressCont} >
                            <div className={classes.addressCont} >
                                <p className={classes.addHeader} >Bill From:</p>
                                <p>{tx?.firstParty?.parent?.displayName || "Company Name"}</p>
                                <p>{tx?.firstParty?.parent?.address?.streetAddress || "Street Address"}</p>
                                <p>{tx?.firstParty?.parent?.address?.city || "City"}{" "}
                                    {tx?.firstParty?.parent?.address?.region || "State"}{" "}
                                    {tx?.firstParty?.parent?.address?.zip || "ZIP Code"}
                                </p>
                                <p>{tx?.firstParty?.parent?.address?.country || "Country"}</p>
                                <p>T:{" "}{tx?.firstParty?.parent?.phone}</p>
                            </div>
                            <div className={classes.addressCont} >
                                <p className={classes.addHeader} >Bill To:</p>
                                <p>{tx?.secondParty?.parent?.displayName || "Company Name"}</p>
                                <p>{tx?.secondParty?.parent?.address?.streetAddress || "Street Address"}</p>
                                <p>{tx?.secondParty?.parent?.address?.city || "City"}{" "}
                                    {tx?.secondParty?.parent?.address?.region || "State"}{" "}
                                    {tx?.secondParty?.parent?.address?.zip || "ZIP Code"}
                                </p>
                                <p>{tx?.secondParty?.parent?.address?.country || "Country"}</p>
                                <p>T:{" "}{tx?.secondParty?.parent?.phone}</p>
                            </div>
                        </div>

                        <table className={classes.tableCont} >
                            <tr
                                className={classes.headerRow}
                                style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse"
                                }}
                            >
                                <th className={classes.header_title} style={{ width: "40px" }}  >#</th>
                                <th className={classes.header_title} style={{ width: "100px" }} >Item Name</th>
                                <th className={classes.header_title} style={{ width: "140px" }} >Description</th>
                                <th className={classes.header_title} style={{ width: "60px" }} >Qty</th>
                                <th className={classes.header_title} style={{ width: "80px" }} >Unit Price</th>
                                <th className={classes.header_title} style={{ width: "90px" }} >Total</th>
                            </tr>
                            {tx && tx?.billList?.items && tx?.billList?.items.length > 0 && tx?.billList?.items.map((item, i) => (
                                <tr 
                                key={i}
                                style={{
                                    height: "30px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse"
                                }} 
                                >
                                    <td style={{
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{i + 1}</td>
                                    <td style={{
                                        textAlign: "left",
                                        paddingLeft: "5px",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{item?.name}</td>
                                    <td style={{
                                        textAlign: "left",
                                        paddingLeft: "5px",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{item?.description}</td>
                                    <td style={{
                                        textAlign: "right",
                                        paddingRight: "5px",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{item?.qTy}</td>
                                    <td style={{
                                        textAlign: "right",
                                        paddingRight: "5px",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >₹{item?.rate}</td>
                                    <td style={{
                                        textAlign: "right",
                                        paddingRight: "5px",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >
                                        ₹{parseInt(item?.qTy) * parseInt(item?.rate)}
                                        {item?.tax ? (<span style={{ fontSize: "14px", fontWeight: "550" }}  >{"(T)"}</span>) : null}
                                    </td>
                                </tr>
                            ))}
                        </table>

                        {/* total related details */}
                        <div className={classes.displayFlex} >
                            <div></div>
                            <table
                                style={{
                                    width: "250px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tr style={{
                                    height: "30px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                    fontSize: "12px"
                                }} >
                                    <td style={{
                                        width: "119px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        backgroundColor: "#4d4c4c",
                                        color: "white",
                                        fontSize: "12px"
                                    }} >SUBTOTAL</td>
                                    <td style={{
                                        width: "134px",
                                        textAlign: "right",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{SubTotalView}</td>
                                </tr>
                            </table>
                        </div>

                        <div className={classes.displayFlex} >
                            <div></div>
                            <table
                                style={{
                                    width: "250px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tr style={{
                                    height: "30px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse"
                                }} >
                                    <td style={{
                                        width: "210px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        backgroundColor: "#4d4c4c",
                                        color: "white",
                                        fontSize: "12px"
                                    }} >DISCOUNT RATE</td>
                                    <td style={{
                                        width: "89px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >
                                        {tx?.billList?.tax?.type === "%" ? (
                                            <>{tx?.billList?.tax?.percent}%</>
                                        ) : (
                                            <>₹{tx?.billList?.tax?.amount}</>
                                        )}
                                    </td>
                                    <td style={{
                                        width: "119px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        backgroundColor: "#4d4c4c",
                                        color: "white",
                                        fontSize: "12px"
                                    }} >DISCOUNT</td>
                                    <td style={{
                                        width: "134px",
                                        textAlign: "right",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{DiscountView}</td>
                                </tr>
                            </table>
                        </div>

                        <div className={classes.displayFlex} >
                            <div></div>
                            <table
                                style={{
                                    width: "250px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tr style={{
                                    height: "30px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse"
                                }} >
                                    <td style={{
                                        width: "210px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        backgroundColor: "#4d4c4c",
                                        color: "white",
                                        fontSize: "12px"
                                    }} >TAX RATE</td>
                                    <td style={{
                                        width: "89px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >
                                        {tx?.billList?.discount?.type === "%" ? (
                                            <>{tx?.billList?.discount?.percent}%</>
                                        ) : (
                                            <>₹{tx?.billList?.discount?.amount}</>
                                        )}
                                    </td>
                                    <td style={{
                                        width: "119px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        backgroundColor: "#4d4c4c",
                                        color: "white",
                                        fontSize: "12px"
                                    }} >TAX</td>
                                    <td style={{
                                        width: "134px",
                                        textAlign: "right",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{TaxView}</td>
                                </tr>
                            </table>
                        </div>

                        <div className={classes.displayFlex} >
                            <div></div>
                            <table
                                style={{
                                    width: "250px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tr style={{
                                    height: "30px",
                                    border: "1px solid black",
                                    borderCollapse: "collapse"
                                }} >
                                    <td style={{
                                        width: "119px",
                                        textAlign: "center",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        backgroundColor: "#4d4c4c",
                                        color: "white",
                                        fontSize: "12px"
                                    }} >GRAND TOTAL</td>
                                    <td style={{
                                        width: "134px",
                                        textAlign: "right",
                                        border: "1px solid black",
                                        borderCollapse: "collapse",
                                        fontSize: "12px"
                                    }} >{GrandTotalView}</td>
                                </tr>
                            </table>
                        </div>

                        <p style={{ fontSize: "14px", fontWeight: "400" }} >Memo</p>
                        <div className={classes.noteCont} >
                            {tx?.memo}
                        </div>

                        <p style={{ fontSize: "14px", fontWeight: "400" }} >Note</p>
                        <div className={classes.noteCont} >
                            {tx?.firstPartyWallet?.note}
                        </div>

                        <div style={{ marginTop: "50px" }} >
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "30px",
                                    fontWeight: "700"
                                }}
                            >Thank You For Your Business!</p>
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    fontWeight: "400"
                                }}
                            >If you have questions about your order, you can email us at{" "}
                                <a
                                    target="_blank"
                                    href={`mailto:${tx?.firstParty?.parent?.email}`}
                                >
                                    {tx?.firstParty?.parent?.email}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: "15px" }} >
                    <ReactToPrint
                        trigger={() => (
                            <Button color="primary" autoFocus>
                                Print
                            </Button>
                        )}
                        content={() => componentRef.current}
                    />
                </div>
            </>}
        />
    );
}
export default BillPrintDialog;