import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import emptyBill from "../../../../Assets/emptyBillItem.svg"
import BillItemAddEdit from "./Bill.Item.AddEdit";
import AddItemBtn from "../../expenseAndBills/bills/BillList/add.item.btn";

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "10px",
    marginTop: "30px",
    padding: "5px",
    "& h3": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#193B56"
    },
    [theme.breakpoints.down('sm')]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    }
  },
  addItemBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px"
  },
  allItemsCont: {
    width: "100%",
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "180px",
      width: "auto",
      marginBottom: "10px"
    },
    "& p": {
      fontSize: "14px",
      color: "gray"
    },
    "& a": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  }
}));

export default function BillListEditItemAdd({
  billList, setBillList, billItemDictionary, walletData, type,
  setBillItemDictionary, setLoadingBool, needToMakeDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <div className={classes.sectionTop} >
        <h3>Items(A)</h3>
        {needToMakeDisable ? (<div></div>) : (
          <AddItemBtn
            billList={billList}
            size={"small"}
            setBillList={setBillList}
            billItemDictionary={billItemDictionary}
            setBillItemDictionary={setBillItemDictionary}
            setLoadingBool={setLoadingBool}
            type={type === "Expense" ? "Bill" : "Invoice"}
            defaultOfferingRelation={type === "Expense" ? walletData?.defaultExpansesOfferingRelation : walletData?.defaultIncomeOfferingRelation}
            itemChartAccount={type === "Expense" ? walletData?.defaultExpenseAcc : walletData?.defaultIncomeAcc}
          />
        )}
      </div>

      {billList?.items && billList.items.length > 0 ? (
        <div className={classes.allItemsCont} >
          {billList.items.map((itemId, index) => {
            const item = billItemDictionary[itemId];
            return item?._id && (
              <BillItemAddEdit
                key={itemId}
                itemId={itemId}
                billItemDict={billItemDictionary}
                setBillItemDict={setBillItemDictionary}
                billList={billList}
                isLast={index === (billList.items.length - 1)}
                setBillList={setBillList}
                totalLen={billList.items.length}
                needToMakeDisable={needToMakeDisable}
                setLoadingBool={setLoadingBool}
                libraryData={walletData?.defaultLibrary}
                currentProfile={walletData?.profile?._id}
                currentWallet={walletData?._id}
                defaultIncomeAcc={walletData?.defaultIncomeAcc?._id}
                defaultExpenseAcc={walletData?.defaultExpenseAcc?._id}
                type={"Expense"}
              />
            )
          })}
        </div>
      ) : (<div className={classes.emptyCont} >
        <img src={emptyBill} alt="empty image" />
        <p>{type === "Expense" ? "No bill item is added. Click below button to add" : "No invoice item is added. Click below button to add"}</p>
      </div>)}
    </>
  );
}