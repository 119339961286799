import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    "& img": {
      width: "15rem",
      height: "11rem",
      height: "auto",
      margin: "3px",
      cursor: "pointer",
    },
  },
  fullImageViewr: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: "9999",
  },
  closeBtnCont: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  imageArrowCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "20px 0px",
  },
  mainImageCont: {
    width: "calc(100% - 200px)",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 80px)",
    },
  },
  arrowCont: {
    width: "100px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
    },
  },
  arrowIcon: {
    color: "white",
    fontSize: "35px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  btnSty: {
    backgroundColor: "white",
    padding: "2px",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    },
  },
}));

export default function PhotoAndFullView({ actualImages }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const [openFullView, setOpenFullView] = useState(false);
  const [size, setSize] = useState(0);
  const [curPhoto, setCurPhoto] = useState(0);

  useEffect(() => {
    if (actualImages) {
      setSize(actualImages.length);
    }
  }, [actualImages]);

  return (
    <>
      <div className={classes.root} on>
        {actualImages &&
          actualImages.map((photo, inx) => (
            <img
              src={photo?.url}
              onClick={() => {
                setCurPhoto(inx);
                setOpenFullView(true);
              }}
            />
          ))}
      </div>

      {openFullView && (
        <div className={classes.fullImageViewr}>
          <IconButton
            className={classes.closeBtnCont}
            onClick={() => {
              setOpenFullView(false);
            }}
          >
            <CancelIcon style={{ color: "white", fontSize: "35px" }} />
          </IconButton>
          <div className={classes.imageArrowCont}>
            <div className={classes.arrowCont}>
              <IconButton
                onClick={() => {
                  let next = curPhoto - 1;
                  if (next < 0) {
                    setCurPhoto(size - 1);
                  } else {
                    next = next % size;
                    setCurPhoto(next);
                  }
                }}
              >
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
            <div className={classes.mainImageCont}>
              <img
                style={{ width: "100%", maxHeight: "100%" }}
                src={actualImages[curPhoto]?.url}
              />
            </div>
            <div className={classes.arrowCont}>
              <IconButton
                onClick={() => {
                  let next = curPhoto + 1;
                  next = next % size;
                  setCurPhoto(next);
                }}
              >
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
