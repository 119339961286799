import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import EditListItem from "./edit.list.item";
import TextField from '@material-ui/core/TextField';
import AddItemBtn from "./add.item.btn";
import Checkbox from '@material-ui/core/Checkbox';
import MoneyPopOver from "./popper";
import Api from "../../../../../helpers/Api";
import useTotalBlocks from "./total.hook";
import Collapse from '@material-ui/core/Collapse';
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { getDiscountOrTaxes } from "../../../commonComponent/transaction/api";
import { useDebounce } from "react-use";
import emptyBill from "../../../../../Assets/emptyBillItem.svg"
import EditListItemWithoutAccounting from "./edit.list.item.without.accounting";
import BlocksAndTasksHandler from "../../../salesAndInvoices/BlocksAndTasksHandler";

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    marginTop: "50px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56"
    },
    [theme.breakpoints.down('sm')]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    }
  },
  taxDiscountAndTotal: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
    alignItems: "flex-start",
  },
  grandTotalCont: {
    width: "500px",
    padding: "5px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "30px",
      width: "96%",
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: "30px",
      width: "100%",
      padding: "0px"
    },
  },
  rowCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "8px 0px",
  },
  taxDiscountRowCont: {
    width: "100%",
  },
  labelSty: {
    width: "350px",
    textAlign: "right",
    paddingRight: "7px",
    fontSize: "15px",
    fontWeight: "500",
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 110px)",
      fontSize: "12px",
      paddingRight: "0px",
    }
  },
  taxLabelSty: {
    width: "250px",
    textAlign: "right",
    paddingRight: "7px",
    fontSize: "15px",
    fontWeight: "500",
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 110px)",
      fontSize: "12px",
      paddingRight: "0px"
    }
  },
  discountLabelSty: {
    width: "250px",
    textAlign: "right",
    paddingRight: "7px",
    fontSize: "15px",
    fontWeight: "500",
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 110px)",
      fontSize: "12px",
      paddingRight: "0px"
    }
  },
  valueSty: {
    width: "150px",
    textAlign: "right",
    paddingRight: "7px",
    fontSize: "14px",
    fontWeight: "450",
    [theme.breakpoints.down('xs')]: {
      width: "110px",
      fontSize: "10px",
      fontWeight: "400",
      paddingRight: "0px",
    }
  },
  swapIconCont: {
    width: "100px",
    paddingLeft: "20px",
    [theme.breakpoints.down('xs')]: {
      width: "50px",
      paddingLeft: "10px",
    }
  },
  taxDiscountCont: {
    width: "400px",
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 60px)",
    }
  },
  swapIcontAndTaxDiscountCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  iconCont: {
    padding: "8px 0px",
    width: "40px",
    border: "1px solid gray",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderRight: "none",
  },
  iconBtn: {
    position: "relative",
    left: "-15px",
    zIndex: "5",
    padding: "0px"
  },
  iconSty: {
    width: "27px",
    height: "27px",
    backgroundColor: "white",
    padding: "2px",
    borderRadius: "50%",
    color: "white",
    backgroundColor: theme.palette.primary.main
  },
  cellStyle: {
    padding: "10px 8px",
    opacity: "0.6",
    [theme.breakpoints.down('sm')]: {
      padding: "7px 4px",
    },
  },
  addItemBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "20px"
  },
  allItemsCont: {
    width: "100%",
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "180px",
      width: "auto",
      marginBottom: "10px"
    },
    "& p": {
      fontSize: "14px",
      color: "gray"
    },
    "& a": {
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
  },
  checkboxsCont: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500"
    }
  }
}));

export default function BillListEdit({
  billList, setBillList, billItemDictionary, setBillItemDictionary,
  tx, setTx, showGL, updateApi, setLoadingBool, discounts, setDiscounts,
  taxes, setTaxes, chartAccounts, setChartAccounts, offerings, setOfferings,
  libraryData, categoriesData, setCategoriesData, incomeChartAccounts,
  setIncomeChartAccounts, expenseChartAccounts, setExpenseChartAccounts,
  needToMakeDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { walletId } = useParams();

  const itemIds = billList?.items || [];

  const oldOrder = billList.orderReverse;
  const [order, setOrder] = useState(oldOrder);

  const oldDiscountListObj = billList?.discount
  const [discountListObj, setDiscountListObj] = useState(oldDiscountListObj)
  const [selectProject, setSelectedProject] = useState({});
  const oldTaxListObj = billList?.tax
  const [taxListObj, setTaxListObj] = useState(oldTaxListObj)

  const [discountValue, setDiscountValue] = useState(null);
  const [taxValue, setTaxValue] = useState(null);
  const [isIndividualTax, setIsIndividualTax] = useState(billList?.isIndividualTax || false)
  const [initialDStopper1, setInitialDStopper1] = useState(false)

  const { SubTotalView, TaxView, DiscountView, GrandTotalView, grandTotal } = useTotalBlocks({ tx, updateApi });

  // to update bill list
  const update = async (obj) => {
    if (billList?._id && !needToMakeDisable) {
      await Api.post("wallet/billList/update", {
        _id: billList._id,
        ...obj,
      }).then((res) => {
        const data = res.data;
        setBillList(data);
        setDiscountListObj(data?.discount)
        setTaxListObj(data?.tax)
      });
    }
  };

  // to update orderReverse
  useDebounce(() => {
    if (billList?.orderReverse !== order && initialDStopper1 && !needToMakeDisable) {
      update({ orderReverse: order })
    } else {
      setInitialDStopper1(true)
    }
  }, 700, [order]);

  // to get discounts & taxs and chart accounts 
  useEffect(() => {
    if (tx?.type === "Invoice") {
      if (discounts && taxes && discounts.length > 0 && taxes.length > 0) {
        if (billList?.discountRelationModel) {
          setDiscountValue(billList?.discountRelationModel)
        } else {
          setDiscountValue(discounts[1])
        }
        if (billList?.taxRelationModel) {
          setTaxValue(billList?.taxRelationModel)
        } else {
          setTaxValue(taxes[1])
        }
      } else {
        getDiscountOrTaxes({ walletId })
          .then((discountOrTaxs) => {
            let locDiscounts = []
            let locTaxes = []
            discountOrTaxs.length > 0 && discountOrTaxs.map((discountOrTax) => {
              if (discountOrTax.model === "Discount") {
                locDiscounts.push(discountOrTax)
              } else if (discountOrTax.model === "Tax") {
                locTaxes.push(discountOrTax)
              }
            })
            const addObject = {
              _id: 'New',
              name: "+ Add New",
              numDays: "0"
            };
            const modifiedDiscounts = [addObject, ...locDiscounts];
            setDiscounts(modifiedDiscounts)
            if (billList?.discountRelationModel) {
              setDiscountValue(billList?.discountRelationModel)
            } else {
              setDiscountValue(modifiedDiscounts[1])
            }
            const modifiedtaxes = [addObject, ...locTaxes];
            setTaxes(modifiedtaxes)
            if (billList?.taxRelationModel) {
              setTaxValue(billList?.taxRelationModel)
            } else {
              setTaxValue(modifiedtaxes[1])
            }
          })
          .catch((err) => {
            console.log(err);
          })
      }
    }
  }, [discounts, taxes]);

  // add or change discount
  const selectDiscount = async (selected, newlyCreated = false) => {
    if (selected && selected._id && !needToMakeDisable) {
      setDiscountValue(selected);
      let newBillDiscountObj = {
        enabled: billList?.discount?.enabled,
        type: selected?.type,
      };
      if (selected.type === "%") {
        newBillDiscountObj.percent = selected?.percent
      } else if (selected.type === "$") {
        newBillDiscountObj.amount = selected?.amount
      }
      const newBillListUpdate = {
        discountRelationModel: selected?._id,
        discount: newBillDiscountObj
      };
      setDiscountValue(selected);
      if (newlyCreated) {
        const discountArr = [...discounts, selected]
        setDiscounts(discountArr)
      }
      update(newBillListUpdate);
    }
  }

  // add or change tax
  const selectTax = async (selected, newlyCreated = false) => {
    if (selected && selected._id && needToMakeDisable) {
      setTaxValue(selected);
      let newBillTaxObj = {
        enabled: billList?.tax?.enabled,
        type: selected?.type,
      };
      if (selected.type === "%") {
        newBillTaxObj.percent = selected?.percent
      } else if (selected.type === "$") {
        newBillTaxObj.amount = selected?.amount
      }
      const newBillListUpdate = {
        taxRelationModel: selected?._id,
        tax: newBillTaxObj
      };
      setTaxValue(selected);
      if (newlyCreated) {
        const taxesArr = [...taxes, selected]
        setTaxes(taxesArr)
      }
      update(newBillListUpdate);
    }
  }

  // add default tax
  const selectDefaultTax = async () => {
    if (taxValue && taxValue._id && !needToMakeDisable) {
      const newBillListUpdate = {
        taxRelationModel: taxValue?._id
      };

      await update(newBillListUpdate);
    }
  }

  // add default discount
  const selectDefaultDiscount = async () => {
    if (discountValue && discountValue?._id && !needToMakeDisable) {
      const newBillListUpdate = {
        discountRelationModel: discountValue?._id
      };

      await update(newBillListUpdate);
    }
  }

  let Discount = <MoneyPopOver
    key="discount"
    placeholder={"Discount"}
    valObjGiven={discountListObj}
    taxesOrDiscounts={discounts}
    taxOrDiscountValue={discountValue}
    onSelect={selectDiscount}
    callDefaultAdd={selectDefaultDiscount}
    grandTotal={grandTotal}
    tx={tx}
    setValObj={(valObj) => {
      update({
        discount: valObj,
      });
    }}
    onClickDelete={() => {
      update({
        discount: {
          enabled: false,
          amount: 0,
          percent: 0,
          type: "$",
        },
      });
    }}
  />

  let Tax = <MoneyPopOver
    key="tax"
    placeholder={"Tax"}
    valObjGiven={taxListObj}
    taxesOrDiscounts={taxes}
    taxOrDiscountValue={taxValue}
    grandTotal={grandTotal}
    onSelect={selectTax}
    callDefaultAdd={selectDefaultTax}
    tx={tx}
    setValObj={(valObj) => {
      update({
        tax: valObj,
      });
    }}
    onClickDelete={() => {
      update({
        tax: {
          enabled: false,
          amount: 0,
          percent: 0,
          type: "%",
        },
      });
    }}
  />

console.log("Task Project:" , tx);
  return (
    <>
      <div className={classes.sectionTop} >
        <h3>Items</h3>
        <div className={classes.checkboxsCont} >
          {!tx?.notIntregratedAccounting && (<>
            <Checkbox
              color="primary"
              disabled={needToMakeDisable}
              checked={isIndividualTax}
              onChange={async (event) => {
                setIsIndividualTax(event.target.checked)
                await update({
                  isIndividualTax: event.target.checked
                });
              }}
            />
            <h3>Individual Tax</h3>
          </>)}
        </div>
      </div>

      {itemIds && itemIds.length > 0 ? (
        <div className={classes.allItemsCont} >
          {itemIds.map((itemId, index) => {
            const item = billItemDictionary[itemId];
            return item?._id && (<>
              {tx?.notIntregratedAccounting ? (
                <EditListItemWithoutAccounting
                  billItemDict={billItemDictionary}
                  setBillItemDict={setBillItemDictionary}
                  billList={billList}
                  setBillList={setBillList}
                  key={itemId}
                  itemId={itemId}
                  offeringRelations={offerings}
                  tx={tx}
                  defaultIncomeAcc={tx?.defaultIncomeAcc?._id}
                  defaultExpenseAcc={tx?.defaultExpenseAcc?._id}
                  currentWallet={tx?.firstPartyWallet?._id}
                  currentProfile={tx?.firstParty?._id}
                  listType={tx?.type}
                  setOfferingRelations={setOfferings}
                  setLoadingBool={setLoadingBool}
                  index={index}
                  totalLen={itemIds.length}
                  chartAccounts={chartAccounts}
                  setChartAccounts={setChartAccounts}
                  taxes={taxes}
                  incomeChartAccounts={incomeChartAccounts}
                  setIncomeChartAccounts={setIncomeChartAccounts}
                  expenseChartAccounts={expenseChartAccounts}
                  setExpenseChartAccounts={setExpenseChartAccounts}
                  libraryData={libraryData}
                  categoriesData={categoriesData}
                  setCategoriesData={setCategoriesData}
                  setTaxes={setTaxes}
                  isIndividualTax={isIndividualTax}
                  needToMakeDisable={needToMakeDisable}
                />
              ) : (
                <EditListItem
                  billItemDict={billItemDictionary}
                  setBillItemDict={setBillItemDictionary}
                  billList={billList}
                  setBillList={setBillList}
                  key={itemId}
                  itemId={itemId}
                  offeringRelations={offerings}
                  tx={tx}
                  setOfferingRelations={setOfferings}
                  setLoadingBool={setLoadingBool}
                  index={index}
                  totalLen={itemIds.length}
                  chartAccounts={chartAccounts}
                  setChartAccounts={setChartAccounts}
                  taxes={taxes}
                  incomeChartAccounts={incomeChartAccounts}
                  setIncomeChartAccounts={setIncomeChartAccounts}
                  expenseChartAccounts={expenseChartAccounts}
                  setExpenseChartAccounts={setExpenseChartAccounts}
                  libraryData={libraryData}
                  categoriesData={categoriesData}
                  setCategoriesData={setCategoriesData}
                  setTaxes={setTaxes}
                  isIndividualTax={isIndividualTax}
                  needToMakeDisable={needToMakeDisable}
                />
              )}
            </>)
          })}
        </div>
      ) : (<div className={classes.emptyCont} >
        <img src={emptyBill} alt="empty image" />
        <p>{tx?.type === "Bill" ? "No bill item is added. Click below button to add" : "No invoice item is added. Click below button to add"}</p>
      </div>)}

      {!needToMakeDisable && (
        <div className={classes.addItemBtn}>
          <AddItemBtn
            billList={billList}
            setBillList={setBillList}
            billItemDictionary={billItemDictionary}
            setBillItemDictionary={setBillItemDictionary}
            setLoadingBool={setLoadingBool}
            type={tx?.type}
            defaultOfferingRelation={tx?.type === "Bill" ? tx?.defaultExpansesOfferingRelation : tx?.defaultIncomeOfferingRelation}
            itemChartAccount={tx?.type === "Bill" ? tx?.defaultExpenseAcc : tx?.defaultIncomeAcc}
          />
        </div>
      )}

      {itemIds && itemIds.length > 0 ? (
        <div className={classes.taxDiscountAndTotal} >
          <div className={classes.grandTotalCont} >
            <div className={classes.rowCont} >
              <div className={classes.labelSty} >
                {(tx?.type === "Invoice" || isIndividualTax) ? "Sub Total" : "Grand Total"}
              </div>
              <div className={classes.valueSty} >
                {SubTotalView}
              </div>
            </div>
            {isIndividualTax ? (<>
              <div className={classes.rowCont} >
                <div className={classes.labelSty} >
                  Total Tax
                </div>
                <div className={classes.valueSty} >
                  {TaxView}
                </div>
              </div>
              <div className={classes.rowCont} >
                <div className={classes.labelSty} >
                  {Discount}
                </div>
                <div className={classes.valueSty} >
                  {DiscountView}
                </div>
              </div>
              <div className={classes.rowCont} >
                <div className={classes.labelSty} >
                  Grand Total
                </div>
                <div className={classes.valueSty} >
                  {GrandTotalView}
                </div>
              </div>
            </>) : (<>
              {tx?.type === "Invoice" && <>
                <div className={classes.swapIcontAndTaxDiscountCont} >
                  <div className={classes.swapIconCont} >
                    <div className={classes.iconCont} >
                      <IconButton
                        className={classes.iconBtn}
                        disabled={needToMakeDisable}
                        onClick={async () => { setOrder(!order); }}
                      >
                        <SwapVertIcon className={classes.iconSty} />
                      </IconButton>
                    </div>
                  </div>
                  <div className={classes.taxDiscountCont} >
                    <div className={classes.taxDiscountRowCont} >
                      <Collapse in={order}>
                        <div className={classes.rowCont} >
                          <div className={classes.taxLabelSty} >
                            {Tax}
                          </div>
                          <div className={classes.valueSty} >
                            {TaxView}
                          </div>
                        </div>
                      </Collapse>
                      <Collapse in={!order}>
                        <div className={classes.rowCont} >
                          <div className={classes.discountLabelSty} >
                            {Discount}
                          </div>
                          <div className={classes.valueSty} >
                            {DiscountView}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className={classes.taxDiscountRowCont} >
                      <Collapse in={!order}>
                        <div className={classes.rowCont} >
                          <div className={classes.taxLabelSty} >
                            {Tax}
                          </div>
                          <div className={classes.valueSty} >
                            {TaxView}
                          </div>
                        </div>
                      </Collapse>
                      <Collapse in={order}>
                        <div className={classes.rowCont} >
                          <div className={classes.discountLabelSty} >
                            {Discount}
                          </div>
                          <div className={classes.valueSty} >
                            {DiscountView}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
                <div className={classes.rowCont} >
                  <div className={classes.labelSty} >
                    Grand Total
                  </div>
                  <div className={classes.valueSty} >
                    {GrandTotalView}
                  </div>
                </div>
                {showGL && (
                  <div className={classes.rowCont} >
                    <div className={classes.labelSty} >
                      Late Fees
                    </div>
                    <div className={classes.valueSty} >
                      ₹{tx?.lateFeeAmount}
                    </div>
                  </div>
                )}
              </>}
            </>)}
          </div>
        </div>
      ) : null}
      
    </>
  );
}